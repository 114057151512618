import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { HeightModel } from '../../../../../../../lib-editor/src/lib/editors/primitive/height-editor/height.model';
import { ImagePlainElementParameters } from '../../../../models/linked-models/image-plain-element-linked-model';

@Component({
    selector: 'lib-image-editor',
    template: `
        <lib-expandable-group
            titleText="Image settings">
            <div>
                Source url:
                <lib-url-editor
                    [url]="parameters.sourceUrl"
                    (commitChange)="commitChanges({ sourceUrl: $event })"></lib-url-editor>
            </div>
            <div>
                Alternative Text:
                <lib-text-editor
                    [text]="parameters.alternativeText"
                    (commitChange)="commitChanges({ alternativeText: $event })"></lib-text-editor>
            </div>
        </lib-expandable-group>
        <lib-expandable-group
            titleText="Size">
            <div>
                Width:
                <lib-width-editor
                    [width]="parameters.width"
                    (commitChange)="commitChanges({ width: $event })"></lib-width-editor>
            </div>
            <div>
                Height:
                <lib-height-editor
                    [height]="heightModel"
                    (commitChange)="commitChanges({ height: $event.numberValueOrThrow() })"></lib-height-editor>
            </div>
        </lib-expandable-group>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePlainElementComponent
    implements OnInit {
    @Input() parameters: ImagePlainElementParameters;
    @Output() parametersChanges = new EventEmitter<ImagePlainElementParameters>();
    heightModel: HeightModel;

    ngOnInit(): void {
        this.heightModel = new HeightModel(this.parameters.height);
    }

    commitChanges(changes: Partial<ImagePlainElementParameters>) {
        this.parameters = { ...this.parameters, ...changes };
        this.parametersChanges.emit(this.parameters);
    }
}
