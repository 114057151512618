<div
    class="responsive-block"

    [ngStyle]="{
        paddingTop: responsiveBlockModel.parameters.padding.top + 'px',
        paddingBottom: responsiveBlockModel.parameters.padding.bottom + 'px',
        paddingLeft: responsiveBlockModel.parameters.padding.left + 'px',
        paddingRight: responsiveBlockModel.parameters.padding.right + 'px'
    }">
    <div class="children-container">
        <lib-container
            class="container"
            *ngFor="let containerModel of responsiveBlockModel.children; index as position"
            [model]="containerModel"
            [position]="position"
            [isResponsive]="isResponsive">
        </lib-container>
    </div>
</div>
