import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { BreadcrumbModel } from '../../../contracts/models/breadcrumb.model';

@Component({
    selector: 'lib-breadcrumb-node',
    template: `
        <button
            class="select-button"
            (click)="select.emit(model)">{{model.title}}</button>
        <span class="divider">/</span>
    `,
    styles: [`
        :host {
            font-size: 12px;
            line-height: 16px;
        }

        .select-button {
            padding: 0;
            border: none;

            font-size: inherit;
            color: #337ab7;
            background-color: transparent;
        }

        .select-button:hover {
            border-bottom: 1px dotted;
        }

        .divider {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 4px;

            font-size: inherit;
            text-align: center;
            color: #d6d6d6;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbNodeComponent {
    @Input() model: BreadcrumbModel;
    @Output() select = new EventEmitter<BreadcrumbModel>();
}
