import {
    Component,
    ComponentFactoryResolver,
    Input,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ResponsiveBlockSetContainerComponent } from './responsive-block-set-container/responsive-block-set-container.component';
import { HorizontalContainerComponent } from './horizontal-container/horizontal-container.component';
import { ContainerLinkedModel } from '../../../../models/linked-models/container-linked-model';
import { DynamicContainerComponent } from '../../../../../../../lib-dynamic-component/dynamic-container-component';
import { CustomSimpleChanges } from '../../../../utils/angular-extensions';

export type DisplayContainerDynamicComponent = HorizontalContainerComponent | ResponsiveBlockSetContainerComponent;

@Component({
    selector: 'lib-container',
    template: `
        <ng-container #dynamicContainer></ng-container>
    `,
})
export class ContainerComponent
    extends DynamicContainerComponent<ContainerLinkedModel, DisplayContainerDynamicComponent> {
    @Input() model: ContainerLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;

    @ViewChild(
        'dynamicContainer',
        {
            read: ViewContainerRef,
            static: true
        },
    ) dynamicContainer: ViewContainerRef;

    private component: DisplayContainerDynamicComponent;

    constructor(resolver: ComponentFactoryResolver) {
        super(resolver);
    }

    contextToComponent(contextModel: ContainerLinkedModel): Type<DisplayContainerDynamicComponent> {
        switch (contextModel['@type']) {
            case 'responsiveBlockSet':
                return ResponsiveBlockSetContainerComponent;
            case 'horizontalContainer':
                return HorizontalContainerComponent;
        }
    }

    // TODO: generic type any
    onChange(changes: CustomSimpleChanges<any>): void {
        this.setValues();
        this.component.ngOnChanges(changes);
    }

    setValues() {
        this.component.model = this.model;
        this.component.position = this.position;
        this.component.isResponsive = this.isResponsive;
    }

    onCreateComponent(component: DisplayContainerDynamicComponent): void {
        this.component = component;
        this.setValues();
    }

    canRerender(previousModel: ContainerLinkedModel, currentModel: ContainerLinkedModel): boolean {
        return true;
    }

    beforeDestroyComponent(component: DisplayContainerDynamicComponent): void {
    }
}
