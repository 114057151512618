import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToolboxListTypes } from '../../components/toolbox-list-group/toolbox-list-types';

@Injectable()
export class SelectorService {
    private readonly selectSubject = new BehaviorSubject<ToolboxListTypes>('container');
    readonly selected$ = this.selectSubject.asObservable();

    readonly isSelected = (type: ToolboxListTypes) =>
        this.selectSubject
            .pipe(
                map(x => x === type)
            );

    selectComponentTab() {
        this.selectSubject.next('component');
    }

    selectContainerTab() {
        this.selectSubject.next('container');
    }

    selectElementTab() {
        this.selectSubject.next('element');
    }

    selectThemeTab() {
        this.selectSubject.next('theme');
    }
}
