import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { EditorAdapterModule } from './adapters/editor-adapter/editor-adapter.module';
import { BreadcrumbAdapterModule } from './adapters/breadcrumb-adapter/breadcrumb-adapter.module';
import { TreeModule } from './components/tree/tree.module';
import { CommonModule } from '@angular/common';
import { DragImageComponent } from './components/drag-image/drag-image.component';
import { DisplayModule } from './modules/display/display.module';
import { ToolboxModule } from './modules/toolbox/toolbox.module';
import { ViewportModule } from './modules/viewport/viewport.module';
import { FormsModule } from '@angular/forms';
import { OptionsModule } from './modules/options/options.module';

@NgModule({
    declarations: [
        AppComponent,
        DragImageComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BreadcrumbAdapterModule,
        TreeModule,
        EditorAdapterModule,
        DisplayModule,
        ToolboxModule,
        ViewportModule,
        FormsModule,
        OptionsModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
