import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { BackgroundModel } from './background.model';

@Component({
    selector: 'lib-background-editor',
    template: `
        <fieldset>
            <legend>Цвет фона</legend>
            <lib-color-editor
                [colorModel]="backgroundModel.color"
                (colorModelChange)="patchValue({ color: $event })"
                (commitChange)="patchValue({ color: $event });commitChange.emit(backgroundModel)"></lib-color-editor>
        </fieldset>
        <fieldset>
            <legend>Изображение</legend>
            <div class="background-preview">
                <lib-background-preview
                    [color]="backgroundModel.color.toString()"
                    [imageUrl]="backgroundModel.image.sourceUrl"
                    [imagePosition]="backgroundModel.image.position"
                    [imageRepeat]="backgroundModel.image.repeat">
                    <button class="background-preview__remove-button"
                        (click)="removeBackgroundImage()"
                        title="Убрать изображение">
                        <svg width="10"
                            height="11"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.41895 7.79687C9.56478 7.94271 9.6377 8.11979 9.6377 8.32812C9.6377 8.53646 9.56478 8.71354 9.41895 8.85937L8.35645 9.92187C8.21061 10.0677 8.03353 10.1406 7.8252 10.1406C7.61686 10.1406 7.43978 10.0677 7.29395 9.92187L4.99707 7.625L2.7002 9.92187C2.55436 10.0677 2.37728 10.1406 2.16895 10.1406C1.96061 10.1406 1.78353 10.0677 1.6377 9.92187L0.575195 8.85937C0.429362 8.71354 0.356445 8.53646 0.356445 8.32812C0.356445 8.11979 0.429362 7.94271 0.575195 7.79687L2.87207 5.5L0.575195 3.20312C0.429362 3.05729 0.356445 2.88021 0.356445 2.67188C0.356445 2.46354 0.429362 2.28646 0.575195 2.14062L1.6377 1.07812C1.78353 0.932291 1.96061 0.859374 2.16895 0.859374C2.37728 0.859374 2.55436 0.932291 2.7002 1.07812L4.99707 3.375L7.29395 1.07812C7.43978 0.932291 7.61686 0.859374 7.8252 0.859374C8.03353 0.859374 8.21061 0.932291 8.35645 1.07812L9.41895 2.14062C9.56478 2.28646 9.6377 2.46354 9.6377 2.67188C9.6377 2.88021 9.56478 3.05729 9.41895 3.20312L7.12207 5.5L9.41895 7.79687Z"
                                fill="white"/>
                        </svg>
                    </button>
                </lib-background-preview>
            </div>
        </fieldset>
        <lib-background-image-editor
            [backgroundImageModel]="backgroundModel.image"
            (backgroundImageModelChange)="patchValue({ image: $event })"
            (commitChange)="patchValue({ image: $event });commitChange.emit(backgroundModel)"></lib-background-image-editor>
    `,
    styles: [`
        .background-preview {
            height: 113px;
        }

        .background-preview__remove-button {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 16px;
            height: 16px;
            padding: 0;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.5));
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundEditorComponent {
    @Input() backgroundModel: BackgroundModel;
    @Output() backgroundModelChange = new EventEmitter<BackgroundModel>();
    @Output() commitChange = new EventEmitter<BackgroundModel>();

    patchValue(changes: Partial<BackgroundModel>) {
        this.backgroundModel = { ...this.backgroundModel, ...changes };
        this.backgroundModelChange.emit(this.backgroundModel);
    }

    removeBackgroundImage() {
        this.patchValue({
            image: {
                ...this.backgroundModel.image,
                sourceUrl: '',
            },
        });
        this.commitChange.emit(this.backgroundModel);
    }
}
