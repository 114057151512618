import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { HorizontalContainerLinkedModel } from '../../../../../models/linked-models/horizontal-container-linked-model';
import { DisplayComponentEventBusService } from '../../../shared/display-event-bus/display-component-event-bus.service';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../../../../utils/angular-extensions';

@Component({
    template: `
        <table
            #tableElementRef
            class="horizontal-container"
            [align]="model.parameters.align"

            [ngStyle]="{
                width: model.parameters.isFullWidth ? '100%' : 'auto',
                backgroundColor: model.parameters.background.color,
                backgroundImage: 'url(' + model.parameters.background.image.url + ')',
                backgroundPosition: model.parameters.background.image.position,
                backgroundRepeat: model.parameters.background.image.repeat,
                borderTopWidth: model.parameters.border.top?.width + 'px',
                borderTopStyle: model.parameters.border.top?.style,
                borderTopColor: model.parameters.border.top?.color,
                borderBottomWidth: model.parameters.border.bottom?.width + 'px',
                borderBottomStyle: model.parameters.border.bottom?.style,
                borderBottomColor: model.parameters.border.bottom?.color,
                borderLeftWidth: model.parameters.border.left?.width + 'px',
                borderLeftStyle: model.parameters.border.left?.style,
                borderLeftColor: model.parameters.border.left?.color,
                borderRightWidth: model.parameters.border.right?.width + 'px',
                borderRightStyle: model.parameters.border.right?.style,
                borderRightColor: model.parameters.border.right?.color,
                borderTopLeftRadius: model.parameters.borderRadius.topLeft + 'px',
                borderTopRightRadius: model.parameters.borderRadius.topRight + 'px',
                borderBottomRightRadius: model.parameters.borderRadius.bottomRight + 'px',
                borderBottomLeftRadius: model.parameters.borderRadius.bottomLeft + 'px'
            }">
            <lib-cell
                class="cell"
                *ngFor="let cellModel of model.children; index as position"
                [cellModel]="cellModel"
                [position]="position"
                [isResponsive]="isResponsive"

                [ngStyle]="{
                    height: model.parameters.cellsHeight + 'px',
                    width: cellModel.parameters.width + 'px'
                }">
            </lib-cell>
        </table>
        <div style="clear: both"></div>
    `,
    styles: [`
        .horizontal-container {
            position: relative;
            border-spacing: 0;
            margin: auto;
            display: table;
            height: 100%;
        }

        .cell {
            position: relative;
            display: table-cell;
            height: 100%;
            border-spacing: 0;
            vertical-align: top;
        }
    `],
})
export class HorizontalContainerComponent
    implements OnInit,
        AfterViewInit,
        CustomOnChanges,
        OnDestroy {
    @Input() model: HorizontalContainerLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;
    @ViewChild('tableElementRef')
    private tableElementRef: ElementRef<HTMLTableElement>;

    constructor(
        private elementRef: ElementRef,
        public displayComponentEventService: DisplayComponentEventBusService,
    ) {
    }

    ngOnInit(): void {
        this.displayComponentEventService.create(
            this.model,
            this.position,
            this.elementRef)
    }

    ngAfterViewInit(): void {
        this.displayComponentEventService.change(
            this.model,
            this.position,
            this.tableElementRef);
    }

    ngOnChanges(changes: CustomSimpleChanges<this>): void {
        if (changes.position) {
            this.displayComponentEventService.change(
                this.model,
                this.position,
                this.tableElementRef);
        }
    }

    ngOnDestroy(): void {
        this.displayComponentEventService.destroy(this.model);
    }
}
