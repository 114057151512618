import { ActionTypes } from './actions';

export class MoveElementAction<TElement> {
    public readonly type = ActionTypes.MoveElement;

    constructor(
        public readonly prevContainerList: TElement[],
        public readonly nextContainerList: TElement[],
        public readonly fromPosition: number,
        public readonly toPosition: number,
        public readonly targetModel: TElement,
    ) {
    }
}
