import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { BreadcrumbModel } from '../../../../../lib-breadcrumbs/src/public-contracts';
import { LinkedModel } from '../../models/linked-models/linked-model';
import { IdMapper } from '../../utils/helpers/id-mapper';

@Component({
    selector: 'lib-breadcrumb-adapter',
    template: `
        <lib-breadcrumbs
            [breadcrumbPath]="breadcrumbPath"
            (select)="select($event)"></lib-breadcrumbs>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbAdapterComponent
    implements OnChanges {
    @Input() idMapper: IdMapper<LinkedModel>;
    @Input() getPathFromRoot: (model: LinkedModel) => LinkedModel[];
    @Input() element: LinkedModel;
    @Input() getLabelByModel: (model: LinkedModel) => string;
    @Output() elementChange = new EventEmitter<LinkedModel>();
    breadcrumbPath: BreadcrumbModel[];

    select($event: BreadcrumbModel) {
        const element = this.getElement($event);
        this.elementChange.emit(element);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.element) {
            this.breadcrumbPath = this.getBreadcrumbPath(this.element);
        }
    }

    private getElement(breadcrumbModel: BreadcrumbModel): LinkedModel {
        return this.idMapper.getModelById(breadcrumbModel.id);
    }

    private getBreadcrumbPath(element: LinkedModel): BreadcrumbModel[] {
        const pathFromRoot = this.getPathFromRoot(element);

        return pathFromRoot.map(
            (element) => {
                const id = this.idMapper.getIdByModel(element);

                return new BreadcrumbModel(
                    id,
                    this.getLabelByModel(element),
                );
            });
    }
}
