import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { HeightModel } from './height.model';

@Component({
    selector: 'lib-height-editor',
    template: `
        <input type="number"
            [ngModel]="height.numberValueOrThrow()"
            (ngModelChange)="setValue($event)"
            (blur)="commitChange.emit(height)">
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeightEditorComponent {
    @Input() height: HeightModel;
    @Output() heightChange = new EventEmitter<HeightModel>();
    @Output() commitChange = new EventEmitter<HeightModel>();

    setValue(value: number) {
        this.height = new HeightModel(value);
        this.heightChange.emit(this.height);
    }
}
