import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { VerticalAlignModel } from './vertical-align.model';

@Component({
    selector: 'lib-vertical-align-editor',
    template: `
        <div class="button-group">
            <div class="button-group__row">
                <button class="button button--vAlign button--left"
                    title="По верхнему краю"
                    [disabled]="vAlign === 'top'"
                    (click)="commitAlignPosition('top')">
                    <svg width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="5"
                            width="10"
                            height="6"
                            rx="1"
                            fill="#333333"/>
                        <rect x="2"
                            width="2"
                            height="16"
                            rx="1"
                            fill="#333333"/>
                    </svg>
                </button>
                <button class="button button--vAlign button--middle"
                    title="По центру"
                    [disabled]="vAlign === 'middle'"
                    (click)="commitAlignPosition('middle')">
                    <svg width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="5"
                            y="5"
                            width="10"
                            height="6"
                            rx="1"
                            fill="#333333"/>
                        <rect x="2"
                            width="2"
                            height="16"
                            rx="1"
                            fill="#333333"/>
                    </svg>
                </button>
                <button class="button button--vAlign bottom"
                    title="По нижнему краю"
                    [disabled]="vAlign === 'bottom'"
                    (click)="commitAlignPosition('bottom')">
                    <svg width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="5"
                            y="10"
                            width="10"
                            height="6"
                            rx="1"
                            fill="#333333"/>
                        <rect x="2"
                            width="2"
                            height="16"
                            rx="1"
                            fill="#333333"/>
                    </svg>
                </button>
            </div>
        </div>
    `,
    styles: [`
        /* TODO: remake to scss */
        .button {
            box-sizing: border-box;
            padding: 0;
            border: 1px solid #cccccc;
            background-color: #ffffff;
        }

        .button:disabled {
            background-color: #e6e6e6;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }

        .button--vAlign {
            width: 48px;
            height: 32px;
        }

        .button-group__row .button + .button {
            margin-left: -1px;
        }

        .button-group__row:first-child .button:first-child {
            border-top-left-radius: 4px;
        }

        .button-group__row:first-child .button:last-child {
            border-top-right-radius: 4px;
        }

        .button-group__row:last-child .button:first-child {
            border-bottom-left-radius: 4px;
        }

        .button-group__row:last-child .button:last-child {
            border-bottom-right-radius: 4px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalAlignEditorComponent {
    @Input() vAlign: VerticalAlignModel;
    @Output() vAlignChange = new EventEmitter<VerticalAlignModel>();
    @Output() commitChange = new EventEmitter<VerticalAlignModel>();

    commitAlignPosition(vAlign: VerticalAlignModel) {
        this.vAlign = vAlign;
        this.vAlignChange.emit(this.vAlign);
        this.commitChange.emit(this.vAlign);
    }
}
