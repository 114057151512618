import { Pixels } from '../../../../../../demo/src/app/models/types/Pixels';
import { BorderStyle } from '../../../../../../demo/src/app/models/types/BorderGroup';

export interface BorderStyleModel {
    style: BorderStyle;
    width: Pixels;
}

export class BorderStyleGroupModel {
    constructor(
        public readonly top: BorderStyleModel,
        public readonly right: BorderStyleModel,
        public readonly bottom: BorderStyleModel,
        public readonly left: BorderStyleModel,
    ) {
    }
}
