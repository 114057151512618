import { LinkedModel } from '../../../../models/linked-models/linked-model';
import { ElementRef } from '@angular/core';

export class DisplayComponentChangeEvent {
    constructor(
        public model: LinkedModel,
        public position: number,
        public elementRef: ElementRef
    ) {
    }
}
