import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { PaddingGroupModel } from './padding-group.model';

type PaddingSide = 'all' | 'top' | 'right' | 'bottom' | 'left';

@Component({
    selector: 'lib-padding-group-editor',
    template: `
        <div class="padding-group">
            <div class="padding-group__main">
                <div class="icon">
                    <svg
                        *ngIf="activeSide === 'all'"
                        class="icon__all"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="1"
                            y="4"
                            width="2"
                            height="8"
                            rx="1"
                            fill="#333333"/>
                        <rect x="13"
                            y="4"
                            width="2"
                            height="8"
                            rx="1"
                            fill="#333333"/>
                        <rect x="12"
                            y="13"
                            width="2"
                            height="8"
                            rx="1"
                            transform="rotate(90 12 13)"
                            fill="#333333"/>
                        <rect x="12"
                            y="1"
                            width="2"
                            height="8"
                            rx="1"
                            transform="rotate(90 12 1)"
                            fill="#333333"/>
                    </svg>

                    <svg
                        *ngIf="activeSide === 'top'"
                        class="icon__top-side"
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 8C16 8.55228 15.5523 9 15 9L1 9C0.447715 9 2.41411e-08 8.55228 0 8C-2.41411e-08 7.44772 0.447715 7 1 7L15 7C15.5523 7 16 7.44771 16 8Z"
                            fill="#333333"/>
                        <path d="M9 8L7 8L7 2L9 2V8Z"
                            fill="#333333"/>
                        <path d="M8 -9.53674e-07V2L10.72 4.04C11.2474 4.43554 12 4.05924 12 3.4C12 3.14819 11.8814 2.91108 11.68 2.76L8 -9.53674e-07Z"
                            fill="#333333"/>
                        <path d="M8 -9.53674e-07V2L5.28 4.04C4.75261 4.43554 4 4.05924 4 3.4C4 3.14819 4.11856 2.91108 4.32 2.76L8 -9.53674e-07Z"
                            fill="#333333"/>
                    </svg>

                    <svg
                        *ngIf="activeSide === 'right'"
                        class="icon__right-side"
                        width="9"
                        height="16"
                        viewBox="0 0 9 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 16C0.447716 16 6.36101e-07 15.5523 6.11959e-07 15L0 1C-2.41411e-08 0.447715 0.447715 2.41411e-08 1 0C1.55228 -2.41411e-08 2 0.447715 2 1L2 15C2 15.5523 1.55229 16 1 16Z"
                            fill="#333333"/>
                        <path d="M1 9L1 7L7 7V9H1Z"
                            fill="#333333"/>
                        <path d="M9 8H7L4.96 10.72C4.56446 11.2474 4.94076 12 5.6 12C5.85181 12 6.08892 11.8814 6.24 11.68L9 8Z"
                            fill="#333333"/>
                        <path d="M9 8H7L4.96 5.28C4.56446 4.75261 4.94076 4 5.6 4C5.85181 4 6.08892 4.11856 6.24 4.32L9 8Z"
                            fill="#333333"/>
                    </svg>

                    <svg
                        *ngIf="activeSide === 'bottom'"
                        class="icon__bottom-side"
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1C16 0.447716 15.5523 6.36101e-07 15 6.11959e-07L1 0C0.447715 -2.41411e-08 2.41411e-08 0.447715 0 1C-2.41411e-08 1.55228 0.447715 2 1 2L15 2C15.5523 2 16 1.55229 16 1Z"
                            fill="#333333"/>
                        <path d="M9 1L7 1L7 7H9V1Z"
                            fill="#333333"/>
                        <path d="M8 9V7L10.72 4.96C11.2474 4.56446 12 4.94076 12 5.6C12 5.85181 11.8814 6.08892 11.68 6.24L8 9Z"
                            fill="#333333"/>
                        <path d="M8 9V7L5.28 4.96C4.75261 4.56446 4 4.94076 4 5.6C4 5.85181 4.11856 6.08892 4.32 6.24L8 9Z"
                            fill="#333333"/>
                    </svg>

                    <svg
                        *ngIf="activeSide === 'left'"
                        class="icon__left-side"
                        width="9"
                        height="16"
                        viewBox="0 0 9 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 16C8.55228 16 9 15.5523 9 15L9 1C9 0.447715 8.55228 2.41411e-08 8 0C7.44772 -2.41411e-08 7 0.447715 7 1L7 15C7 15.5523 7.44771 16 8 16Z"
                            fill="#333333"/>
                        <path d="M8 9L8 7L2 7V9L8 9Z"
                            fill="#333333"/>
                        <path d="M-9.53674e-07 8H2L4.04 10.72C4.43554 11.2474 4.05924 12 3.4 12C3.14819 12 2.91108 11.8814 2.76 11.68L-9.53674e-07 8Z"
                            fill="#333333"/>
                        <path d="M-9.53674e-07 8H2L4.04 5.28C4.43554 4.75261 4.05924 4 3.4 4C3.14819 4 2.91108 4.11856 2.76 4.32L-9.53674e-07 8Z"
                            fill="#333333"/>
                    </svg>
                </div>
                <div class="editor">
                    <div
                        class="editor__common"
                        [ngClass]="{
                            'editor__common--mixed': isMixed,
                            'editor__common--hide': isExtended
                        }"
                        [hidden]="isExtended">
                        <lib-pixel-editor
                            class="editor__full-input"
                            [pixels]="isMixed ? 0 : paddingGroupModel.top"
                            (pixelsChange)="patchValue({
                                top: $event,
                                right: $event,
                                bottom: $event,
                                left: $event
                            })"
                            (focus)="fullInputFocus($event)"
                            (commitChange)="commitChange.emit(paddingGroupModel)"
                            [maxValue]="PADDING_MAX"
                        ></lib-pixel-editor>
                    </div>
                    <div
                        class="editor__extended"
                        [ngClass]="{
                            'editor__extended--show': isExtended
                        }">
                        <lib-pixel-editor
                            class="editor__piece-input"
                            title="Top side"
                            [pixels]="paddingGroupModel.top"
                            [maxValue]="PADDING_MAX"
                            (pixelsChange)="patchValue({
                                top: $event
                            })"
                            (commitChange)="commitChange.emit(paddingGroupModel)"
                            (focus)="activeSide = 'top'"
                            (blur)="resetSide()"
                        ></lib-pixel-editor>
                        <lib-pixel-editor
                            class="editor__piece-input"
                            title="Right side"
                            [pixels]="paddingGroupModel.right"
                            [maxValue]="PADDING_MAX"
                            (pixelsChange)="patchValue({
                                right: $event
                            })"
                            (commitChange)="commitChange.emit(paddingGroupModel)"
                            (focus)="activeSide = 'right'"
                            (blur)="resetSide()"
                        ></lib-pixel-editor>
                        <lib-pixel-editor
                            class="editor__piece-input"
                            title="Bottom side"
                            [pixels]="paddingGroupModel.bottom"
                            [maxValue]="PADDING_MAX"
                            (pixelsChange)="patchValue({
                                bottom: $event
                            })"
                            (commitChange)="commitChange.emit(paddingGroupModel)"
                            (focus)="activeSide = 'bottom'"
                            (blur)="resetSide()"
                        ></lib-pixel-editor>
                        <lib-pixel-editor
                            class="editor__piece-input"
                            title="Left side"
                            [pixels]="paddingGroupModel.left"
                            [maxValue]="PADDING_MAX"
                            (pixelsChange)="patchValue({
                                left: $event
                            })"
                            (commitChange)="commitChange.emit(paddingGroupModel)"
                            (focus)="activeSide = 'left'"
                            (blur)="resetSide()"
                        ></lib-pixel-editor>
                    </div>
                </div>
            </div>
            <div class="switcher">
                <button
                    class="switcher__button"
                    [ngClass]="{
                        'switcher__button--extended': isExtended
                    }"
                    (click)="isExtended = !isExtended">
                    <i class="fas fa-sliders-h"></i>
                </button>
            </div>
        </div>
    `,
    styles: [`
        /* reset input[type=number] arrow buttons */
        :host ::ng-deep input[type=number] {
            -moz-appearance: textfield;
        }

        :host ::ng-deep input::-webkit-outer-spin-button,
        :host ::ng-deep input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }


        .padding-group__main {
            display: flex;
            flex-basis: 205px;
            flex-grow: 0;
            flex-shrink: 0;
            border: 1px solid #ccc;
            border-radius: 4px;
            height: 34px;
            color: #333333;
        }

        .icon {
            flex-basis: 48px;
            flex-grow: 0;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .editor {
            flex-basis: 156px;
            flex-grow: 0;
            flex-shrink: 0;
            color: #333333;
        }

        .editor__common {
            display: flex;
            height: 100%;
        }

        .editor__common--hide {
            display: none;
        }

        .editor__common--mixed {
            position: relative;
        }

        .editor__common--mixed ::ng-deep input:not(:focus) {
            color: transparent;
        }

        .editor__common--mixed::before {
            content: 'Mixed';
            position: absolute;
            right: 9px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
            color: #777777;
            pointer-events: none;
        }

        .editor__full-input {
            width: 100%;
            height: 100%;
            border-left: 1px solid #cccccc;
        }

        .editor__full-input ::ng-deep input {
            border: 0;
            padding: 6px;

            font-size: 14px;
            line-height: 19px;
            text-align: right;
            color: inherit;
        }

        .padding-group {
            display: flex;
        }

        .editor__extended {
            display: none;
            height: 100%;
        }

        .editor__extended--show {
            display: flex;
        }

        .editor__piece-input {
            flex-basis: 25%;
            border-left: 1px solid #cccccc;
        }

        .editor__piece-input ::ng-deep input {
            border: 0;
            padding: 6px;

            font-size: 14px;
            line-height: 19px;
            text-align: right;
            color: inherit;
        }

        .switcher__button {
            padding: 7px;
            height: 100%;
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }

        .switcher__button--extended {
            color: #16aadb;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaddingGroupEditorComponent {
    readonly PADDING_MAX = 60;
    @Input() paddingGroupModel: PaddingGroupModel;
    @Output() paddingGroupModelChange = new EventEmitter<PaddingGroupModel>();
    @Output() commitChange = new EventEmitter<PaddingGroupModel>();
    isExtended = false;
    activeSide: PaddingSide = 'all';

    get isMixed() {
        return this.paddingGroupModel.top !== this.paddingGroupModel.right
            || this.paddingGroupModel.top !== this.paddingGroupModel.bottom
            || this.paddingGroupModel.top !== this.paddingGroupModel.left;
    }

    patchValue(changes: Partial<PaddingGroupModel>): void {
        this.paddingGroupModel = { ...this.paddingGroupModel, ...changes };
        this.paddingGroupModelChange.emit(this.paddingGroupModel);
    }

    resetSide(): void {
        this.activeSide = 'all';
    }

    resetValue(element: HTMLInputElement) {
        element.value = '';
    }

    fullInputFocus($event: FocusEvent) {
        if (this.isMixed && $event.target !== null) {
            this.resetValue($event.target as HTMLInputElement)
        }
    }
}
