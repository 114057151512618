import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { BackgroundModel } from '../../../../../../../lib-editor/src/lib/editors/complex/background-editor/background.model';
import { ColorModel } from '../../../../../../../lib-editor/src/lib/editors/primitive/color-editor/color.model';
import { RowParameters } from '../../../../models/linked-models/row-linked-model';
import { BackgroundImageModel } from '../../../../../../../lib-editor/src/lib/editors/complex/background-image-editor/background-image.model';

@Component({
    selector: 'lib-row',
    template: `
        <lib-background-group
            [background]="parameters.background"
            (commitChange)="commitChanges({ background: $event })"></lib-background-group>

        <lib-expandable-group
            titleText="Content">
            <div>
                Background color:
                <lib-color-editor
                    [colorModel]="bodyBackgroundColor"
                    (commitChange)="commitChanges({
                        bodyBackground: {
                            color: $event.toString()
                        }
                    })"></lib-color-editor>
            </div>
            <div>
                Content Width:
                <lib-width-editor
                    [width]="parameters.bodyWidth"
                    (commitChange)="commitChanges({ bodyWidth: $event })"></lib-width-editor>
            </div>
            <div>
                Inner Width:
                <lib-width-editor
                    [width]="parameters.innerWidth"
                    (commitChange)="commitChanges({ innerWidth: $event })"></lib-width-editor>
            </div>
        </lib-expandable-group>
        <lib-expandable-group
            titleText="Content padding">
            <lib-padding-group-editor
                [paddingGroupModel]="{
                    top: parameters.innerPadding.top,
                    bottom: parameters.innerPadding.bottom,
                    left: parameters.innerPadding.left,
                    right: parameters.innerPadding.right
                }"
                (commitChange)="commitChanges({
                    innerPadding: {
                        top: $event.top,
                        bottom: $event.bottom,
                        left: $event.left,
                        right: $event.right
                    }
                })"></lib-padding-group-editor>
        </lib-expandable-group>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowComponent
    implements OnInit {
    @Input() parameters: RowParameters;
    @Output() parametersChanges = new EventEmitter<RowParameters>();
    backgroundModel: BackgroundModel;
    bodyBackgroundColor: ColorModel;

    ngOnInit(): void {
        this.initializeValues();
    }

    ngOnChanges() {
        this.initializeValues();
    }

    initializeValues() {
        this.backgroundModel = new BackgroundModel(
            new ColorModel(this.parameters.background.color),
            new BackgroundImageModel(
                this.parameters.background.image.url,
                this.parameters.background.image.position,
                this.parameters.background.image.repeat
            ),
        );
        this.bodyBackgroundColor = new ColorModel(this.parameters.bodyBackground.color);
    }

    commitChanges(changes: Partial<RowParameters>) {
        this.parameters = { ...this.parameters, ...changes };
        this.parametersChanges.emit(this.parameters);
    }
}
