import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { DynamicContainerComponent } from '../../../../../../../lib-dynamic-component/dynamic-container-component';
import { ComponentListComponent } from '../component-list/component-list.component';
import { ContainerListComponent } from '../container-list/container-list.component';
import { ElementListComponent } from '../element-list/element-list.component';
import { ThemeListComponent } from '../theme-list/theme-list.component';
import { ToolboxListTypes } from './toolbox-list-types';
import { AddableTypes } from '../../types/addable-types';

type ToolboxListComponents = ComponentListComponent | ContainerListComponent | ElementListComponent | ThemeListComponent;

@Component({
    selector: 'toolbox-list-group',
    template: `
        <ng-container #dynamicContainer></ng-container>
    `,
    styles: [`
    `],
})
export class ToolboxListGroupComponent
    extends DynamicContainerComponent<ToolboxListTypes, ToolboxListComponents> {
    @ViewChild(
        'dynamicContainer',
        {
            read: ViewContainerRef,
            static: true
        },
    ) dynamicContainer: ViewContainerRef;
    @Input() model: ToolboxListTypes;
    @Output() dragStart = new EventEmitter<AddableTypes>();

    constructor(resolver: ComponentFactoryResolver) {
        super(resolver);
    }

    contextToComponent(type: ToolboxListTypes): Type<ToolboxListComponents> {
        switch (type) {
            case 'component':
                return ComponentListComponent;
            case 'container':
                return ContainerListComponent;
            case 'element':
                return ElementListComponent;
            case 'theme':
                return ThemeListComponent;
        }
    }

    onChange(changes: SimpleChanges): void {
    }

    onCreateComponent(component: ToolboxListComponents): void {
        component.dragStart.subscribe((x: AddableTypes) => {
            this.dragStart.emit(x);
        });
    }

    canRerender(previousModel: ToolboxListTypes, currentModel: ToolboxListTypes): boolean {
        return true;
    }

    beforeDestroyComponent(component: ToolboxListComponents): void {
    }

}
