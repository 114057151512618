import {
    ChangeDetectionStrategy,
    Component,
    Input
} from '@angular/core';
import { BreadcrumbModel } from '../../../contracts/models/breadcrumb.model';

@Component({
    selector: 'lib-breadcrumb-leaf',
    template: `
        {{model.title}}
    `,
    styles: [`
        :host {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;

            color: #777777;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbLeafComponent {
    @Input() model: BreadcrumbModel;
}
