import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { DocumentLinkedModel } from '../../models/linked-models/document-linked-model';
import { DragStartEvent } from '../../components/tree/drag-start-event';
import {
    DropAfterEvent,
    DropBeforeEvent,
    DropInsideEvent
} from '../../components/tree/drop-inside-event';
import {
    LinkedModel,
    LinkedModelContainers
} from '../../models/linked-models/linked-model';
import { BorderModel } from './shared/models/border.model';
import { DisplayComponentEventBusService } from './shared/display-event-bus/display-component-event-bus.service';
import { DisplayComponentCreateEvent } from './shared/display-event-bus/displayComponentCreateEvent';
import { MapHelper } from '../../utils/helpers/map-helper';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DisplayComponentChangeEvent } from './shared/display-event-bus/displayComponentChangeEvent';
import { RepositoryModel } from './shared/models/repository.model';
import { BidiMap } from '../../utils/bidiMap';
import { RemoveEvent } from '../../removeEvent';
import { CopyEvent } from '../../copyEvent';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../utils/angular-extensions';

export interface DropBorderModel {
    border: BorderModel;
    dropAvailability: {
        inside: boolean;
        before: boolean;
        after: boolean;
    };
    isVertical: boolean;
    isHorizontal: boolean;
}

export interface HoverBorderModel {
    border: BorderModel;
    isSelected: boolean;
    isDraggable: boolean;
}

@Component({
    selector: 'lib-display',
    template: `
        <lib-overlay
            *ngIf="showOverlay"
            [hoverBorderList]="overlayHoverBorderList"
            [dropBorderList]="overlayDropBorderList"
            (hoverClick)="hoverClick($event)"
            (dragStart)="startDrag($event)"
            (dropInside)="dropOverlayInside($event)"
            (dropBefore)="dropOverlayBefore($event)"
            (dropAfter)="dropOverlayAfter($event)"
            (copy)="copyElement($event)"
            (remove)="removeElement($event)"
            [isDropMode]="isDropMode"></lib-overlay>
        <lib-document
            [documentModel]="documentLinkedModel"
            [isResponsive]="isResponsive">
        </lib-document>
    `,
    styles: [`
        :host {
            user-select: none;
        }
    `],
})
export class DisplayComponent
    implements CustomOnChanges {
    @Input() showOverlay: boolean;
    @Input() documentLinkedModel: DocumentLinkedModel;
    @Input() isDropMode: boolean;
    @Input() dropInsidePredicate: (model: LinkedModel) => boolean;
    @Input() dropBeforePredicate: (model: LinkedModel) => boolean;
    @Input() dropAfterPredicate: (model: LinkedModel) => boolean;
    @Input() dropVerticalPredicate: (model: LinkedModel) => boolean;
    @Input() dropHorizontalPredicate: (model: LinkedModel) => boolean;
    @Input() selectedElement: LinkedModel;
    @Output() dragStart: EventEmitter<DragStartEvent> = new EventEmitter<DragStartEvent>();
    @Output() dropInside = new EventEmitter<DropInsideEvent>();
    @Output() dropBefore = new EventEmitter<DropBeforeEvent>();
    @Output() dropAfter = new EventEmitter<DropAfterEvent>();
    @Output() selectElement = new EventEmitter<LinkedModel>();
    @Output() copy = new EventEmitter<CopyEvent>();
    @Output() remove = new EventEmitter<RemoveEvent>();
    @Input() getLabelByModel: (model: LinkedModel) => string;
    @Input() getIconByModel: (model: LinkedModel) => string;
    @Input() draggableElementPredicate: (model: LinkedModel) => boolean;
    overlayHoverBorderList: HoverBorderModel[] = [];
    overlayDropBorderList: DropBorderModel[] = [];
    parentNode: HTMLElement;
    public isResponsive = false;
    private readonly repository: Map<LinkedModel, RepositoryModel> = new Map<LinkedModel, RepositoryModel>();
    private readonly overlayComponents = new BidiMap<BorderModel, RepositoryModel>();
    private readonly border$ = new Subject();

    constructor(
        displayComponentEventBusService: DisplayComponentEventBusService,
        elementRef: ElementRef,
    ) {
        this.parentNode = elementRef.nativeElement.parentNode;
        displayComponentEventBusService.onCreate
            .subscribe((event: DisplayComponentCreateEvent) => {
                this.repository.delete(event.model);
                this.repository.set(event.model, event);

                this.border$.next();
            });

        displayComponentEventBusService.onChange
            .subscribe((event: DisplayComponentChangeEvent) => {
                this.repository.set(event.model, event);

                this.border$.next();
            });

        displayComponentEventBusService.onDestroy
            .subscribe(() => {
                // TODO: optimization: clear repository
                // this.repository.delete(model);

                this.border$.next();
            });

        this.border$
            .pipe(debounceTime(100))
            .subscribe(() => {
                this.initBorders();
            });
    }

    ngOnChanges(changes: CustomSimpleChanges<this>) {
        if (
            changes.selectedElement
            || (changes.isDropMode && changes.isDropMode.currentValue === true)
        ) {
            this.initBorders();
        }
    }

    hoverClick(borderModel: BorderModel) {
        const repositoryModel = MapHelper.getOrThrow(this.overlayComponents, borderModel);

        this.selectElement.emit(repositoryModel.model);
    }

    resize(width: number) {
        this.border$.next();
        this.setResponsive(width);
    }

    setResponsive(width: number) {
        this.isResponsive = this.documentLinkedModel.parameters.documentWidth > width;
    }

    startDrag(borderModel: BorderModel) {
        const repositoryModel = MapHelper.getOrThrow(this.overlayComponents, borderModel);

        this.selectElement.emit(repositoryModel.model);
        this.dragStart.emit({
            fromPosition: repositoryModel.position,
            model: repositoryModel.model,
        });
    }

    dropOverlayInside(borderModel: BorderModel) {
        const repositoryModel = MapHelper.getOrThrow(this.overlayComponents, borderModel);

        // TODO: as
        const model = repositoryModel.model as LinkedModelContainers;
        this.dropInside.emit({
            to: model,
            position: model.children.length,
        });
    }

    dropOverlayBefore(borderModel: BorderModel) {
        const repositoryModel = MapHelper.getOrThrow(this.overlayComponents, borderModel);

        this.dropBefore.emit({
            before: repositoryModel.model,
            position: repositoryModel.position,
        });
    }

    dropOverlayAfter(borderModel: BorderModel) {
        const repositoryModel = MapHelper.getOrThrow(this.overlayComponents, borderModel);

        this.dropAfter.emit({
            after: repositoryModel.model,
            position: repositoryModel.position,
        });
    }

    copyElement(borderModel: BorderModel) {
        const repositoryModel = MapHelper.getOrThrow(this.overlayComponents, borderModel);

        this.copy.emit({
            model: repositoryModel.model,
            position: repositoryModel.position,
        });
    }

    removeElement(borderModel: BorderModel) {
        const repositoryModel = MapHelper.getOrThrow(this.overlayComponents, borderModel);

        this.remove.emit({
            model: repositoryModel.model,
            position: repositoryModel.position,
        });
    }

    private getCorrectClientRect(elementRef: ElementRef): DOMRect {
        const documentRepModel = MapHelper.getOrThrow(this.repository, this.documentLinkedModel);
        const rootElement = documentRepModel.elementRef.nativeElement;
        const parentClientRect: DOMRect = rootElement.getBoundingClientRect();
        const boundingClientRect: DOMRect = elementRef.nativeElement.getBoundingClientRect();
        boundingClientRect.x += rootElement.scrollLeft;
        boundingClientRect.x -= parentClientRect.x;
        boundingClientRect.y += rootElement.scrollTop;
        boundingClientRect.y -= parentClientRect.y;

        return boundingClientRect;
    }

    private initBorders() {
        this.overlayComponents.clear();
        const hoverBorderList: HoverBorderModel[] = [];
        const dropBorderList: DropBorderModel[] = [];

        for (let [model, repositoryModel] of this.repository.entries()) {
            let rect = this.getCorrectClientRect(repositoryModel.elementRef);

            const borderModel: BorderModel = {
                rect: rect,
                label: this.getLabelByModel(model),
                icon: this.getIconByModel(model),
            };
            this.overlayComponents.set(borderModel, repositoryModel);


            hoverBorderList.push({
                border: borderModel,
                isSelected: this.selectedElement === model,
                isDraggable: this.draggableElementPredicate(model),
            });

            dropBorderList.push({
                border: borderModel,
                dropAvailability: {
                    after: this.dropAfterPredicate(model),
                    before: this.dropBeforePredicate(model),
                    inside: this.dropInsidePredicate(model),
                },
                isHorizontal: this.dropHorizontalPredicate(model),
                isVertical: this.dropVerticalPredicate(model),
            });
        }

        this.overlayHoverBorderList = hoverBorderList;
        this.overlayDropBorderList = dropBorderList;
    }
}
