import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { InitialStateFormControl } from '../../../helpers/initial-state-form-control';
import { UrlValidatorFactory } from '../../../helpers/url-validator-factory';
import { Subscription } from 'rxjs';

@Component({
    selector: 'lib-url-editor',
    template: `
        <input type="url"
            [formControl]="urlFormControl"
            (focus)="focus.emit($event)"
            (blur)="blur.emit($event); commitValue()"
            (keyup.enter)="commitValue()"
            placeholder="https://...">
    `,
    styles: [`
        input {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrlEditorComponent
    implements OnInit,
        OnChanges,
        OnDestroy {
    @Input() url: string;
    @Output() urlChange = new EventEmitter<string>();
    @Output() commitChange = new EventEmitter<string>();
    @Output() focus = new EventEmitter<FocusEvent>();
    @Output() blur = new EventEmitter<FocusEvent>();

    urlFormControl: InitialStateFormControl<{ value: string }>;
    subscriptionList = new Subscription()

    ngOnInit(): void {
        this.urlFormControl = new InitialStateFormControl(
            { value: this.url },
            [
                UrlValidatorFactory.build(),
            ],
        );

        this.subscriptionList
            .add(
                this.urlFormControl
                    .valueChanges
                    .subscribe((value: string) => {
                        if (this.urlFormControl.valid) {
                            this.urlChange.emit(value);
                        }
                    })
            );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.url && !changes.url.isFirstChange()) {
            this.urlFormControl
                .setValue(
                    changes.url.currentValue,
                    { emitEvent: false })
        }
    }

    ngOnDestroy(): void {
        this.subscriptionList
            .unsubscribe();
    }

    commitValue(): void {
        if (this.urlFormControl.invalid) {
            this.urlFormControl.resetStateToInitial();
            return;
        }

        this.commitChange.emit(this.url);
    }
}
