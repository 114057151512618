<div
    class="row"
    [ngStyle]="{
        backgroundColor: rowModel.parameters.background.color,
        backgroundImage: 'url(' + rowModel.parameters.background.image.url + ')',
        backgroundPosition: rowModel.parameters.background.image.position,
        backgroundRepeat: rowModel.parameters.background.image.repeat
    }">
    <div
        class="outer"
        [ngStyle]="{
            maxWidth: rowModel.parameters.bodyWidth + 'px',
            backgroundColor: rowModel.parameters.bodyBackground.color
        }">
        <div
            class="inner"
            [ngStyle]="{
                maxWidth: rowModel.parameters.innerWidth + 'px',
                paddingTop: (isResponsive ? rowModel.responsiveParameters.innerPadding.top : rowModel.parameters.innerPadding.top) + 'px',
                paddingBottom: (isResponsive ? rowModel.responsiveParameters.innerPadding.bottom : rowModel.parameters.innerPadding.bottom) + 'px',
                paddingLeft: (isResponsive ? rowModel.responsiveParameters.innerPadding.left : rowModel.parameters.innerPadding.left) + 'px',
                paddingRight: (isResponsive ? rowModel.responsiveParameters.innerPadding.right : rowModel.parameters.innerPadding.right) + 'px'
            }">
            <lib-container
                class="container"
                *ngFor="let containerModel of rowModel.children; index as position"
                [model]="containerModel"
                [position]="position"
                [isResponsive]="isResponsive"></lib-container>
        </div>
    </div>
</div>
