import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { LinkedModelParameters } from '../../../../models/linked-models/linked-model-parameters';

@Component({
    template: `
        default
    `,
})
export class DefaultComponent {
    @Input() parameters: LinkedModelParameters;
    @Output() parametersChanges = new EventEmitter<LinkedModelParameters>();

}
