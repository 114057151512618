import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ButtonPlainElementLinkedModel } from '../../../../../models/linked-models/button-plain-element-linked-model';
import { DisplayComponentEventBusService } from '../../../shared/display-event-bus/display-component-event-bus.service';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../../../../utils/angular-extensions';

@Component({
    template: `
        <a
            class="button"
            [href]="model.parameters.url"
            target="_blank"
            [ngStyle]="{
                width: model.parameters.width + 'px',
                height: model.parameters.height + 'px',
                borderWidth: model.parameters.border.width + 'px',
                borderStyle: model.parameters.border.style,
                borderColor: model.parameters.border.color,
                borderRadius: model.parameters.borderRadius + 'px',
                fontWeight: model.parameters.fontWeight,
                fontSize: model.parameters.fontSize + 'px',
                fontFamily: model.parameters.fontFamily,
                color: model.parameters.color,
                backgroundColor: model.parameters.backgroundColor
            }">
            {{model.parameters.text}}
        </a>
    `,
    styles: [`
        :host {
            display: inline-block;
        }

        .button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            box-sizing: border-box;
        }
    `],
})
export class ButtonPlainElementComponent
    implements OnInit,
        CustomOnChanges,
        OnDestroy {
    @Input() model: ButtonPlainElementLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;

    constructor(
        private elementRef: ElementRef,
        public displayComponentEventService: DisplayComponentEventBusService) {

    }

    ngOnInit(): void {
        this.displayComponentEventService.create(
            this.model,
            this.position,
            this.elementRef)
    }

    ngOnChanges(changes: CustomSimpleChanges<this>): void {
        if (changes.position) {
            this.displayComponentEventService.change(
                this.model,
                this.position,
                this.elementRef);
        }
    }

    ngOnDestroy(): void {
        this.displayComponentEventService.destroy(this.model);
    }
}
