import { ContainerLinkedModel } from './container-linked-model';
import { Width } from '../types/Width';
import { Background } from '../types/Background';
import { PaddingGroup } from '../types/PaddingGroup';

export interface RowParameters {
    bodyWidth: Width;
    innerWidth: Width;
    background: Background;
    bodyBackground: Pick<Background, 'color'>;
    innerPadding: PaddingGroup;
}

export interface RowResponsiveParameters {
    innerPadding: PaddingGroup;
}

export class RowLinkedModel {
    public readonly '@type': 'row' = 'row';
    public children: ContainerLinkedModel[];
    public parameters: RowParameters;
    public responsiveParameters: RowResponsiveParameters;

    constructor(params: {
        children: ContainerLinkedModel[];
        bodyWidth: Width;
        innerWidth: Width;
        background: Background;
        bodyBackground: Pick<Background, 'color'>;
        innerPadding: PaddingGroup;
        responsiveInnerPadding: PaddingGroup;
    }) {
        this.children = params.children;
        this.parameters = {
            bodyWidth: params.bodyWidth,
            innerWidth: params.innerWidth,
            background: params.background,
            bodyBackground: params.bodyBackground,
            innerPadding: params.innerPadding,
        };
        this.responsiveParameters = {
            innerPadding: params.responsiveInnerPadding,
        };
    }
}
