import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { BorderModel } from '../../shared/models/border.model';
import {
    DropBorderModel,
    HoverBorderModel
} from '../../display.component';

@Component({
    selector: 'lib-overlay',
    template: `
        <ng-template ngFor
            [ngForOf]="hoverBorderList"
            let-hoverBorder>
            <div class="hover-border"
                [ngClass]="{
                    'hover-border--selected': hoverBorder.isSelected,
                    'hover-border--dropMode': isDropMode,
                    'hover-border--draggable': hoverBorder.isDraggable
                }"

                libDraggable
                (libDraggableDragStart)="dragStart.emit(hoverBorder.border)"

                (click)="hoverClick.emit(hoverBorder.border)"
                [ngStyle]="{
                    top: hoverBorder.border.rect.top + 'px',
                    left: hoverBorder.border.rect.left + 'px',
                    width: (hoverBorder.border.rect.right - hoverBorder.border.rect.left) + 'px',
                    height: (hoverBorder.border.rect.bottom - hoverBorder.border.rect.top) + 'px'
                }">
            </div>
            <div class="hover-title"
                [ngClass]="{
                    'hover-title--selected': hoverBorder.isSelected,
                    'hover-title--dropMode': isDropMode,
                    'hover-title--draggable': hoverBorder.isDraggable
                }"

                libDraggable
                (libDraggableDragStart)="dragStart.emit(hoverBorder.border)"

                [ngStyle]="{
                    top: hoverBorder.border.rect.top + 'px',
                    left: hoverBorder.border.rect.left + 'px'
                }">
                <span class="hover-title__label">
                    <i class="hover-title__icon fas fa-{{hoverBorder.border.icon}}"></i>
                    {{hoverBorder.border.label}}
                </span>
                <div class="hover-title__control-buttons">
                    <button
                        (click)="copy.emit(hoverBorder.border)">
                        <i class="fas fa-copy"></i>
                    </button>
                    <button (click)="remove.emit(hoverBorder.border)">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </div>
            </div>
        </ng-template>

        <ng-template [ngIf]="isDropMode">
            <div class="drop-border"
                *ngFor="let dropBorder of dropBorderList"

                [ngClass]="{
                    'drop-border__available-inside': dropBorder.dropAvailability.inside,
                    'drop-border__available-before': dropBorder.dropAvailability.before,
                    'drop-border__available-after': dropBorder.dropAvailability.after,
                    'drop-border--vertical': dropBorder.isVertical,
                    'drop-border--horizontal': dropBorder.isHorizontal
                }"

                (click)="hoverClick.emit(dropBorder.border)"
                [ngStyle]="{
                    top: dropBorder.border.rect.top + 'px',
                    left: dropBorder.border.rect.left + 'px',
                    width: (dropBorder.border.rect.right - dropBorder.border.rect.left) + 'px',
                    height: (dropBorder.border.rect.bottom - dropBorder.border.rect.top) + 'px'
                }">
                <span class="placeholder-inside"
                    (libDrop)="dropInside.emit(dropBorder.border)">
                    <span class="placeholder-inside__title">
                        <i class="placeholder-inside__icon fas fa-{{dropBorder.border.icon}}"></i>
                        {{dropBorder.border.label}}
                    </span>
                </span>
                <span class="placeholder-before"
                    (libDrop)="dropBefore.emit(dropBorder.border)"></span>
                <span class="placeholder-after"
                    (libDrop)="dropAfter.emit(dropBorder.border)"></span>
            </div>
        </ng-template>
    `,
    styles: [`
        .hover-border {
            position: absolute;
            z-index: 1;
            outline: 1px solid #16AADB;
            opacity: 0;
        }

        .hover-border:hover {
            opacity: 1;
        }

        .hover-border--dropMode:hover {
            opacity: 0;
        }

        .hover-border--selected {
            outline-color: #16aadb;
            opacity: 1;
        }

        .hover-border--draggable {
            opacity: 0.9;
            outline-color: rgba(0, 0, 0, 0.5);

            backdrop-filter: grayscale(100%);
        }

        .hover-title {
            position: absolute;
            z-index: 1;

            display: flex;
            align-items: center;
            height: 18px;
            margin-top: -19px;
            margin-left: 0;
            padding: 3px 5px;
            outline: 1px solid #16AADB;
            box-sizing: border-box;

            background-color: #ffffff;
            color: #16aadb;
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;

            opacity: 0;
            pointer-events: none;
        }

        .hover-title:not(.hover-title--dropMode):hover,
        .hover-border:hover + .hover-title:not(.hover-title--dropMode) {
            opacity: 1;
            z-index: 2;
        }

        .hover-title--selected {
            z-index: 3;

            background-color: #16aadb;
            color: #ffffff;

            opacity: 1;
            pointer-events: auto;
        }

        .hover-title--draggable {
            background-color: rgba(0, 0, 0, 0.5);
            outline-color: rgba(0, 0, 0, 0.5);
        }

        .hover-title__control-buttons {
            display: none;
            margin-left: 5px;
        }

        .hover-title__control-buttons button {
            padding: 0;
            margin-left: 2px;
            margin-right: 2px;

            border: none;
            background-color: transparent;
            color: inherit;
        }

        .hover-title--selected .hover-title__control-buttons {
            display: block;
        }

        .drop-border {
            position: absolute;
            z-index: 1;
            outline: none;
            opacity: 0;
            pointer-events: none;
        }

        .placeholder-inside {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            outline: 1px solid #16AADB;
            opacity: 0;
        }

        .placeholder-inside__title {
            position: absolute;
            top: -20px;
            left: -1px;

            pointer-events: none;

            padding: 3px 5px;

            background-color: #16aadb;
            color: #ffffff;
            font-size: 12px;
        }

        .drop-border__available-inside .placeholder-before,
        .drop-border__available-inside .placeholder-after {
            display: none;
        }

        .drop-border--vertical .placeholder-before {
            position: absolute;
            width: 100%;
            height: 50%;
            top: 0;
            left: 0;
            background: transparent;
            border-top: 1px solid cyan;
            opacity: 0;
            font-size: 12px;
        }

        .drop-border--vertical .placeholder-before::after {
            content: 'Drop here';
            background: cyan;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            padding: 5px 10px;
            border-radius: 5px;
            color: #ffffff;
            text-shadow: 0 0 black;
        }

        .drop-border--vertical .placeholder-after {
            position: absolute;
            width: 100%;
            height: 50%;
            bottom: 0;
            left: 0;
            background: transparent;
            border-bottom: 1px solid cyan;
            opacity: 0;
            font-size: 12px;
        }

        .drop-border--vertical .placeholder-after::after {
            content: 'Drop here';
            background: cyan;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%);
            padding: 5px 10px;
            border-radius: 5px;
            color: #ffffff;
            text-shadow: 0 0 black;
        }

        .drop-border--horizontal .placeholder-before {
            position: absolute;
            width: 50%;
            height: 100%;
            left: 0;
            background: transparent;
            border: none;
            border-left: 3px solid #01284d;
            opacity: 0;
            font-size: 12px;
        }

        .drop-border--horizontal .placeholder-after {
            position: absolute;
            width: 50%;
            height: 100%;
            right: 0;
            background: transparent;
            border: none;
            border-right: 3px solid #01284d;
            opacity: 0;
            font-size: 12px;
        }

        .drop-border__available-inside,
        .drop-border__available-before,
        .drop-border__available-after {
            pointer-events: auto;
        }

        .drop-border__available-inside:hover {
            opacity: 1;
        }

        .drop-border__available-before:hover,
        .drop-border__available-after:hover {
            opacity: 1;
        }

        .drop-border__available-inside .placeholder-inside:hover {
            opacity: 1;
        }

        .drop-border__available-before .placeholder-before:hover {
            opacity: 1;
        }

        .drop-border__available-after .placeholder-after:hover {
            opacity: 1;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent
    implements AfterViewInit,
        AfterViewChecked {
    @Input() hoverBorderList: HoverBorderModel[];
    @Input() dropBorderList: DropBorderModel[];
    @Input() isDropMode: boolean;
    @Output() hoverClick = new EventEmitter<BorderModel>();
    @Output() dragStart = new EventEmitter<BorderModel>();
    @Output() dropInside = new EventEmitter<BorderModel>();
    @Output() dropBefore = new EventEmitter<BorderModel>();
    @Output() dropAfter = new EventEmitter<BorderModel>();
    @Output() copy = new EventEmitter<BorderModel>();
    @Output() remove = new EventEmitter<BorderModel>();

    ngAfterViewInit(): void {
        console.log('view init');
    }

    ngAfterViewChecked(): void {
        // console.log('view checked');
    }
}
