import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { ResponsiveBlockParameters } from '../../../../models/linked-models/responsive-block-linked-model';

@Component({
    template: `
        <lib-expandable-group
            titleText="Responsive block">
            <lib-padding-group-editor
                [paddingGroupModel]="{
                    top: parameters.padding.top,
                    bottom: parameters.padding.bottom,
                    left: parameters.padding.left,
                    right: parameters.padding.right
                }"
                (commitChange)="commitChange({
                    padding: {
                        top: $event.top,
                        bottom: $event.bottom,
                        left: $event.left,
                        right: $event.right
                    }
                })"></lib-padding-group-editor>
        </lib-expandable-group>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveBlockComponent {
    @Input() parameters: ResponsiveBlockParameters;
    @Output() parametersChanges = new EventEmitter<ResponsiveBlockParameters>();

    commitChange(changes: Partial<ResponsiveBlockParameters>) {
        this.parameters = { ...this.parameters, ...changes };
        this.parametersChanges.emit(this.parameters);
    }
}
