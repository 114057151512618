import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { FontFamilyModel } from './font-family.model';

@Component({
    selector: 'lib-font-family-editor',
    template: `
        <select [ngModel]="fontFamily"
            (ngModelChange)="fontFamily = $event; fontFamilyChange.emit(fontFamily)"
            (blur)="commitChange.emit(fontFamily)">
            <optgroup label="Sans serif">
                <option [ngValue]="'Arial, Helvetica, sans-serif'">Arial, Helvetica, sans-serif</option>
                <option [ngValue]="'&quot;Arial Black&quot;, Gadget, sans-serif'">&quot;Arial Black&quot;, Gadget, sans-serif</option>
                <option [ngValue]="'&quot;Comic Sans MS&quot;, cursive, sans-serif'">&quot;Comic Sans MS&quot;, cursive, sans-serif</option>
                <option [ngValue]="'Impact, Charcoal, sans-serif'">Impact, Charcoal, sans-serif</option>
                <option [ngValue]="'&quot;Lucida Sans Unicode&quot;, &quot;Lucida Grande&quot;, sans-serif'">&quot;Lucida Sans Unicode&quot;, &quot;Lucida Grande&quot;, sans-serif</option>
                <option [ngValue]="'Tahoma, Geneva, sans-serif'">Tahoma, Geneva, sans-serif</option>
                <option [ngValue]="'&quot;Trebuchet MS&quot;, Helvetica, sans-serif'">&quot;Trebuchet MS&quot;, Helvetica, sans-serif</option>
                <option [ngValue]="'Verdana, Geneva, sans-serif'">Verdana, Geneva, sans-serif</option>
            </optgroup>
            <optgroup label="Serif">
                <option [ngValue]="'Georgia, serif'">Georgia, serif</option>
                <option [ngValue]="'&quot;Palatino Linotype&quot;, &quot;Book Antiqua&quot;, Palatino, serif'">&quot;Palatino Linotype&quot;, &quot;Book Antiqua&quot;, Palatino, serif</option>
                <option [ngValue]="'&quot;Times New Roman&quot;, Times, serif'">&quot;Times New Roman&quot;, Times, serif</option>
            </optgroup>
            <optgroup label="Monospace">
                <option [ngValue]="'&quot;Courier New&quot;, Courier, monospace'">&quot;Courier New&quot;, Courier, monospace</option>
                <option [ngValue]="'&quot;Lucida Console&quot;, Monaco, monospace'">&quot;Lucida Console&quot;, Monaco, monospace</option>
            </optgroup>
        </select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontFamilyEditorComponent {
    @Input() fontFamily: FontFamilyModel;
    @Output() fontFamilyChange = new EventEmitter<FontFamilyModel>();
    @Output() commitChange = new EventEmitter<FontFamilyModel>();
}
