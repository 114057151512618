import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';

export class NumberValidatorFactory {
    static build(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (Number.isSafeInteger(control.value)) {
                return null;
            }

            return { numberValidator: { value: control.value } }
        }
    }
}
