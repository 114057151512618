import { RowLinkedModel } from './row-linked-model';
import { ResponsiveBlockSetContainerLinkedModel } from './responsive-block-set-container-linked-model';
import { ResponsiveBlockLinkedModel } from './responsive-block-linked-model';
import { HorizontalContainerLinkedModel } from './horizontal-container-linked-model';
import { CellLinkedModel } from './cell-linked-model';
import { RichTextPlainElementLinkedModel } from './rich-text-plain-element-linked-model';
import { ImagePlainElementLinkedModel } from './image-plain-element-linked-model';

export class EmptyRowLinkedModelFactory {
    static build(): RowLinkedModel {
        return new RowLinkedModel({
            children: [],
            bodyWidth: 600,
            innerWidth: 600,
            background: {
                color: '#ffffff',
                image: {
                    url: '',
                    position: 'left top',
                    repeat: 'no-repeat',
                },
            },
            bodyBackground: {
                color: '#ffffff'
            },
            innerPadding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
            responsiveInnerPadding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
        })
    }
}

export class EmptyResponsiveBlockSetContainerLinkedModelFactory {
    static build(): ResponsiveBlockSetContainerLinkedModel {
        return new ResponsiveBlockSetContainerLinkedModel({
            children: [],
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
            background: {
                color: '#ffffff',
                image: {
                    url: '',
                    position: 'left top',
                    repeat: 'no-repeat',
                },
            },
            bodyWidth: 600,
            columnsVerticalAlign: 'top',
            columnsHorizontalAlign: 'center',
        })
    }
}

export class EmptyResponsiveBlockLinkedModelFactory {
    static build(): ResponsiveBlockLinkedModel {
        return new ResponsiveBlockLinkedModel({
            children: [],
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
            width: 600,
            responsiveWidthInPercents: '100%',
        });
    }
}

export class EmptyHorizontalContainerLinkedModelFactory {
    static build(): HorizontalContainerLinkedModel {
        return new HorizontalContainerLinkedModel({
            background: {
                color: '#ffffff',
                image: {
                    url: '',
                    position: 'left top',
                    repeat: 'no-repeat',
                },
            },
            border: {
                top: {
                    width: 0,
                    style: 'none',
                    color: '#ffffff',
                },
                bottom: {
                    width: 0,
                    style: 'none',
                    color: '#ffffff',
                },
                left: {
                    width: 0,
                    style: 'none',
                    color: '#ffffff',
                },
                right: {
                    width: 0,
                    style: 'none',
                    color: '#ffffff',
                },
            },
            isFullWidth: true,
            cellsHeight: 'auto',
            children: [],
            borderRadius: {
                topLeft: 0,
                topRight: 0,
                bottomRight: 0,
                bottomLeft: 0,
            },
            align: 'center',
        });
    }
}

export class EmptyCellLinkedModelFactory {
    static build(): CellLinkedModel {
        return new CellLinkedModel({
            children: [],
            align: 'left',
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
            vAlign: 'top',
            width: 600,
            responsiveAlign: 'center',
            responsivePadding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
        });
    }
}

export class EmptyRichTextLinkedModelFactory {
    static build(): RichTextPlainElementLinkedModel {
        return new RichTextPlainElementLinkedModel({
            html: 'text',
        });
    }
}

export class EmptyImageLinkedModelFactory {
    static build(): ImagePlainElementLinkedModel {
        return new ImagePlainElementLinkedModel({
            alternativeText: '',
            height: 100,
            sourceUrl: 'https://via.placeholder.com/100',
            width: 100,
        });
    }
}
