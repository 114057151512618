import {
    Component,
    Input
} from '@angular/core';

@Component({
    selector: 'lib-expandable-group',
    template: `
        <div class="header"
            [ngClass]="{
                'header--expanded': isExpanded
            }"
            (click)="isExpanded = !isExpanded">
            <span class="header__title">
                {{titleText}}
            </span>
            <i class="header__status-icon fas fa-angle-down"></i>
        </div>
        <div class="content"
            [hidden]="!isExpanded">
            <ng-content></ng-content>
        </div>
    `,
    styles: [`
        :host {
            display: block;
            border-top: 1px solid #dfdfdf;
        }

        .header {
            position: relative;
            height: 34px;
            padding: 10px;

            box-sizing: border-box;
            cursor: pointer;
        }

        .header:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        .header__title {
            display: flex;
            align-items: center;
            height: 100%;

            font-weight: bold;
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: #33455a;
        }

        .header__status-icon {
            position: absolute;
            right: 9px;
            top: 50%;

            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #333333;

            transform: translateY(-50%) rotate(-90deg);
        }

        .header--expanded .header__status-icon {
            transform: translateY(-50%) rotate(0deg);
        }

        .content {
            padding: 10px;
        }
    `],
})
export class ExpandableGroupComponent {
    @Input() titleText: string;
    @Input() isExpanded: boolean = true;
}
