import { NgModule } from '@angular/core';

import { TreeDynamicNodeComponent, } from './tree-node/tree-dynamic-node.component';

import { CommonModule } from '@angular/common';
import { TreeContainerNodeComponent } from './tree-node/tree-container-node/tree-container-node.component';
import { TreeLeafNodeComponent } from './tree-node/tree-leaf-node/tree-leaf-node.component';
import { DndModule } from '../../../../../lib-dnd/src/dnd.module';
import { TitleComponent } from './components/title/title.component';

@NgModule({
    declarations: [
        TreeDynamicNodeComponent,
        TreeContainerNodeComponent,
        TreeLeafNodeComponent,
        TitleComponent,
    ],
    imports: [
        CommonModule,
        DndModule,
    ],
    exports: [
        TreeDynamicNodeComponent,
    ],
})
export class TreeModule {
}
