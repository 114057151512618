import { NgModule } from '@angular/core';
import { OptionsComponent } from './options.component';
import { EditorAdapterModule } from '../../adapters/editor-adapter/editor-adapter.module';

@NgModule({
    declarations: [
        OptionsComponent
    ],
    exports: [
        OptionsComponent,
    ],
    imports: [
        EditorAdapterModule
    ]
})
export class OptionsModule {
}
