import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { CellComponent } from './components/cell/cell.component';
import { DefaultComponent } from './components/default/default.component';
import { RowComponent } from './components/row/row.component';
import { HorizontalContainerComponent } from './components/horizontal-container/horizontal-container.component';
import { LinkedModelParameters } from '../../models/linked-models/linked-model-parameters';
import { LinkedModel } from '../../models/linked-models/linked-model';
import { DocumentComponent } from './components/document/document.component';
import { ResponsiveBlockSetComponent } from './components/responsive-block-set/responsive-block-set.component';
import { ResponsiveBlockComponent } from './components/responsive-block/responsive-block.component';
import { PlainElementIteratorComponent } from './components/plain-element-iterator/plain-element-iterator.component';
import { ImagePlainElementComponent } from './components/image-plain-element/image-plain-element.component';
import { DynamicContainerComponent } from '../../../../../lib-dynamic-component/dynamic-container-component';
import { Subscription } from 'rxjs';
import { ButtonPlainElementComponent } from './components/button-plain-element/button-plain-element.component';

type EditorComponent = DocumentComponent
    | CellComponent
    | DefaultComponent
    | HorizontalContainerComponent
    | ImagePlainElementComponent
    | PlainElementIteratorComponent
    | ResponsiveBlockComponent
    | ResponsiveBlockSetComponent
    | RowComponent;

export interface EditorModel<T extends LinkedModel = LinkedModel> {
    '@type': T['@type'],
    'parameters': T['parameters'],
}

@Component({
    selector: 'lib-editor',
    template: `
        <ng-template #dynamicContainer></ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorAdapterComponent
    extends DynamicContainerComponent<EditorModel, EditorComponent> {
    @Input() model: EditorModel;
    @Output() modelChange = new EventEmitter<EditorModel>();
    @ViewChild(
        'dynamicContainer',
        {
            read: ViewContainerRef,
            static: true
        },
    ) dynamicContainer: ViewContainerRef;

    private component: EditorComponent;
    private parametersChangesSubscription: Subscription;

    constructor(resolver: ComponentFactoryResolver) {
        super(resolver);
    }

    contextToComponent(model: EditorModel): Type<EditorComponent> {
        switch (model['@type']) {
            case 'document':
                return DocumentComponent;
            case 'cell':
                return CellComponent;
            case 'row':
                return RowComponent;
            case 'horizontalContainer':
                return HorizontalContainerComponent;
            case 'responsiveBlockSet':
                return ResponsiveBlockSetComponent;
            case 'responsiveBlock':
                return ResponsiveBlockComponent;
            case 'plain-element-iterator':
                return PlainElementIteratorComponent;
            case 'button':
                return ButtonPlainElementComponent;
            case 'linkImage':
            case 'verticalSpacer':
            case 'blockImage':
            case 'richText':
            case 'group-plain-element':
                return DefaultComponent;
        }
    }

    onChange(changes: SimpleChanges): void {
        this.setValues();
        if ('ngOnChanges' in this.component) {
            this.component.ngOnChanges(changes);
        }
    }

    setValues() {
        this.component.parameters = this.model.parameters;
    }

    onCreateComponent(component: EditorComponent): void {
        this.component = component;
        this.parametersChangesSubscription = this.component
            .parametersChanges
            .subscribe((params: LinkedModelParameters) => {
                this.modelChange.emit({
                    ['@type']: this.model['@type'],
                    parameters: params,
                });
            });
        this.setValues();
    }

    canRerender(previousModel: EditorModel, currentModel: EditorModel): boolean {
        return previousModel['@type'] !== currentModel['@type'];
    }

    beforeDestroyComponent(component: EditorComponent): void {
        this.parametersChangesSubscription.unsubscribe();
    }
}
