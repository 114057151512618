import {
    Component,
    EventEmitter,
    Output
} from '@angular/core';
import { SelectorService } from '../../services/selector/selector.service';
import { ToolboxListTypes } from '../toolbox-list-group/toolbox-list-types';
import { AddableTypes } from '../../types/addable-types';
import {
    EmptyCellLinkedModelFactory,
    EmptyHorizontalContainerLinkedModelFactory,
    EmptyResponsiveBlockLinkedModelFactory,
    EmptyResponsiveBlockSetContainerLinkedModelFactory,
    EmptyRichTextLinkedModelFactory,
    EmptyRowLinkedModelFactory
} from '../../../../models/linked-models/factories';
import {
    LinkedModel,
    LinkedModelWithParent
} from '../../../../models/linked-models/linked-model';

@Component({
    selector: 'toolbox-viewport',
    template: `
        <toolbox-list-group
            [model]="type"
            (dragStart)="dragStartComponent($event)"></toolbox-list-group>
    `,
    styles: [`
    `],
})
export class ToolboxViewportComponent {
    type: ToolboxListTypes;
    @Output() dragStart = new EventEmitter<LinkedModelWithParent>();

    constructor(
        selectorService: SelectorService,
    ) {
        selectorService.selected$
            .subscribe(v => this.type = v);
    }

    dragStartComponent(type: AddableTypes) {
        const model = this.getComponentByType(type);

        this.dragStart.emit(model as LinkedModelWithParent);
    }

    getComponentByType(type: AddableTypes): LinkedModel {
        switch (type) {
            case 'row':
                return EmptyRowLinkedModelFactory.build();
            case 'responsiveContainer':
                return EmptyResponsiveBlockSetContainerLinkedModelFactory.build();
            case 'responsiveBlock':
                return EmptyResponsiveBlockLinkedModelFactory.build();
            case 'table':
                return EmptyHorizontalContainerLinkedModelFactory.build();
            case 'cell':
                return EmptyCellLinkedModelFactory.build();
            case 'text':
                return EmptyRichTextLinkedModelFactory.build();
            case 'button':
                throw new Error('no button');
            case 'image':
                throw new Error('no image');
            case 'separator':
                throw new Error('no sep');
            case 'html':
                throw new Error('no html');
        }
    }
}
