import { NgModule } from '@angular/core';
import { ToolboxButtonGroupComponent } from './components/toolbox-button-group/toolbox-button-group.component';
import { ToolboxViewportComponent } from './components/toolbox-viewport/toolbox-viewport.component';
import { ComponentListComponent } from './components/component-list/component-list.component';
import { ElementListComponent } from './components/element-list/element-list.component';
import { ThemeListComponent } from './components/theme-list/theme-list.component';
import { ContainerListComponent } from './components/container-list/container-list.component';
import { SelectorService } from './services/selector/selector.service';
import { CommonModule } from '@angular/common';
import { ToolboxListGroupComponent } from './components/toolbox-list-group/toolbox-list-group.component';
import { DndModule } from '../../../../../lib-dnd/src/dnd.module';

@NgModule({
    declarations: [
        ToolboxButtonGroupComponent,
        ToolboxViewportComponent,
        ComponentListComponent,
        ContainerListComponent,
        ElementListComponent,
        ThemeListComponent,
        ToolboxListGroupComponent,
    ],
    imports: [
        CommonModule,
        DndModule
    ],
    providers: [
        SelectorService,
    ],
    exports: [
        ToolboxButtonGroupComponent,
        ToolboxViewportComponent,
    ],
})
export class ToolboxModule {

}
