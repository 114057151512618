import { LinkedModel } from '../../models/linked-models/linked-model';

export class DropPredicate
{
    public static IsDropAvailable(
        container: LinkedModel,
        element: LinkedModel): boolean {
            if (container == element)
            {
               return false;
            }

            switch (container['@type']) {
                case 'document':
                    return element['@type'] === 'row';
                case 'row':
                    return element['@type'] === 'horizontalContainer' || element['@type'] === 'responsiveBlockSet';
                case 'horizontalContainer':
                    return element['@type'] === 'cell';
                case 'responsiveBlockSet':
                    return element['@type'] === 'responsiveBlock';
                case 'responsiveBlock':
                    return element['@type'] === 'horizontalContainer' || element['@type'] === 'responsiveBlockSet';
                case 'richText':
                case 'blockImage':
                case 'linkImage':
                case 'button':
                case 'verticalSpacer':
                    return false;
                case 'plain-element-iterator':
                case 'group-plain-element':
                case 'cell':
                    return element['@type'] === 'horizontalContainer'
                        || element['@type'] === 'responsiveBlockSet'
                        || element['@type'] === 'group-plain-element'
                        || element['@type'] === 'plain-element-iterator'
                        || element['@type'] === 'richText'
                        || element['@type'] === 'blockImage'
                        || element['@type'] === 'linkImage'
                        || element['@type'] === 'button'
                        || element['@type'] === 'verticalSpacer';
            }
        }
}
