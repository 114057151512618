export class Option<T> {
    private constructor(
        private readonly value: T,
        private readonly hasValue: boolean,
    ) {
    }

    public get HasValue(): boolean {
        return this.hasValue;
    }

    public static Some<T>(value: T): Option<T> {
        return new Option<T>(value, true);
    }

    public static None<T>(): Option<T> {
        return new Option(null as any, false);
    }

    public Match<TResult>(
        some: (x: T) => TResult,
        none: () => TResult,
    ): TResult {
        return this.hasValue ? some(this.value) : none();
    }

    public ValueOrFailure(): T {
        if (this.hasValue) {
            return this.value;
        }

        throw new Error('Option: Value is missing');
    }

    public Map<TResult>(mapping: (x: T) => TResult): Option<TResult> {
        return this.Match(
            (x) => Option.Some<TResult>(mapping(x)),
            () => Option.None<TResult>()
        );
    }

    public Else(alternativeOption: Option<T>): Option<T> {
        return this.hasValue ? this : alternativeOption;
    }

    public MatchSome(some: (x: T) => void) {
        if (this.hasValue) {
            some(this.value);
        }
    }

    public MatchNone(none: () => void) {
        if (!this.hasValue) {
            none();
        }
    }

    public Contains(value: T): boolean {
        if (this.hasValue) {
            return this.value === value;
        }

        return false;
    }

    public Exists(predicate: (x: T) => boolean): boolean {
        return this.hasValue && predicate(this.value);
    }
}
