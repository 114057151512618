import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

import { LinkedModel } from '../../../models/linked-models/linked-model';
import { DynamicContainerComponent } from 'projects/lib-dynamic-component/dynamic-container-component';
import { DropInsideEvent } from '../drop-inside-event';
import { DragStartEvent } from '../drag-start-event';
import { TreeContainerNodeComponent } from './tree-container-node/tree-container-node.component';
import { TreeLeafNodeComponent } from './tree-leaf-node/tree-leaf-node.component';
import {
    assertNever,
    hasDeepInside
} from '../../../utils/utils';
import { LinkedModelHelper } from '../../../app.component';

type TreeNodeDynamicComponent = TreeContainerNodeComponent | TreeLeafNodeComponent;

@Component({
    selector: 'lib-tree-dynamic-node-component',
    template: `
        <ng-container #dynamicContainer></ng-container>
    `,
})
export class TreeDynamicNodeComponent
    extends DynamicContainerComponent<LinkedModel, TreeNodeDynamicComponent> {
    @ViewChild(
        'dynamicContainer',
        {
            read: ViewContainerRef,
            static: true
        },
    ) dynamicContainer: ViewContainerRef;

    @Input() model: LinkedModel;
    @Input() selectedElement: LinkedModel;
    @Input() dropPredicate: (model: LinkedModel) => boolean;
    @Input() isDndMode: boolean;
    @Output() select = new EventEmitter<LinkedModel>();
    @Output() dragStart = new EventEmitter<DragStartEvent>();
    @Output() drop = new EventEmitter<DropInsideEvent>();
    @Input() getLabelByModel: (model: LinkedModel) => string;
    @Input() getIconByModel: (model: LinkedModel) => string;
    @Input() level = 0;
    @Input() draggableElementPredicate: (model: LinkedModel) => boolean;

    @Input() position: number;

    component: TreeNodeDynamicComponent;

    constructor(resolver: ComponentFactoryResolver) {
        super(resolver);
    }

    contextToComponent(model: LinkedModel): Type<TreeNodeDynamicComponent> {
        switch (model['@type']) {
            case 'document':
            case 'row':
            case 'horizontalContainer':
            case 'responsiveBlockSet':
            case 'responsiveBlock':
            case 'cell':
            case 'plain-element-iterator':
            case 'group-plain-element':
                return TreeContainerNodeComponent;
            case 'richText':
            case 'button':
            case 'verticalSpacer':
            case 'blockImage':
            case 'linkImage':
                return TreeLeafNodeComponent;
            default:
                assertNever(model);
        }
    }

    onCreateComponent(component: TreeNodeDynamicComponent): void {
        this.component = component;
        this.component.model = this.model;
        this.component.dragStart
            .subscribe((event: DragStartEvent) => {
                this.dragStart
                    .emit(event)
            });
        this.component.select
            .subscribe(($event: LinkedModel) => {
                this.select.emit($event);

                // TODO: optimization
                // console.log('select');
            });

        if (this.component['@type'] == 'treeContainerNodeComponent') {
            this.component.drop
                .subscribe((event: DropInsideEvent) => {
                    this.drop
                        .emit(event);
                })
        }
        this.setValues();
    }

    setValues() {
        this.component.position = this.position;
        this.component.isDndMode = this.isDndMode;
        this.component.selectedElement = this.selectedElement;
        this.component.isDragged = this.draggableElementPredicate(this.model);
        this.component.icon = this.getIconByModel(this.model);
        this.component.title = this.getLabelByModel(this.model);
        this.component.level = this.level;

        if (this.component['@type'] === 'treeContainerNodeComponent') {
            this.component.draggableElementPredicate = this.draggableElementPredicate;
            this.component.dropPredicate = this.dropPredicate;
            this.component.getLabelByModel = this.getLabelByModel;
            this.component.getIconByModel = this.getIconByModel;
            this.component.isOpen = this.component.isOpen ? this.component.isOpen : this.isOpen(this.model);
        }
    }

    isOpen(model: LinkedModel): boolean {
        if (model === this.selectedElement) {
            return false;
        }

        return hasDeepInside(model, this.selectedElement, LinkedModelHelper.childrenAccessor);
    }

    onChange(changes: SimpleChanges): void {
        this.setValues();
    }

    canRerender(previousModel: LinkedModel, currentModel: LinkedModel): boolean {
        return true;
    }

    beforeDestroyComponent(component: TreeNodeDynamicComponent): void {
    }
}
