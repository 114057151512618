import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Validators } from '@angular/forms';
import { InitialStateFormControl } from '../../../helpers/initial-state-form-control';
import { Subscription } from 'rxjs';
import { NumberValidatorFactory } from '../../../helpers/number-validator';

@Component({
    selector: 'lib-pixel-editor',
    template: `
        <input type="number"
            [min]="minValue"
            [max]="maxValue"
            [formControl]="pixelFormControl"
            (focus)="focus.emit($event)"
            (blur)="blur.emit($event); commitValue()"
            (keyup.enter)="commitValue()">
    `,
    styles: [`
        input {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PixelEditorComponent
    implements OnInit,
        OnDestroy {
    @Input() pixels: number;
    @Output() pixelsChange = new EventEmitter<number>();
    @Output() commitChange = new EventEmitter<number>();
    @Output() focus = new EventEmitter<FocusEvent>();
    @Output() blur = new EventEmitter<FocusEvent>();
    @Input() minValue = 0;
    @Input() maxValue: number;
    @Input() disabled: boolean = false;

    pixelFormControl: InitialStateFormControl<{ value: number, disabled: boolean }>;
    pixelChangesSubscription: Subscription;

    ngOnInit(): void {
        this.pixelFormControl = new InitialStateFormControl(
            {
                value: this.pixels,
                disabled: this.disabled,
            },
            [
                Validators.min(this.minValue),
                Validators.max(this.maxValue),
                NumberValidatorFactory.build(),
            ]);
        this.pixelChangesSubscription = this.pixelFormControl
            .valueChanges
            .subscribe((value: number) => {
                if (this.pixelFormControl.valid) {
                    this.pixelsChange.emit(value);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pixels && !changes.pixels.isFirstChange()) {
            this.pixelFormControl
                .setValue(
                    changes.pixels.currentValue,
                    { emitEvent: false })
        }

        if (changes.disabled && !changes.disabled.isFirstChange()) {
            if (changes.disabled.currentValue) {
                this.pixelFormControl
                    .disable({ emitEvent: false });
            } else {
                this.pixelFormControl
                    .enable({ emitEvent: false });
            }
        }
    }

    ngOnDestroy(): void {
        if (this.pixelChangesSubscription) {
            this.pixelChangesSubscription
                .unsubscribe();
        }
    }

    commitValue(): void {
        if (this.pixelFormControl.invalid) {
            this.pixelFormControl.resetValueToInitial();
            return;
        }

        this.commitChange.emit(this.pixels);
    }
}
