import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'lib-width-editor',
    template: `
        <input type="number"
            [ngModel]="width"
            (ngModelChange)="width = $event; widthChange.emit(width)"
            (blur)="commitChange.emit(width)">
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidthEditorComponent {
    @Input() width: number;
    @Output() widthChange = new EventEmitter<number>();
    @Output() commitChange = new EventEmitter<number>();
}
