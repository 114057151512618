import { RowLinkedModel } from './row-linked-model';
import { Color } from '../types/Color';
import { FontFamily } from '../types/FontFamily';
import { Width } from '../types/Width';

export interface DocumentParameters {
    backgroundColor: Color;
    documentWidth: Width;
    // TODO: Make font provider
    fontFamily: FontFamily;
}

export interface DocumentCustomData {
    [key: string]: string;
}


export class DocumentLinkedModel {
    public readonly '@type': 'document' = 'document';
    public parameters: DocumentParameters;
    public customData: DocumentCustomData;
    public children: RowLinkedModel[];

    constructor(params: {
        children: RowLinkedModel[];
        backgroundColor: Color;
        documentWidth: Width;
        fontFamily: FontFamily;
        customData: DocumentCustomData;
    }) {
        this.children = params.children;
        this.parameters = {
            backgroundColor: params.backgroundColor,
            documentWidth: params.documentWidth,
            fontFamily: params.fontFamily,
        };
        this.customData = params.customData;
    }
}
