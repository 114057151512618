import {
    ElementRef,
    EventEmitter,
    Injectable
} from '@angular/core';
import { LinkedModel } from '../../../../models/linked-models/linked-model';
import { DisplayComponentCreateEvent } from './displayComponentCreateEvent';
import { DisplayComponentChangeEvent } from './displayComponentChangeEvent';

@Injectable()
export class DisplayComponentEventBusService {
    public onCreate = new EventEmitter<DisplayComponentCreateEvent>();
    public onChange = new EventEmitter<DisplayComponentChangeEvent>();
    public onDestroy = new EventEmitter<LinkedModel>();

    public create(
        model: LinkedModel,
        position: number,
        elementRef: ElementRef,
    ) {
        this.onCreate
            .emit(
                new DisplayComponentCreateEvent(
                    model,
                    position,
                    elementRef));
    }

    public change(
        model: LinkedModel,
        position: number,
        elementRef: ElementRef,
    ) {
        this.onChange
            .emit(
                new DisplayComponentChangeEvent(
                    model,
                    position,
                    elementRef));
    }

    public destroy(model: LinkedModel) {
        this.onDestroy
            .emit(model);
    }
}
