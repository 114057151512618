import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { BorderStyleGroupModel } from '../../../../../../../lib-editor/src/lib/editors/complex/border-style-group-editor/border-style-group.model';
import { ColorModel } from '../../../../../../../lib-editor/src/lib/editors/primitive/color-editor/color.model';
import { BorderRadiusGroupModel } from '../../../../../../../lib-editor/src/lib/editors/complex/border-radius-group-editor/border-radius-group.model';
import { ButtonPlainElementParameters } from '../../../../models/linked-models/button-plain-element-linked-model';

@Component({
    template: `
        <lib-expandable-group
            titleText="Обводка">
            <div>
                Стиль и толщина:
                <lib-border-style-group-editor
                    [borderStyleGroupModel]="borderGroupModel"
                    (commitChange)="commitAllBordersStyle($event)"></lib-border-style-group-editor>
            </div>
            <div>
                Цвет:
                <lib-color-editor
                    [transparentMode]="false"
                    [colorModel]="borderColorModel"
                    (commitChange)="commitAllBordersColor($event)"></lib-color-editor>
            </div>
            <div>
                Скругление углов:
                <lib-border-radius-group-editor
                    [canExtend]="false"
                    [borderRadiusGroupModel]="borderRadiusGroupModel"
                    (commitChange)="commitAllBorderRadius($event)"></lib-border-radius-group-editor>
            </div>
        </lib-expandable-group>
    `,
    styles: [`
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonPlainElementComponent
    implements OnInit,
        OnChanges {
    @Input() parameters: ButtonPlainElementParameters;
    @Output() parametersChanges = new EventEmitter<ButtonPlainElementParameters>();
    borderGroupModel: BorderStyleGroupModel;
    borderColorModel: ColorModel;
    borderRadiusGroupModel: BorderRadiusGroupModel;

    ngOnInit(): void {
        this.initValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initValues();
    }

    initValues(): void {
        this.borderGroupModel = new BorderStyleGroupModel(
            this.parameters.border,
            this.parameters.border,
            this.parameters.border,
            this.parameters.border,
        );
        this.borderColorModel = new ColorModel(this.parameters.border.color);
        this.borderRadiusGroupModel = new BorderRadiusGroupModel(
            this.parameters.borderRadius,
            this.parameters.borderRadius,
            this.parameters.borderRadius,
            this.parameters.borderRadius,
        );
    }

    commitChange(changes: Partial<ButtonPlainElementParameters>): void {
        this.parameters = { ...this.parameters, ...changes };
        this.parametersChanges.emit(this.parameters);
    }

    commitAllBordersStyle(borderGroupModel: BorderStyleGroupModel): void {
        this.commitChange({
            border: {
                ...this.parameters.border,
                width: borderGroupModel.top.width,
                style: borderGroupModel.top.style,
            }
        })
    }

    commitAllBordersColor(colorModel: ColorModel): void {
        this.commitChange({
            border: {
                ...this.parameters.border,
                color: colorModel.toString(),
            },
        });
    }

    commitAllBorderRadius(borderRadiusGroupModel: BorderRadiusGroupModel) {
        this.commitChange({
            borderRadius: borderRadiusGroupModel.topLeft
        });
    }

}
