import {
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { DocumentLinkedModel } from '../../../../models/linked-models/document-linked-model';
import { DisplayComponentEventBusService } from '../../shared/display-event-bus/display-component-event-bus.service';

@Component({
    selector: 'lib-document',
    template: `
        <div class="document"
            [ngStyle]="{
                backgroundColor: documentModel.parameters.backgroundColor,
                fontFamily: documentModel.parameters.fontFamily
            }">
            <lib-row
                class="row"
                *ngFor="let rowModel of documentModel.children; index as position"
                [rowModel]="rowModel"
                [position]="position"
                [isResponsive]="isResponsive"></lib-row>
        </div>
    `,
    styles: [`
        .row {
            display: block;
        }
    `, `
        /* TODO: Email Template styles */
        p {
            margin: 0;
        }
    `],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class DocumentComponent
    implements OnInit {
    @Input() documentModel: DocumentLinkedModel;
    @Input() isResponsive: boolean;

    constructor(
        private elementRef: ElementRef,
        public displayComponentEventService: DisplayComponentEventBusService) {
    }

    ngOnInit(): void {
        this.displayComponentEventService.create(
            this.documentModel,
            0,
            this.elementRef);
    }
}
