import {
    ChangeDetectionStrategy,
    Component,
    Input
} from '@angular/core';
import { ColorModel } from '../../editors/primitive/color-editor/color.model';

@Component({
    selector: 'lib-color-preview',
    template: `
        <div class="color-preview"
            [ngClass]="{
                'color-preview--transparent': isTransparent
            }"
            [ngStyle]="{
                backgroundColor: color
            }">
            <ng-content></ng-content>
        </div>
    `,
    styles: [`
        .color-preview {
            width: 100%;
            height: 100%;
        }

        .color-preview--transparent {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABVSURBVHgB7dExEQAgDMDAgq3ab3UBHtKBIa8gd1lVdWJQZsakHZ8zkDKQMpAykDKQMpAykDKQMpBa54lB3R2TXEwZSBlIGUgZSBlIGUgZSBlIfR94AV+wCpZTk1lWAAAAAElFTkSuQmCC');
            background-size: 20px 20px;
            background-position: left top;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPreviewComponent {
    @Input() color: string;

    get isTransparent(): boolean {
        return ColorModel.isTransparent(this.color);
    }
}
