import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ResponsiveBlockLinkedModel } from '../../../../models/linked-models/responsive-block-linked-model';
import { DisplayComponentEventBusService } from '../../shared/display-event-bus/display-component-event-bus.service';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../../../utils/angular-extensions';

@Component({
    selector: 'lib-responsive-block',
    templateUrl: './responsive-block.component.html',
    styles: [`
        .responsive-block {
            position: relative;
            display: flex;
        }

        .children-container {
            position: relative;
            width: 100%;
        }

        .container {
            position: relative;
            display: block;
        }
    `],
})
export class ResponsiveBlockComponent
    implements OnInit,
        CustomOnChanges,
        OnDestroy {
    @Input() responsiveBlockModel: ResponsiveBlockLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;

    constructor(
        private elementRef: ElementRef,
        public displayComponentEventService: DisplayComponentEventBusService) {
    }

    ngOnInit(): void {
        this.displayComponentEventService.create(
            this.responsiveBlockModel,
            this.position,
            this.elementRef)
    }

    ngOnChanges(changes: CustomSimpleChanges<this>): void {
        if (changes.position) {
            this.displayComponentEventService.change(
                this.responsiveBlockModel,
                this.position,
                this.elementRef);
        }
    }

    ngOnDestroy(): void {
        this.displayComponentEventService.destroy(this.responsiveBlockModel);
    }
}
