export class BidiMap<TKey, TValue> extends Map<TKey, TValue> {
    private bidiMap = new Map<TValue, TKey>();

    set(key: TKey, value: TValue): this {
        this.bidiMap.set(value, key);

        return super.set(key, value);
    }

    getKey(value: TValue): TKey | undefined {
        return this.bidiMap.get(value);
    }

    getKeyOrThrow(value: TValue): TKey {
        const key = this.getKey(value);

        if (key === undefined) {
            throw new Error('BidiMap: Value is not exists');
        }

        return key;
    }

    hasValue(value: TValue): boolean {
        return this.bidiMap.has(value);
    }

}
