import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { HeightModel } from '../../../../../../../lib-editor/src/lib/editors/primitive/height-editor/height.model';
import { ColorModel } from '../../../../../../../lib-editor/src/lib/editors/primitive/color-editor/color.model';
import { BorderStyleGroupModel } from '../../../../../../../lib-editor/src/lib/editors/complex/border-style-group-editor/border-style-group.model';
import { HorizontalContainerParameters } from '../../../../models/linked-models/horizontal-container-linked-model';
import { BorderRadiusGroupModel } from '../../../../../../../lib-editor/src/lib/editors/complex/border-radius-group-editor/border-radius-group.model';
import { AlignModel } from '../../../../../../../lib-editor/src/lib/editors/primitive/align-editor/align.model';

@Component({
    template: `
        <lib-background-group
            [background]="parameters.background"
            (commitChange)="commitChange({ background: $event })"></lib-background-group>

        <lib-expandable-group
            titleText="Положение">
            <fieldset>
                <legend>Горизонтальное выравнивание</legend>
                <lib-align-editor
                    [align]="align"
                    (commitChange)="commitAlign($event)"></lib-align-editor>
                <lib-switcher-editor
                    label="Растянуть на всю ширину"
                    [enable]="parameters.isFullWidth"
                    (commitChange)="commitChange({ isFullWidth: $event })"></lib-switcher-editor>
            </fieldset>
        </lib-expandable-group>

        <lib-expandable-group
            titleText="Обводка">
            <div>
                Стиль и толщина:
                <lib-border-style-group-editor
                    [borderStyleGroupModel]="borderGroupModel"
                    (commitChange)="commitAllBordersStyle($event)"></lib-border-style-group-editor>
            </div>
            <div>
                Цвет:
                <lib-color-editor
                    [transparentMode]="false"
                    [colorModel]="borderColorModel"
                    (commitChange)="commitAllBordersColor($event)"></lib-color-editor>
            </div>
            <div>
                Скругление углов:
                <lib-border-radius-group-editor
                    [borderRadiusGroupModel]="borderRadiusGroupModel"
                    (commitChange)="commitAllBorderRadius($event)"></lib-border-radius-group-editor>
            </div>
        </lib-expandable-group>

        <lib-expandable-group
            titleText="Cell settings">
            <div>
                Cells height:
                <lib-auto-height-editor
                    [heightModel]="heightModel"
                    (commitChange)="commitChange({ cellsHeight: $event.getValue() })"></lib-auto-height-editor>
            </div>
        </lib-expandable-group>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalContainerComponent
    implements OnInit,
        OnChanges {
    @Input() parameters: HorizontalContainerParameters;
    @Output() parametersChanges = new EventEmitter<HorizontalContainerParameters>();
    heightModel: HeightModel;
    borderGroupModel: BorderStyleGroupModel;
    borderColorModel: ColorModel;
    borderRadiusGroupModel: BorderRadiusGroupModel;
    align: AlignModel;

    ngOnInit(): void {
        this.initValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initValues();
    }

    initValues(): void {
        this.heightModel = new HeightModel(this.parameters.cellsHeight);
        this.borderGroupModel = new BorderStyleGroupModel(
            this.parameters.border.top,
            this.parameters.border.right,
            this.parameters.border.bottom,
            this.parameters.border.left,
        );
        this.borderColorModel = new ColorModel(this.parameters.border.top.color);
        this.borderRadiusGroupModel = new BorderRadiusGroupModel(
            this.parameters.borderRadius.topLeft,
            this.parameters.borderRadius.topRight,
            this.parameters.borderRadius.bottomRight,
            this.parameters.borderRadius.bottomLeft,
        );
        this.align = this.parameters.align;
    }

    commitChange(changes: Partial<HorizontalContainerParameters>): void {
        this.parameters = { ...this.parameters, ...changes };
        this.parametersChanges.emit(this.parameters);
    }

    commitAllBordersStyle(borderGroupModel: BorderStyleGroupModel): void {
        this.commitChange({
            border: {
                top: {
                    ...this.parameters.border.top,
                    width: borderGroupModel.top.width,
                    style: borderGroupModel.top.style,
                },
                bottom: {
                    ...this.parameters.border.bottom,
                    width: borderGroupModel.bottom.width,
                    style: borderGroupModel.bottom.style,
                },
                left: {
                    ...this.parameters.border.left,
                    width: borderGroupModel.left.width,
                    style: borderGroupModel.left.style,
                },
                right: {
                    ...this.parameters.border.right,
                    width: borderGroupModel.right.width,
                    style: borderGroupModel.right.style,
                },
            }
        })
    }

    commitAllBordersColor(colorModel: ColorModel): void {
        this.commitChange({
            border: {
                top: {
                    ...this.parameters.border.top,
                    color: colorModel.toString(),
                },
                right: {
                    ...this.parameters.border.right,
                    color: colorModel.toString(),
                },
                bottom: {
                    ...this.parameters.border.bottom,
                    color: colorModel.toString(),
                },
                left: {
                    ...this.parameters.border.left,
                    color: colorModel.toString(),
                },
            },
        });
    }

    commitAllBorderRadius(borderRadiusGroupModel: BorderRadiusGroupModel) {
        this.commitChange({
            borderRadius: { ...borderRadiusGroupModel }
        });
    }

    commitAlign(align: AlignModel) {
        this.commitChange({
            align: align,
        });
    }
}
