import { CellLinkedModel } from './cell-linked-model';
import { AutoHeight } from '../types/Height';
import { Background } from '../types/Background';
import { BorderGroup } from '../types/BorderGroup';
import { BorderRadiusGroup } from '../types/BorderRadiusGroup';
import { HorizontalAlign } from '../types/HorizontalAlign';

export interface HorizontalContainerParameters {
    align: HorizontalAlign;
    isFullWidth: boolean;
    cellsHeight: AutoHeight;
    background: Background;
    border: BorderGroup;
    borderRadius: BorderRadiusGroup;
}

export class HorizontalContainerLinkedModel {
    public readonly '@type': 'horizontalContainer' = 'horizontalContainer';

    public parameters: HorizontalContainerParameters;
    public children: CellLinkedModel[];

    constructor(params: {
        children: CellLinkedModel[];
        background: Background;
        border: BorderGroup;
        isFullWidth: boolean;
        cellsHeight: AutoHeight;
        borderRadius: BorderRadiusGroup;
        align: HorizontalAlign;
    }) {
        this.children = params.children;
        this.parameters = {
            align: params.align,
            isFullWidth: params.isFullWidth,
            cellsHeight: params.cellsHeight,
            background: params.background,
            border: params.border,
            borderRadius: params.borderRadius,
        };
    }
}
