import {
    ChangeDetectorRef,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    Type,
    ViewContainerRef
} from '@angular/core';

@Directive()
export abstract class DynamicContainerComponent<TModel,
    TComponent>
    implements OnInit,
        OnChanges,
        OnDestroy {
    abstract dynamicContainer: ViewContainerRef;
    abstract model: TModel;
    protected componentInstance: TComponent | null;
    private componentRef: ComponentRef<TComponent>;
    private componentChangeDetectorRef: ChangeDetectorRef;

    protected constructor(private resolver: ComponentFactoryResolver) {
    }

    private static keyName(n: keyof DynamicContainerComponent<any, any>) {
        return n;
    }

    abstract contextToComponent(contextModel: TModel): Type<TComponent>;

    abstract onCreateComponent(component: TComponent): void;

    abstract beforeDestroyComponent(component: TComponent): void;

    abstract onChange(changes: SimpleChanges): void;

    abstract canRerender(previousModel: TModel, currentModel: TModel): boolean;

    ngOnInit() {
        this.createComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        const modelSimpleChange = changes[DynamicContainerComponent.keyName('model')];
        if (modelSimpleChange) {
            if (!this.componentInstance
                || this.canRerender(modelSimpleChange.previousValue, modelSimpleChange.currentValue)) {
                this.createComponent();
            }
        }
        this.onChange(changes);
        this.componentChangeDetectorRef.markForCheck();
    }

    ngOnDestroy() {
        if (this.componentInstance) {
            this.componentInstance = null;
        }
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    private createComponent() {
        this.validate();
        if (this.componentInstance) {
            this.beforeDestroyComponent(this.componentInstance);
        }
        this.dynamicContainer.clear();

        const componentType = this.contextToComponent(this.model);
        if (!componentType) {
            throw new Error(`Component not found`);
        }

        const factory: ComponentFactory<TComponent> = this.resolver
            .resolveComponentFactory(componentType);
        this.componentRef = this.dynamicContainer
            .createComponent(factory);
        this.componentInstance = this.componentRef.instance;
        this.componentChangeDetectorRef = this.componentRef
            .injector
            .get(ChangeDetectorRef);
        this.onCreateComponent(this.componentInstance);
    }

    private validate() {
        if (this.dynamicContainer == null) {
            throw new Error('Dynamic container (#dynamicContainer) not found');
        }
        if (this.model == null) {
            throw new Error(`Model (${DynamicContainerComponent.keyName('model')} input parameter) not found`);
        }
    }
}
