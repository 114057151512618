import {
    Component,
    EventEmitter,
    Output
} from '@angular/core';
import { IDynamicComponent } from '../../../../../../../lib-dynamic-component/i-dynamic-component';
import { ToolboxListTypes } from '../toolbox-list-group/toolbox-list-types';
import { AddableTypes } from '../../types/addable-types';

@Component({
    template: `
        <h2>Контейнеры</h2>
        <div class="list">
            <div class="card"
                libDraggable
                (libDraggableDragStart)="dragStart.emit('row')">
                <div class="card__icon">
                    <img src="/assets/container-row-icon.png"
                        draggable="false"
                        alt="">
                </div>
                <div class="card__title">
                    Полоса
                </div>
            </div>
            <div class="card"
                libDraggable
                (libDraggableDragStart)="dragStart.emit('responsiveContainer')">
                <div class="card__icon">
                    <img src="/assets/container-responsive-block-set-icon.png"
                        draggable="false"
                        alt="">
                </div>
                <div class="card__title">
                    Адаптивный контейнер
                </div>
            </div>
            <div class="card"
                libDraggable
                (libDraggableDragStart)="dragStart.emit('responsiveBlock')">
                <div class="card__icon">
                    <img src="/assets/container-responsive-block-icon.png"
                        draggable="false"
                        alt="">
                </div>
                <div class="card__title">
                    Адаптивный блок
                </div>
            </div>
            <div class="card"
                libDraggable
                (libDraggableDragStart)="dragStart.emit('table')">
                <div class="card__icon">
                    <img src="/assets/container-table-icon.png"
                        draggable="false"
                        alt="">
                </div>
                <div class="card__title">
                    Строчный контейнер
                </div>
            </div>
            <div class="card"
                libDraggable
                (libDraggableDragStart)="dragStart.emit('cell')">
                <div class="card__icon">
                    <img src="/assets/container-cell-icon.png"
                        draggable="false"
                        alt="">
                </div>
                <div class="card__title">
                    Ячейка
                </div>
            </div>
        </div>
    `,
    styles: [`
        h2 {
            display: flex;
            margin: 0;
            padding: 0 10px;
            height: 32px;
            align-items: center;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
        }

        .card {
            width: 70px;
            margin: 0 5px;
            margin-bottom: 10px;

            background-color: #ffffff;

            user-select: none;
        }

        .card:hover {
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        }

        .card__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 55px;
        }

        .card__title {
            height: 35px;

            font-size: 11px;
            line-height: 15px;

            text-align: center;
            color: #333333;
        }
    `],
})
export class ContainerListComponent
    implements IDynamicComponent<ToolboxListTypes> {
    model: ToolboxListTypes;
    @Output() dragStart = new EventEmitter<AddableTypes>();

}
