import {
    AbstractControlOptions,
    AsyncValidatorFn,
    FormControl,
    ValidatorFn
} from '@angular/forms';

export class InitialStateFormControl<T extends { value: any }> extends FormControl {
    private readonly initialState: T;

    constructor(
        formState: T,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        const boxedValue = { disabled: false, ...formState };
        super(boxedValue, validatorOrOpts, asyncValidator);
        this.initialState = formState;
    }

    resetStateToInitial(): void {
        super.reset(this.initialState);
    }

    resetValueToInitial(): void {
        super.reset(this.initialState.value);
    }
}
