import { ContainerLinkedModel } from './container-linked-model';
import { PaddingGroup } from '../types/PaddingGroup';
import { Width } from '../types/Width';
import { Percents } from '../types/Percents';

export interface ResponsiveBlockParameters {
    width: Width;
    padding: PaddingGroup;
}

export interface ResponsiveBlockResponsiveParameters {
    widthInPercents: Percents;
}

export class ResponsiveBlockLinkedModel {
    public readonly '@type': 'responsiveBlock' = 'responsiveBlock';
    public children: ContainerLinkedModel[];
    public parameters: ResponsiveBlockParameters;
    public responsiveParameters: ResponsiveBlockResponsiveParameters;

    constructor(params: {
        children: ContainerLinkedModel[],
        width: Width,
        padding: PaddingGroup,
        responsiveWidthInPercents: Percents,
    }) {
        this.children = params.children;
        this.parameters = {
            width: params.width,
            padding: params.padding,
        };
        this.responsiveParameters = {
            widthInPercents: params.responsiveWidthInPercents,
        };
    }
}
