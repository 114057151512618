export class MapHelper {
    static getOrThrow<K, T>(map: Map<K, T>, key: K): T | never {
        const value = map.get(key);
        if (value === undefined) {
            throw new Error(`Map: not found by ${key}`);
        }

        return value;
    }

    static getOrDefault<K, T>(map: Map<K, T>, key: K, defaultGenerator: (key: K) => T): T {
        const result = map.get(key);
        if (result !== undefined) {
            return result;
        }

        return defaultGenerator(key);
    }
}
