import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    BorderStyleGroupModel,
    BorderStyleModel
} from './border-style-group.model';
import { BorderStyle } from '../../../../../../demo/src/app/models/types/BorderGroup';
import { Pixels } from '../../../../../../demo/src/app/models/types/Pixels';

@Component({
    selector: 'lib-border-style-group-editor',
    template: `
        <div class="border-style-group-editor">
            <div class="border-style-group-editor__main">
                <select
                    class="border-style-select"
                    [ngModel]="commonBorderStyle.style"
                    (ngModelChange)="setAllBordersStyle($event)"
                    (blur)="commitChange.emit(borderStyleGroupModel)">
                    <option
                        class="border-option"
                        [ngValue]="'solid'">
                        Сплошная
                    </option>
                    <option
                        class="border-option"
                        [ngValue]="'dashed'">
                        Пунктирная
                    </option>
                    <option
                        class="border-option"
                        [ngValue]="'dotted'">
                        Точечная
                    </option>
                    <option
                        class="border-option"
                        [ngValue]="'none'">
                        Отсутствует
                    </option>
                </select>
                <div class="border-width">
                    <lib-pixel-editor
                        class="border-width__input"
                        title="Border width"
                        [disabled]="commonBorderStyle.style === 'none'"
                        [pixels]="commonBorderStyle.width"
                        [maxValue]="BORDER_WIDTH_MAX"
                        (pixelsChange)="setAllBordersWidth($event)"
                        (commitChange)="commitChange.emit(borderStyleGroupModel)"
                    ></lib-pixel-editor>
                </div>
            </div>
            <div class="border-style-group-editor__suffix">
                px
            </div>
        </div>
    `,
    styles: [`
        .border-style-group-editor {
            display: flex;
            align-items: center;
        }

        .border-style-group-editor__main {
            display: flex;
            flex-grow: 1;
            height: 32px;
            border: 1px solid #cccccc;
            border-radius: 4px;
        }

        .border-style-group-editor__suffix {
            margin-left: 5px;
            font-size: 14px;
            line-height: 19px;
        }

        .border-style-select {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            border: 0;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 19px;
            color: inherit;
            border-radius: 4px 0 0 4px;
        }

        .border-width {
            flex-basis: 48px;
            flex-grow: 0;
            flex-shrink: 0;
            border-left: 1px solid #cccccc;
            border-radius: 0 4px 4px 0;
            overflow: hidden;
        }

        .border-width ::ng-deep input {
            padding-left: 10px;
            padding-right: 0;
            border: 0;
            text-align: left;
        }

        .border-width ::ng-deep input:disabled {
            color: #b1b1b1;
            background-color: #eeeeee;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BorderStyleGroupEditorComponent {
    @Input() borderStyleGroupModel: BorderStyleGroupModel;
    @Output() borderStyleGroupModelChange = new EventEmitter<BorderStyleGroupModel>();
    @Output() commitChange = new EventEmitter<BorderStyleGroupModel>();
    readonly BORDER_WIDTH_MAX = 40;

    get commonBorderStyle(): BorderStyleModel {
        return this.borderStyleGroupModel.top;
    }

    patchValue(changes: Partial<BorderStyleGroupModel>): void {
        this.borderStyleGroupModel = { ...this.borderStyleGroupModel, ...changes };
        this.borderStyleGroupModelChange.emit(this.borderStyleGroupModel);
    }

    setAllBordersStyle(type: BorderStyle) {
        this.patchValue({
            top: {
                style: type,
                width: this.borderStyleGroupModel.top.width,
            },
            right: {
                style: type,
                width: this.borderStyleGroupModel.right.width,
            },
            bottom: {
                style: type,
                width: this.borderStyleGroupModel.bottom.width,
            },
            left: {
                style: type,
                width: this.borderStyleGroupModel.left.width,
            },
        });
    }

    setAllBordersWidth(width: Pixels) {
        this.patchValue({
            top: {
                style: this.borderStyleGroupModel.top.style,
                width: width,
            },
            right: {
                style: this.borderStyleGroupModel.right.style,
                width: width,
            },
            bottom: {
                style: this.borderStyleGroupModel.bottom.style,
                width: width,
            },
            left: {
                style: this.borderStyleGroupModel.left.style,
                width: width,
            },
        });
    }
}
