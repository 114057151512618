import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { AlignModel } from './align.model';

@Component({
    selector: 'lib-align-editor',
    template: `
        <div class="button-group">
            <div class="button-group__row">
                <button class="button button--align button--left"
                    title="По левому краю"
                    [disabled]="align === 'left'"
                    (click)="commitAlignPosition('left')">
                    <svg width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="5"
                            y="5"
                            width="10"
                            height="6"
                            rx="1"
                            fill="#333333"/>
                        <rect x="2"
                            width="2"
                            height="16"
                            rx="1"
                            fill="#333333"/>
                    </svg>
                </button>
                <button class="button button--align button--center"
                    title="По центру"
                    [disabled]="align === 'center'"
                    (click)="commitAlignPosition('center')">
                    <svg width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="3"
                            y="5"
                            width="10"
                            height="6"
                            rx="1"
                            fill="#333333"/>
                        <rect x="7"
                            width="2"
                            height="16"
                            rx="1"
                            fill="#333333"/>
                    </svg>
                </button>
                <button class="button button--align button--right"
                    title="По правому краю"
                    [disabled]="align === 'right'"
                    (click)="commitAlignPosition('right')">
                    <svg width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="2"
                            y="5"
                            width="10"
                            height="6"
                            rx="1"
                            fill="#333333"/>
                        <rect x="13"
                            width="2"
                            height="16"
                            rx="1"
                            fill="#333333"/>
                    </svg>
                </button>
            </div>
        </div>
    `,
    styles: [`
        /* TODO: remake to scss */
        .button {
            box-sizing: border-box;
            padding: 0;
            border: 1px solid #cccccc;
            background-color: #ffffff;
        }

        .button:disabled {
            background-color: #e6e6e6;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }

        .button--align {
            width: 48px;
            height: 32px;
        }

        .button-group__row .button + .button {
            margin-left: -1px;
        }

        .button-group__row:first-child .button:first-child {
            border-top-left-radius: 4px;
        }

        .button-group__row:first-child .button:last-child {
            border-top-right-radius: 4px;
        }

        .button-group__row:last-child .button:first-child {
            border-bottom-left-radius: 4px;
        }

        .button-group__row:last-child .button:last-child {
            border-bottom-right-radius: 4px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlignEditorComponent {
    @Input() align: AlignModel;
    @Output() alignChange = new EventEmitter<AlignModel>();
    @Output() commitChange = new EventEmitter<AlignModel>();

    commitAlignPosition(align: AlignModel) {
        this.align = align;
        this.alignChange.emit(this.align);
        this.commitChange.emit(this.align);
    }
}
