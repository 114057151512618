import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ResponsiveBlockSetContainerLinkedModel } from '../../../../../models/linked-models/responsive-block-set-container-linked-model';
import { DisplayComponentEventBusService } from '../../../shared/display-event-bus/display-component-event-bus.service';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../../../../utils/angular-extensions';

@Component({
    template: `
        <div
            class="responsive-block-set"

            [ngStyle]="{
                backgroundColor: model.parameters.background.color,
                backgroundImage: 'url(' + model.parameters.background.image.url + ')',
                backgroundPosition: model.parameters.background.image.position,
                backgroundRepeat: model.parameters.background.image.repeat,
                paddingTop: model.parameters.padding.top + 'px',
                paddingBottom: model.parameters.padding.bottom + 'px',
                paddingLeft: model.parameters.padding.left + 'px',
                paddingRight: model.parameters.padding.right + 'px'
            }">
            <div class="responsive-blocks-container"
                [ngStyle]="{
                    alignItems: alignItems,
                    justifyContent: justifyContent
                }">
                <lib-responsive-block
                    *ngFor="let responsiveBlockModel of model.children; index as position"
                    [responsiveBlockModel]="responsiveBlockModel"
                    [position]="position"
                    [isResponsive]="isResponsive"

                    [ngStyle]="{
                        flexBasis: isResponsive
                            ? responsiveBlockModel.responsiveParameters.widthInPercents + '%'
                            : responsiveBlockModel.parameters.width + 'px'
                    }"></lib-responsive-block>
            </div>
        </div>
    `,
    styles: [`
        .responsive-block-set {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            box-sizing: border-box;
        }

        .responsive-blocks-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            position: relative;
            width: 100%;
        }
    `],
})
export class ResponsiveBlockSetContainerComponent
    implements OnInit,
        CustomOnChanges,
        OnDestroy {
    @Input() model: ResponsiveBlockSetContainerLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;

    constructor(
        private readonly elementRef: ElementRef,
        public displayComponentEventService: DisplayComponentEventBusService) {
    }

    get alignItems(): 'flex-start' | 'center' | 'flex-end' {
        switch (this.model.parameters.columnsVerticalAlign) {
            case 'top':
                return 'flex-start';
            case 'middle':
                return 'center';
            case 'bottom':
                return 'flex-end';
        }
    }

    get justifyContent(): 'flex-start' | 'center' | 'flex-end' {
        switch (this.model.parameters.columnsHorizontalAlign) {
            case 'left':
                return 'flex-start';
            case 'center':
                return 'center';
            case 'right':
                return 'flex-end';
        }
    }

    ngOnInit(): void {
        this.displayComponentEventService.create(
            this.model,
            this.position,
            this.elementRef)
    }

    ngOnChanges(changes: CustomSimpleChanges<this>): void {
        if (changes.position) {
            this.displayComponentEventService.change(
                this.model,
                this.position,
                this.elementRef);
        }
    }

    ngOnDestroy(): void {
        this.displayComponentEventService.destroy(this.model);
    }
}
