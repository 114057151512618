import { Pixels } from '../types/Pixels';

export interface VerticalSpacerPlainElementParameters {
    size: Pixels;
}

export class VerticalSpacerPlainElementLinkedModel {
    public readonly '@type': 'verticalSpacer' = 'verticalSpacer';
    public parameters: VerticalSpacerPlainElementParameters;

    constructor(params: {
        size: Pixels;
    }) {
        this.parameters = {
            size: params.size,
        };
    }
}
