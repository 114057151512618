export function randomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomElement<T>(elements: T[]): T {
    return elements[randomNumber(0, elements.length - 1)];
}

export function getRandomChildren<T>(
    currentNode: T,
    accessor: (node: T) => T[],
): T {
    const children = accessor(currentNode);
    if (children.length === 0) {
        return currentNode;
    }

    const childNode = randomElement(children);

    return getRandomChildren(
        childNode,
        accessor,
    );
}

export function getPathFromRoot<T>(
    root: T,
    target: T,
    childrenAccessor: (model: T) => T[],
    result: T[] = [],
): T[] {
    if (root === target) {
        return [...result, target];
    }

    let res = result;
    const children = childrenAccessor(root);
    for (let item of children) {
        res = getPathFromRoot(item, target, childrenAccessor, result);
        if (res.length !== result.length) {
            return [root, ...res];
        }
    }

    return res;
}

export function hasDeepInside<T>(
    root: T,
    target: T,
    childrenAccessor: (model: T) => T[],
): boolean {
    const children = childrenAccessor(root);
    if (children.includes(target)) {
        return true;
    }

    for (let child of children) {
        if (hasDeepInside(child, target, childrenAccessor)) {
            return true;
        }
    }

    return false;
}

export class DelayedFunction {
    private timeoutId: number;
    private readonly func: Function;
    private readonly timeoutMs: number;

    constructor(params: {
        func: Function,
        timeoutMs: number,
    }) {
        this.func = params.func;
        this.timeoutMs = params.timeoutMs;
    }

    apply(): void {
        this.timeoutId = setTimeout(
            () => {
                this.func();
            },
            this.timeoutMs,
        );
    }

    cancel(): void {
        clearTimeout(this.timeoutId);
    }
}

export function assertNever(x: never): never {
    throw new Error('Unexpected object: ' + x);
}
