<main class="main">
    <div class="debugzone">
        <button (click)="selectRandomElement()">select random element</button>
        <button (click)="addRow()">add row</button>
        <button (click)="moveFirstRowDown()">move first row down</button>
        <button (click)="deleteFirstRow()">delete first row</button>
        <button (click)="addContainer()">add container</button>
        <button
            [disabled]="!stateManagerAdapter.canUndo"
            (click)="stateManagerAdapter.undo()">undo
        </button>
        <button
            [disabled]="!stateManagerAdapter.canRedo"
            (click)="stateManagerAdapter.redo()">redo
        </button>
        <div>
            <label>
                viewport width:
                <input type="number"
                    [(ngModel)]="viewportWidth">
            </label>
            |
            <button (click)="viewportWidth=1280">Laptop</button>
            <button (click)="viewportWidth=375">Iphone</button>
        </div>
        <div>
            <button (click)="showOverlay = !showOverlay">showOverlay</button>
        </div>
    </div>
    <div class="header">
        header
    </div>
    <div class="workzone">
        <div style="flex-basis: 290px;flex-shrink: 0;display:flex;box-shadow: 1px 0 3px rgba(3, 4, 4, 0.25);position: relative;">
            <div style="flex-basis: 40px; flex-shrink: 0; background-color: #3c4a59;">
                <toolbox-button-group></toolbox-button-group>
            </div>
            <div style="flex-grow: 1;background-color: #fafafa;max-width: 100%;display: flex;flex-direction: column;">
                <toolbox-viewport (dragStart)="dragAddStart($event)"></toolbox-viewport>
                <div class="navigator">
                    <div class="navigator__inner">
                        <h2 style="display:flex;align-items:center;margin: 0;padding: 0 10px;height: 24px;">
                            Навигатор
                        </h2>
                        <div style="padding: 1px;">
                            <lib-tree-dynamic-node-component
                                [selectedElement]="selectedElement"
                                (dragStart)="dragMoveStart($event)"
                                (drop)="dropInside($event)"
                                [dropPredicate]="dropInsidePredicate"
                                [isDndMode]="isDragging"
                                [model]="documentLinkedModel"
                                (select)="select($event)"
                                [getLabelByModel]="getLabelByModel"
                                [getIconByModel]="getIconByModel"
                                [draggableElementPredicate]="draggableElementPredicateRef"
                            ></lib-tree-dynamic-node-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="workzone__middle">
            <lib-breadcrumb-adapter
                [idMapper]="idMapper"
                [(element)]="selectedElement"
                [getPathFromRoot]="getPathFromRootRef"
                [getLabelByModel]="getLabelByModel"></lib-breadcrumb-adapter>
            <div class="viewport">
                <div class="viewport__inside">
                    <lib-viewport
                        class="viewport__wrapper"
                        [width]="viewportWidth"
                        (resize)="viewportResize()">
                        <lib-display
                            [showOverlay]="showOverlay"
                            [documentLinkedModel]="documentLinkedModel"
                            [isDropMode]="isDragging"
                            [dropInsidePredicate]="dropInsidePredicateRef"
                            [dropBeforePredicate]="dropBeforeAfterPredicateRef"
                            [dropAfterPredicate]="dropBeforeAfterPredicateRef"
                            [dropHorizontalPredicate]="dropHorizontalPredicateRef"
                            [dropVerticalPredicate]="dropVerticalPredicateRef"
                            [draggableElementPredicate]="draggableElementPredicateRef"
                            [selectedElement]="selectedElement"
                            [getLabelByModel]="getLabelByModel"
                            [getIconByModel]="getIconByModel"
                            (dragStart)="dragMoveStart($event)"
                            (dropInside)="dropInside($event)"
                            (dropBefore)="dropBefore($event)"
                            (dropAfter)="dropAfter($event)"
                            (selectElement)="select($event)"
                            (copy)="copy($event)"
                            (remove)="remove($event)">
                        </lib-display>
                    </lib-viewport>
                </div>
            </div>
        </div>
        <div style="flex-shrink: 0;flex-basis: 250px;background-color: #fafafa;box-shadow: -1px 0 3px rgba(3, 4, 4, 0.25);overflow:hidden;">
            <lib-options
                [model]="{ '@type': selectedElement['@type'], parameters: selectedElement.parameters }"
                (modelChange)="changeParameters(selectedElement, selectedElement.parameters, $event.parameters)"></lib-options>
        </div>
    </div>
    <lib-drag-image *ngIf="dragImage"
        [model]="dragImage"></lib-drag-image>
</main>
