import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output
} from '@angular/core';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../utils/angular-extensions';

@Component({
    selector: 'lib-viewport',
    template: `
        <div class="scope">
            <ng-content></ng-content>
        </div>
    `,
    styles: [`
        :host {
            display: block;
            position: relative;
            overflow: hidden;
            padding-top: 20px;
        }

        .scope {
            position: relative;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewportComponent
    implements CustomOnChanges {
    @Input() width: number;
    @Output() resize = new EventEmitter();

    @HostBinding('style.width')
    get hostWidth(): string {
        return this.width + 'px';
    }

    ngOnChanges(changes: CustomSimpleChanges<this>): void {
        if (changes.width && !changes.width.firstChange) {
            this.resize.emit();
        }
    }

}
