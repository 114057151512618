import { BreadcrumbAdapterComponent } from './breadcrumb-adapter.component';
import { NgModule } from '@angular/core';
import { LibBreadcrumbsModule } from '../../../../../lib-breadcrumbs/src/public-api';

@NgModule({
    declarations: [
        BreadcrumbAdapterComponent,
    ],
    imports: [
        LibBreadcrumbsModule,
    ],
    exports: [
        BreadcrumbAdapterComponent,
    ],
})
export class BreadcrumbAdapterModule {
}
