import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    LinkedModel,
    LinkedModelContainers
} from 'projects/demo/src/app/models/linked-models/linked-model';
import { ITreeNodeDynamicComponent } from '../i-tree-node-component';
import { DropInsideEvent } from '../../drop-inside-event';
import { DragStartEvent } from '../../drag-start-event';
import { DelayedFunction } from '../../../../utils/utils';

@Component({
    selector: 'lib-tree-container-node-component',
    templateUrl: './tree-container-node.component.html',
    styleUrls: ['./tree-container-node.component.css'],
})
export class TreeContainerNodeComponent
    implements ITreeNodeDynamicComponent {
    readonly '@type': 'treeContainerNodeComponent' = 'treeContainerNodeComponent';
    @Input() selectedElement: LinkedModel;
    @Input() dropPredicate: (model: LinkedModel) => boolean;
    @Input() isDndMode: boolean;
    @Output() select: EventEmitter<LinkedModel> = new EventEmitter<LinkedModel>();
    @Output() drop: EventEmitter<DropInsideEvent> = new EventEmitter<DropInsideEvent>();
    @Output() dragStart: EventEmitter<DragStartEvent> = new EventEmitter<DragStartEvent>();
    @Input() model: LinkedModelContainers;
    dropAvailable: boolean;
    @Input() position: number = 0;
    @Input() title: string;
    @Input() icon: string;
    @Input() getLabelByModel: (model: LinkedModel) => string;
    @Input() getIconByModel: (model: LinkedModel) => string;
    @Input() isOpen: boolean = false;
    @Input() level: number;
    @Input() isDragged: boolean;
    @Input() draggableElementPredicate: (model: LinkedModel) => boolean;

    private expandChildrenDelayedFunc = new DelayedFunction({
        func: () => {
            this.isOpen = true;
        },
        timeoutMs: 500,
    });

    DropPredicate: () => boolean = () => {
        return this.dropPredicate(this.model);
    }

    getChildren(): LinkedModel[] {
        return this.model.children;
    }

    placeholderEnter() {
        if (this.isDndMode && !this.isOpen) {
            this.expandChildrenDelayedFunc.apply();
        }
    }

    placeholderOut() {
        this.expandChildrenDelayedFunc.cancel();
    }
}
