import { PlainElementLinkedModel } from './plain-element-linked-model';
import { CustomData } from '../types/custom-data';

export interface PlainElementIteratorParameters {
    items: CustomData[];
}

export class PlainElementIteratorLinkedControlModel {
    public readonly '@type': 'plain-element-iterator' = 'plain-element-iterator';
    public children: PlainElementLinkedModel[];
    public parameters: PlainElementIteratorParameters;

    constructor(params: {
        children: PlainElementLinkedModel[];
        items: CustomData[];
    }) {
        this.children = params.children;
        this.parameters = {
            items: params.items
        };
    }
}
