import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { CellLinkedModel } from '../../../../models/linked-models/cell-linked-model';
import { DisplayComponentEventBusService } from '../../shared/display-event-bus/display-component-event-bus.service';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../../../utils/angular-extensions';

@Component({
    selector: 'lib-cell',
    template: `
        <div class="cell-wrap">
            <td
                [align]="cellModel.parameters.align"
                [ngStyle]="{
                    verticalAlign: cellModel.parameters.vAlign,
                    paddingTop: (isResponsive ? cellModel.responsiveParameters.padding.top : cellModel.parameters.padding.top) + 'px',
                    paddingBottom: (isResponsive ? cellModel.responsiveParameters.padding.bottom : cellModel.parameters.padding.bottom) + 'px',
                    paddingLeft: (isResponsive ? cellModel.responsiveParameters.padding.left : cellModel.parameters.padding.left) + 'px',
                    paddingRight: (isResponsive ? cellModel.responsiveParameters.padding.right : cellModel.parameters.padding.right) + 'px',
                    textAlign: isResponsive ? '-webkit-' + cellModel.responsiveParameters.align : null
                }">
                <lib-plain-element
                    *ngFor="let plainElementModel of cellModel.children; index as position"
                    [model]="plainElementModel"
                    [position]="position"
                ></lib-plain-element>
            </td>
        </div>
    `,
    styles: [`
        .cell-wrap {
            display: table;
            width: 100%;
            height: 100%;
            border: 0;
            border-collapse: separate;
        }

        td {
            width: 100%;
            border: 0;
        }
    `],
    // TODO: optimization: strategy
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellComponent
    implements OnInit,
        CustomOnChanges,
        OnDestroy {
    @Input() cellModel: CellLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;

    constructor(
        private elementRef: ElementRef,
        public displayComponentEventService: DisplayComponentEventBusService) {

    }

    ngOnInit(): void {
        this.displayComponentEventService.create(
            this.cellModel,
            this.position,
            this.elementRef)
    }

    ngOnChanges(changes: CustomSimpleChanges<this>): void {
        if (changes.position) {
            this.displayComponentEventService.change(
                this.cellModel,
                this.position,
                this.elementRef);
        }
    }

    ngOnDestroy(): void {
        this.displayComponentEventService.destroy(this.cellModel);
    }
}
