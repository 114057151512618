import { ResponsiveBlockLinkedModel } from './responsive-block-linked-model';
import { ResponsiveBlockSetContainerLinkedModel } from './responsive-block-set-container-linked-model';
import { RichTextPlainElementLinkedModel } from './rich-text-plain-element-linked-model';
import { PlainElementLinkedModel } from './plain-element-linked-model';
import { CellLinkedModel } from './cell-linked-model';
import { HorizontalContainerLinkedModel } from './horizontal-container-linked-model';
import { ContainerLinkedModel } from './container-linked-model';
import { RowLinkedModel } from './row-linked-model';
import { DocumentLinkedModel } from './document-linked-model';
import { CellSourceModel } from '../source-models/cell-source-model';
import { ResponsiveBlockSourceModel } from '../source-models/responsive-block-source-model';
import { RowSourceModel } from '../source-models/row-source-model';
import { DocumentSourceModel } from '../source-models/document-source-model';
import { ContainerSourceModel } from '../source-models/container-source-model';
import { PlainElementSourceModel } from '../source-models/plain-element-source-model';
import { GroupPlainElementLinkedModel } from './group-plain-element-linked-model';
import { GroupPlainElementSourceModel } from '../source-models/group-plain-element-source-model';
import { PlainElementIteratorLinkedControlModel } from './plain-element-iterator-linked-control-model';
import { CustomData } from '../types/custom-data';
import { BlockImagePlainElementLinkedModel } from './block-image-plain-element-linked-model';
import { LinkImagePlainElementLinkedModel } from './link-image-plain-element-linked-model';
import { VerticalSpacerPlainElementLinkedModel } from './vertical-spacer-plain-element-linked-model';
import { ButtonPlainElementLinkedModel } from './button-plain-element-linked-model';

export class DocumentLinkedModelFactory {
    static build(from: DocumentSourceModel): DocumentLinkedModel {
        const documentLinkedModel = new DocumentLinkedModel({
            children: [],
            backgroundColor: from.documentStyle.backgroundColor,
            documentWidth: from.documentStyle.width,
            fontFamily: from.documentStyle.fontFamily,
            customData: from['@data']
        });
        documentLinkedModel.children = from.children
            .map((rowSourceModel) => DocumentLinkedModelFactory.buildRow(
                rowSourceModel,
            ));
        return documentLinkedModel;
    }

    private static buildRow(
        from: RowSourceModel,
    ): RowLinkedModel {
        const rowLinkedModel = new RowLinkedModel({
            children: [],
            bodyWidth: from.parameters.bodyWidth,
            innerWidth: from.parameters.innerWidth,
            background: { ...from.parameters.background },
            bodyBackground: { ...from.parameters.bodyBackground },
            innerPadding: { ...from.parameters.innerPadding },
            responsiveInnerPadding: { ...from.responsiveParameters.innerPadding },
        });
        rowLinkedModel.children = from.children
            .map((containerSourceModel) => DocumentLinkedModelFactory.buildContainer(
                containerSourceModel,
            ));

        return rowLinkedModel;
    }

    private static buildContainer(
        from: ContainerSourceModel,
    ): ContainerLinkedModel {
        switch (from['@type']) {
            case 'responsiveBlockSet':
                const responsiveBlockSetContainerLinkedModel = new ResponsiveBlockSetContainerLinkedModel({
                    children: [],
                    bodyWidth: from.parameters.bodyWidth,
                    background: { ...from.parameters.background },
                    padding: { ...from.parameters.padding },
                    columnsVerticalAlign: from.parameters.columnsVerticalAlign,
                    columnsHorizontalAlign: from.parameters.columnsHorizontalAlign,
                });
                responsiveBlockSetContainerLinkedModel.children = from.children
                    .map((responsiveBlockSourceModel) => DocumentLinkedModelFactory.buildResponsiveBlock(
                        responsiveBlockSourceModel,
                    ));

                return responsiveBlockSetContainerLinkedModel;
            case 'horizontalContainer':
                const horizontalContainerLinkedModel = new HorizontalContainerLinkedModel({
                    children: [],
                    border: { ...from.parameters.border },
                    background: { ...from.parameters.background },
                    isFullWidth: from.parameters.isFullWidth,
                    cellsHeight: from.parameters.cellsHeight,
                    borderRadius: { ...from.parameters.borderRadius },
                    align: from.parameters.align,
                });
                horizontalContainerLinkedModel.children = from.children
                    .map((cellSourceModel) => DocumentLinkedModelFactory.buildCell(
                        cellSourceModel,
                    ));
                return horizontalContainerLinkedModel;
        }
    }

    private static buildResponsiveBlock(
        from: ResponsiveBlockSourceModel,
    ): ResponsiveBlockLinkedModel {
        const responsiveBlockLinkedModel = new ResponsiveBlockLinkedModel({
            children: [],
            width: from.parameters.width,
            padding: { ...from.parameters.padding },
            responsiveWidthInPercents: from.responsiveParameters.widthInPercents,
        });
        responsiveBlockLinkedModel.children = from.children
            .map((containerSourceModel) => DocumentLinkedModelFactory.buildContainer(
                containerSourceModel,
            ));
        return responsiveBlockLinkedModel;
    }

    private static buildCell(
        from: CellSourceModel,
    ): CellLinkedModel {
        const cellLinkedModel = new CellLinkedModel({
            children: [],
            padding: { ...from.parameters.padding },
            width: from.parameters.width,
            align: from.parameters.align,
            vAlign: from.parameters.vAlign,
            responsiveAlign: from.responsiveParameters.align,
            responsivePadding: { ...from.responsiveParameters.padding },
        });
        cellLinkedModel.children = from.children
            .map((plainElementSourceModel) => DocumentLinkedModelFactory.buildPlainElement(
                plainElementSourceModel,
            ));

        return cellLinkedModel;
    }

    private static buildPlainElement(
        plainElementSourceModel: PlainElementSourceModel,
    ): PlainElementLinkedModel {
        switch (plainElementSourceModel['@type']) {
            case 'horizontalContainer':
            case 'responsiveBlockSet':
                return DocumentLinkedModelFactory.buildContainer(
                    plainElementSourceModel,
                );
            case 'group-plain-element':
                return DocumentLinkedModelFactory.buildGroupPlainElement(
                    plainElementSourceModel,
                );
            case 'blockImage':
                return new BlockImagePlainElementLinkedModel({
                    sourceUrl: plainElementSourceModel.parameters.sourceUrl,
                    alternativeText: plainElementSourceModel.parameters.alternativeText,
                    width: plainElementSourceModel.parameters.width,
                    height: plainElementSourceModel.parameters.height,
                    canGrow: plainElementSourceModel.parameters.canGrow,
                    canShrink: plainElementSourceModel.parameters.canShrink,
                    responsiveCanGrow: plainElementSourceModel.responsiveParameters.canGrow,
                    responsiveCanShrink: plainElementSourceModel.responsiveParameters.canShrink,
                });
            case 'linkImage':
                return new LinkImagePlainElementLinkedModel({
                    linkUrl: plainElementSourceModel.parameters.linkUrl,
                    sourceUrl: plainElementSourceModel.parameters.sourceUrl,
                    alternativeText: plainElementSourceModel.parameters.alternativeText,
                    width: plainElementSourceModel.parameters.width,
                    height: plainElementSourceModel.parameters.height,
                    canGrow: plainElementSourceModel.parameters.canGrow,
                    canShrink: plainElementSourceModel.parameters.canShrink,
                    responsiveCanGrow: plainElementSourceModel.responsiveParameters.canGrow,
                    responsiveCanShrink: plainElementSourceModel.responsiveParameters.canShrink,
                });
            case 'richText':
                return new RichTextPlainElementLinkedModel({
                    html: plainElementSourceModel.parameters.html,
                });
            case 'verticalSpacer':
                return new VerticalSpacerPlainElementLinkedModel({
                    size: plainElementSourceModel.parameters.size,
                });
            case 'button':
                return new ButtonPlainElementLinkedModel({
                    url: plainElementSourceModel.parameters.url,
                    text: plainElementSourceModel.parameters.text,
                    width: plainElementSourceModel.parameters.width,
                    height: plainElementSourceModel.parameters.height,
                    fontSize: plainElementSourceModel.parameters.fontSize,
                    fontWeight: plainElementSourceModel.parameters.fontWeight,
                    fontFamily: plainElementSourceModel.parameters.fontFamily,
                    color: plainElementSourceModel.parameters.color,
                    backgroundColor: plainElementSourceModel.parameters.backgroundColor,
                    borderWidth: plainElementSourceModel.parameters.borderWidth,
                    borderColor: plainElementSourceModel.parameters.borderColor,
                    borderRadius: plainElementSourceModel.parameters.borderRadius,
                    borderStyle: plainElementSourceModel.parameters.borderStyle,
                });
            case 'plain-element-iterator':
                const iteratorLinkedControlModel = new PlainElementIteratorLinkedControlModel({
                    children: [],
                    items: plainElementSourceModel.items as CustomData[],
                });
                iteratorLinkedControlModel.children = plainElementSourceModel.children
                    .map((childSourceModel) => {
                        return this.buildPlainElement(
                            childSourceModel as PlainElementSourceModel,
                        );
                    });
                return iteratorLinkedControlModel;
            default:
                throw new Error('parse error type: ' + plainElementSourceModel['@type']);
        }
    }

    private static buildGroupPlainElement(
        groupPlainElementSourceModel: GroupPlainElementSourceModel,
    ): GroupPlainElementLinkedModel {
        const groupPlainElementLinkedModel = new GroupPlainElementLinkedModel({
            children: [],
        });
        groupPlainElementLinkedModel.children = groupPlainElementSourceModel.children
            .map((plainElementSourceModel) => DocumentLinkedModelFactory
                .buildPlainElement(
                    plainElementSourceModel,
                ));
        return groupPlainElementLinkedModel;
    }
}

