import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { ColorModel } from './color.model';
import { InitialStateFormControl } from '../../../helpers/initial-state-form-control';
import { Subscription } from 'rxjs';
import { ColorValidatorFactory } from '../../../helpers/color-validator-factory';

@Component({
    selector: 'lib-color-editor',
    template: `
        <div class="color-editor">
            <label class="color-selector">
                <lib-color-preview
                    [color]="colorFormControl.value">
                    <input
                        class="color-selector__input"
                        type="color"
                        #colorSelectorInputElementRef
                        [formControl]="colorFormControl"
                        [value]="colorFormControl.value"
                        (change)="commit()">
                </lib-color-preview>
            </label>
            <input
                class="color-input"
                type="text"
                [formControl]="colorFormControl"
                [value]="colorFormControl.value"
                (blur)="commit()">
        </div>
        <div class="transparent-mode"
            *ngIf="transparentMode && !isTransparent(colorFormControl.value)">
            <label>
                <input
                    class="transparent-mode__checkbox"
                    name="transparentModeCheckbox"
                    type="checkbox"
                    [checked]="isTransparent(colorFormControl.value)"
                    (change)="setTransparent()">
                <span
                    class="transparent-mode__label">Прозрачный
                </span>
            </label>
        </div>
    `,
    styles: [`
        .color-editor {
            display: flex;
            height: 32px;
            border: 1px solid #cccccc;
            border-radius: 4px;
        }

        .color-input {
            width: 100%;
            padding: 0 8px;
            border: 0;
            border-left: 1px solid #cccccc;
            box-sizing: border-box;
            font-size: 0.875em;
            line-height: 1.35;
            color: #333333;
            border-radius: 0 4px 4px 0;
        }

        .color-selector {
            position: relative;
            flex-basis: 48px;
            flex-grow: 0;
            flex-shrink: 0;
            box-sizing: border-box;
            border-radius: 4px 0 0 4px;
            overflow: hidden;
        }

        .color-selector__input {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border: 0;
            padding: 0;
            margin: 0;
            visibility: hidden;
        }

        .transparent-mode {
            margin-top: 8px;

            font-size: 0.75em;
            line-height: 1.5;
        }

        .transparent-mode__checkbox {
            display: inline-block;
            margin: 0;
            vertical-align: middle;
        }

        .transparent-mode__label {
            display: inline-block;
            margin-left: 8px;
            vertical-align: middle;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorEditorComponent
    implements OnInit,
        OnChanges,
        OnDestroy {
    @Input() colorModel: ColorModel;
    @Input() transparentMode = true;
    @Output() colorModelChange = new EventEmitter<ColorModel>();
    @Output() commitChange = new EventEmitter<ColorModel>();
    colorFormControl: InitialStateFormControl<{ value: string }>;
    colorChangesSubscription: Subscription;

    ngOnInit(): void {
        this.colorFormControl = new InitialStateFormControl(
            { value: this.colorModel.toString() },
            [
                ColorValidatorFactory.build(),
            ]);
        this.colorChangesSubscription = this.colorFormControl
            .valueChanges
            .subscribe((color: string) => {
                if (this.colorFormControl.valid) {
                    this.colorModelChange.emit(new ColorModel(color));
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.colorModel.isFirstChange()) {
            this.colorFormControl
                .setValue(
                    this.colorModel.toString(),
                    { emitEvent: false });
        }
    }

    ngOnDestroy(): void {
        if (this.colorChangesSubscription) {
            this.colorChangesSubscription
                .unsubscribe();
        }
    }

    commit() {
        if (this.colorFormControl.invalid) {
            this.colorFormControl.resetStateToInitial();
            return;
        }

        this.commitChange
            .emit(
                new ColorModel(
                    this.colorFormControl.value));
    }

    setTransparent(): void {
        this.colorFormControl
            .setValue(ColorModel.TRANSPARENT_COLOR);
        this.commit();
    }

    isTransparent(color: string): boolean {
        return ColorModel.isTransparent(color);
    }
}
