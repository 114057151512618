import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import { ColorModel } from '../editors/primitive/color-editor/color.model';

export class ColorValidatorFactory {
    static build(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (ColorModel.isValidColor(control.value)) {
                return null;
            }

            return { colorValidator: { value: control.value } };
        }
    }
}
