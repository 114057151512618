import { NgModule } from '@angular/core';
import { PaddingGroupEditorComponent } from './editors/complex/padding-group-editor/padding-group-editor.component';
import { PixelEditorComponent } from './editors/primitive/pixel-editor/pixel-editor.component';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { WidthEditorComponent } from './editors/primitive/width-editor/width-editor.component';
import { AlignEditorComponent } from './editors/primitive/align-editor/align-editor.component';
import { VerticalAlignEditorComponent } from './editors/primitive/vertical-align-editor/vertical-align-editor.component';
import { UrlEditorComponent } from './editors/primitive/url-editor/url-editor.component';
import { HeightEditorComponent } from './editors/primitive/height-editor/height-editor.component';
import { ColorEditorComponent } from './editors/primitive/color-editor/color-editor.component';
import { CommonModule } from '@angular/common';
import { BorderEditorComponent } from './editors/complex/border-editor/border-editor.component';
import { BorderStyleGroupEditorComponent } from './editors/complex/border-style-group-editor/border-style-group-editor.component';
import { BackgroundImageEditorComponent } from './editors/complex/background-image-editor/background-image-editor.component';
import { BackgroundEditorComponent } from './editors/complex/background-editor/background-editor.component';
import { TextEditorComponent } from './editors/primitive/text-editor/text-editor.component';
import { FontWeightEditorComponent } from './editors/primitive/font-weight-editor/font-weight-editor.component';
import { FontFamilyEditorComponent } from './editors/primitive/font-family-editor/font-family-editor.component';
import { AutoHeightEditorComponent } from './editors/complex/auto-height-editor/auto-height-editor.component';
import { BackgroundPreviewComponent } from './helper-components/background-preview/background-preview.component';
import { ColorPreviewComponent } from './helper-components/color-preview/color-preview.component';

import { BorderRadiusGroupEditorComponent } from './editors/complex/border-radius-group-editor/border-radius-group-editor.component';
import { SwitcherEditorComponent } from './editors/primitive/switcher-editor/switcher-editor.component';

@NgModule({
    declarations: [
        PaddingGroupEditorComponent,
        PixelEditorComponent,
        WidthEditorComponent,
        AlignEditorComponent,
        VerticalAlignEditorComponent,
        UrlEditorComponent,
        HeightEditorComponent,
        ColorEditorComponent,
        BorderEditorComponent,
        BorderStyleGroupEditorComponent,
        BackgroundImageEditorComponent,
        BackgroundEditorComponent,
        TextEditorComponent,
        FontWeightEditorComponent,
        FontFamilyEditorComponent,
        AutoHeightEditorComponent,
        BackgroundPreviewComponent,
        ColorPreviewComponent,
        BorderRadiusGroupEditorComponent,
        SwitcherEditorComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
    ],
    exports: [
        PaddingGroupEditorComponent,
        WidthEditorComponent,
        AlignEditorComponent,
        VerticalAlignEditorComponent,
        TextEditorComponent,
        ColorEditorComponent,
        PixelEditorComponent,
        FontWeightEditorComponent,
        FontFamilyEditorComponent,
        HeightEditorComponent,
        UrlEditorComponent,
        BackgroundEditorComponent,
        AutoHeightEditorComponent,
        BorderStyleGroupEditorComponent,
        BorderRadiusGroupEditorComponent,
        SwitcherEditorComponent,
    ],
})
export class LibEditorModule {
}
