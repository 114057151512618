import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { FontWeightModel } from './font-weight.model';

@Component({
    selector: 'lib-font-weight-editor',
    template: `
        <select [ngModel]="fontWeight"
            (ngModelChange)="fontWeight = $event; fontWeightChange.emit(fontWeight)"
            (blur)="commitChange.emit(fontWeight)">
            <option [ngValue]="400">normal</option>
            <option [ngValue]="700">bold</option>
        </select>
    `,
})
export class FontWeightEditorComponent {
    @Input() fontWeight: FontWeightModel;
    @Output() fontWeightChange = new EventEmitter<FontWeightModel>();
    @Output() commitChange = new EventEmitter<FontWeightModel>();
}
