import { Url } from '../types/Url';
import { Width } from '../types/Width';
import { Height } from '../types/Height';

export interface BlockImagePlainElementParameters {
    sourceUrl: Url;
    alternativeText: string;
    width: Width;
    height: Height;
    canShrink: boolean;
    canGrow: boolean;
}

export interface BlockImagePlainElementResponsiveParameters {
    canShrink: boolean;
    canGrow: boolean;
}

export class BlockImagePlainElementLinkedModel {
    public readonly '@type': 'blockImage' = 'blockImage';
    public parameters: BlockImagePlainElementParameters;
    public responsiveParameters: BlockImagePlainElementResponsiveParameters;

    constructor(params: {
        sourceUrl: Url;
        alternativeText: string;
        width: Width;
        height: Height;
        canShrink: boolean;
        canGrow: boolean;
        responsiveCanShrink: boolean;
        responsiveCanGrow: boolean;
    }) {
        this.parameters = {
            sourceUrl: params.sourceUrl,
            alternativeText: params.alternativeText,
            width: params.width,
            height: params.height,
            canShrink: params.canShrink,
            canGrow: params.canGrow,
        };
        this.responsiveParameters = {
            canShrink: params.responsiveCanShrink,
            canGrow: params.responsiveCanGrow,
        };
    }
}
