import { ActionTypes } from './actions';

export class RemoveElementAction<TElement> {
    public readonly type = ActionTypes.RemoveElement;

    constructor(
        public readonly containerList: TElement[],
        public readonly targetModel: TElement,
        public readonly position: number,
    ) {
    }
}
