import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { VerticalSpacerPlainElementLinkedModel } from '../../../../../models/linked-models/vertical-spacer-plain-element-linked-model';
import { DisplayComponentEventBusService } from '../../../shared/display-event-bus/display-component-event-bus.service';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../../../../utils/angular-extensions';

@Component({
    template: `
        <div
            class="vSpacer"
            [ngStyle]="{
                height: model.parameters.size + 'px'
            }"></div>
    `,
    styles: [`
        :host {
            display: block;
        }

        .vSpacer {
            font-size: 0;
            line-height: 0;
        }
    `],
})
export class VerticalSpacerPlainElementComponent
    implements OnInit,
        CustomOnChanges,
        OnDestroy {
    @Input() model: VerticalSpacerPlainElementLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;

    constructor(
        private elementRef: ElementRef,
        public displayComponentEventService: DisplayComponentEventBusService) {

    }

    ngOnInit(): void {
        this.displayComponentEventService.create(
            this.model,
            this.position,
            this.elementRef)
    }

    ngOnChanges(changes: CustomSimpleChanges<this>): void {
        if (changes.position) {
            this.displayComponentEventService.change(
                this.model,
                this.position,
                this.elementRef);
        }
    }

    ngOnDestroy(): void {
        this.displayComponentEventService.destroy(this.model);
    }
}
