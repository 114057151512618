import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../../../../utils/angular-extensions';
import { DisplayComponentEventBusService } from '../../../shared/display-event-bus/display-component-event-bus.service';
import { BlockImagePlainElementLinkedModel } from '../../../../../models/linked-models/block-image-plain-element-linked-model';

@Component({
    template: `
        <img
            #imageElementRef
            [src]="model.parameters.sourceUrl"
            [alt]="model.parameters.alternativeText"
            [width]="model.parameters.width"
            [height]="model.parameters.height"

            [ngStyle]="{
                width: isResponsive
                 ? getWidth(model.parameters.canGrow, model.parameters.canShrink)
                 : getWidth(model.responsiveParameters.canGrow, model.responsiveParameters.canShrink),
                height: isResponsive
                 ? getHeight(model.parameters.canGrow, model.parameters.canShrink)
                 : getHeight(model.responsiveParameters.canGrow, model.responsiveParameters.canShrink),
                maxWidth: isResponsive
                 ? getMaxWidth(model.parameters.canGrow)
                 : getMaxWidth(model.responsiveParameters.canGrow),
                minWidth: isResponsive
                 ? getMaxWidth(model.parameters.canShrink)
                 : getMaxWidth(model.responsiveParameters.canShrink)
            }"

            draggable="false">
    `,
    styles: [`
        img {
            display: block;
        }
    `],
})
export class BlockImagePlainElementComponent
    implements OnInit,
        AfterViewInit,
        CustomOnChanges,
        OnDestroy {
    @Input() model: BlockImagePlainElementLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;

    @ViewChild('imageElementRef')
    private imageElementRef: ElementRef<HTMLImageElement>;

    constructor(
        private elementRef: ElementRef,
        public displayComponentEventService: DisplayComponentEventBusService) {

    }

    getWidth(canGrow: boolean, canShrink: boolean): string {
        return canGrow || canShrink
            ? '100%' : this.model.parameters.width + 'px';
    }

    getHeight(canGrow: boolean, canShrink: boolean): string {
        return canGrow || canShrink
            ? 'auto' : this.model.parameters.height + 'px';
    }

    getMaxWidth(canGrow: boolean): string {
        return canGrow ? '100%' : this.model.parameters.width + 'px';
    }

    getMinWidth(canShrink: boolean): string {
        return canShrink ? '0' : this.model.parameters.width + 'px';
    }

    ngOnInit(): void {
        this.displayComponentEventService.create(
            this.model,
            this.position,
            this.elementRef,
        );
    }

    ngAfterViewInit(): void {
        this.displayComponentEventService.change(
            this.model,
            this.position,
            this.imageElementRef);
    }

    ngOnChanges(changes: CustomSimpleChanges<this>): void {
        if (changes.position && this.imageElementRef) {
            this.displayComponentEventService.change(
                this.model,
                this.position,
                this.imageElementRef);
        }
    }

    ngOnDestroy(): void {
        this.displayComponentEventService.destroy(this.model);
    }
}
