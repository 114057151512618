import {
    Directive,
    EventEmitter,
    HostListener,
    Output,
    Input
} from '@angular/core';

@Directive({
    selector: '[libDrop]',
})
export class DropDirective {
    @Input() libDropPredicate: () => boolean = () => true;
    @Output() libDrop = new EventEmitter();
    @Output() libDropAvailabilityStateChange = new EventEmitter<boolean>();

    dropAvailabilityState: boolean = false;

    private mouseMoveRef = this.mousemove.bind(this);

    private setDropAvailabilityState(state: boolean)
    {
        if (this.dropAvailabilityState != state)
        {
            this.dropAvailabilityState = state;
            this.libDropAvailabilityStateChange
                .emit(state);
        }
    }

    @HostListener('mouseenter')
    mouseenter() {
        document.addEventListener('mousemove', this.mouseMoveRef);
    }

    @HostListener('mouseleave')
    mouseleave() {
        this.setDropAvailabilityState(false);
        document.removeEventListener('mousemove', this.mouseMoveRef);
    }

    @HostListener('mouseup')
    mouseup() {
        if (this.libDropPredicate())
        {
           this.libDrop.emit();
        }

        this.setDropAvailabilityState(false);
    }

    mousemove() {
        this.setDropAvailabilityState(
            this.libDropPredicate());
    }
}
