import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'lib-background-preview',
    template: `
        <div class="preview">
            <lib-color-preview [color]="color">
                <div class="preview__image"
                    *ngIf="imageUrl"
                    [ngStyle]="{
                        backgroundImage: 'url(' + imageUrl + ')',
                        backgroundPosition: imagePosition,
                        backgroundRepeat: imageRepeat
                    }">
                    <ng-content></ng-content>
                </div>
            </lib-color-preview>
        </div>
    `,
    styles: [`
        :host {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            padding: 5px;
            border: 1px solid #dddddd;
            box-sizing: border-box;
            border-radius: 4px;

            background-color: #ffffff;
        }

        .preview {
            width: 100%;
        }

        .preview__image {
            position: relative;
            width: 100%;
            height: 100%;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundPreviewComponent
    implements OnChanges {
    @Input() color: string;
    @Input() imageUrl?: string;
    @Input() imagePosition?: string;
    @Input() imageRepeat?: string;

    ngOnChanges(changes: SimpleChanges): void {
    }
}
