import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    HostListener,
    Input
} from '@angular/core';
import { DragImageModel } from './drag-image.model';

@Component({
    selector: 'lib-drag-image',
    template: `
        <i class="fas fa-{{model.icon}}"></i>
        {{model.title}}
    `,
    styles: [`
        :host {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            pointer-events: none;

            padding: 3px 5px;

            background-color: #16aadb;
            color: #ffffff;
            font-size: 12px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragImageComponent {
    @Input() model: DragImageModel;
    @HostBinding('style.top')
    positionY = '0';
    @HostBinding('style.left')
    positionX = '0';

    private static CURSOR_SIZE = 12;


    @HostListener('document:mousemove', ['$event'])
    mouseMove($event: MouseEvent) {
        this.positionY = `${$event.clientY + DragImageComponent.CURSOR_SIZE}px`;
        this.positionX = `${$event.clientX + DragImageComponent.CURSOR_SIZE}px`;
    }
}
