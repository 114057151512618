import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'lib-title',
    template: `
        <button
            class="button"
            [ngClass]="{
                'button--selected': isSelected,
                'button--dragged': isDragged,
                'button--dropMode': isDropMode,
                'button--dropAvailable': isDropInsideAvailable
            }"
            (click)="click.emit($event)"
            [ngStyle]="{
                paddingLeft: level * 4 + 'px'
            }">
            <button class="button__expandable-state fas fa-angle-down"
                (click)="toggle($event)"
                [ngClass]="{
                    'button__expandable-state--visible': isExpandable,
                    'button__expandable-state--open': isOpen
                }"></button>
            <i class="button__icon fas fa-{{icon}}"></i>
            {{label}}
        </button>
    `,
    styles: [`
        .button {
            display: block;
            width: 100%;
            height: 20px;

            text-align: left;
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;

            color: #333333;
            background-color: transparent;
            border: none;
        }

        .button__expandable-state {
            display: inline-block;
            width: 14px;
            height: 14px;
            border: none;
            margin-right: 4px;
            padding: 0;

            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #333333;
            background-color: transparent;
            visibility: hidden;

            transform: rotate(-90deg);
        }

        .button__expandable-state--visible {
            visibility: visible;
        }

        .button__expandable-state--open {
            transform: none;
        }

        .button__icon {
            color: #777777;
        }

        .button:hover {
            outline: 1px solid rgba(1, 40, 77, 0.5);
        }

        .button--dropMode:hover {
            cursor: not-allowed;
            outline: 1px solid #dc3545;
            background-color: rgba(220, 53, 69, 0.2);
        }

        .button--dropAvailable:hover {
            cursor: default;
            outline: 1px solid #16aadb;
            background-color: rgba(22, 170, 219, 0.2);
        }

        .button--selected {
            background-color: rgba(60, 74, 89, 0.2);
        }

        .button--dragged {
            opacity: 0.5;
        }
    `],
})
export class TitleComponent {
    @Input() icon: string;
    @Input() label: string;
    @Input() level: number;
    @Input() isSelected: boolean;
    @Input() isDropMode: boolean;
    @Input() isDropInsideAvailable: boolean;
    @Input() isOpen: boolean;
    @Input() isExpandable: boolean;
    @Input() isDragged: boolean;
    @Output() click = new EventEmitter<MouseEvent>();
    @Output() isOpenChange = new EventEmitter<boolean>();

    toggle($event: MouseEvent) {
        $event.stopPropagation();

        this.isOpenChange.emit(!this.isOpen);
    }
}
