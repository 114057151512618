type Height = 'auto' | number;

export class HeightModel {
    public static AUTO: 'auto' = 'auto';
    public readonly isAuto: boolean = false;
    private readonly height: Height;

    constructor(height: Height) {
        if (height === HeightModel.AUTO) {
            this.isAuto = true;
            this.height = height;
            return;
        }

        this.isAuto = false;
        this.height = height;
    }

    getValue(): Height {
        return this.height;
    }

    numberValueOrThrow(): number {
        if (this.height === HeightModel.AUTO) {
            throw new Error('Height is not number');
        }

        return this.height;
    }
}

