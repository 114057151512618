import {
    Component,
    Input
} from '@angular/core';
import { PlainElementIteratorLinkedControlModel } from '../../../../../models/linked-models/plain-element-iterator-linked-control-model';

@Component({
    template: `
        <lib-plain-element
            *ngFor="let customData of model.parameters.items"
            [model]="model.children[0]"
            [position]="0"
            [isResponsive]="isResponsive"></lib-plain-element>
    `,
})
export class PlainElementIteratorComponent {
    @Input() model: PlainElementIteratorLinkedControlModel;
    @Input() position: number;
    @Input() isResponsive: boolean;
}
