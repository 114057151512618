import { PlainElementLinkedModel } from './plain-element-linked-model';
import { Width } from '../types/Width';
import { HorizontalAlign } from '../types/HorizontalAlign';
import { VerticalAlign } from '../types/VerticalAlign';
import { PaddingGroup } from '../types/PaddingGroup';

export interface CellParameters {
    width: Width;
    align: HorizontalAlign;
    vAlign: VerticalAlign;
    padding: PaddingGroup;
}

export interface CellResponsiveParameters {
    align: HorizontalAlign;
    padding: PaddingGroup;
}

export class CellLinkedModel {
    public readonly '@type': 'cell' = 'cell';
    public children: PlainElementLinkedModel[];
    public parameters: CellParameters;
    public responsiveParameters: CellResponsiveParameters;

    constructor(params: {
        children: PlainElementLinkedModel[];
        padding: PaddingGroup;
        width: Width;
        align: HorizontalAlign;
        vAlign: VerticalAlign;
        responsiveAlign: HorizontalAlign;
        responsivePadding: PaddingGroup;
    }) {
        this.children = params.children;
        this.parameters = {
            width: params.width,
            align: params.align,
            vAlign: params.vAlign,
            padding: params.padding,
        };
        this.responsiveParameters = {
            align: params.responsiveAlign,
            padding: params.responsivePadding,
        };
    }
}
