import { ResponsiveBlockLinkedModel } from './responsive-block-linked-model';
import { PaddingGroup } from '../types/PaddingGroup';
import { Width } from '../types/Width';
import { VerticalAlign } from '../types/VerticalAlign';
import { Background } from '../types/Background';
import { HorizontalAlign } from '../types/HorizontalAlign';

export interface ResponsiveBlockSetParameters {
    bodyWidth: Width;
    columnsHorizontalAlign: HorizontalAlign;
    columnsVerticalAlign: VerticalAlign;
    background: Background;
    padding: PaddingGroup;
}

export class ResponsiveBlockSetContainerLinkedModel {
    public readonly '@type': 'responsiveBlockSet' = 'responsiveBlockSet';
    public children: ResponsiveBlockLinkedModel[];
    public parameters: ResponsiveBlockSetParameters;

    constructor(params: {
        children: ResponsiveBlockLinkedModel[],
        bodyWidth: Width,
        background: Background,
        padding: PaddingGroup,
        columnsHorizontalAlign: HorizontalAlign,
        columnsVerticalAlign: VerticalAlign,
    }) {
        this.children = params.children;
        this.parameters = {
            bodyWidth: params.bodyWidth,
            background: params.background,
            padding: params.padding,
            columnsHorizontalAlign: params.columnsHorizontalAlign,
            columnsVerticalAlign: params.columnsVerticalAlign,
        };
    }
}
