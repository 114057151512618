import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output
} from '@angular/core';

interface MousePoint {
    x: number;
    y: number;
}

class DraggableDirectiveHelper {
    private static readonly OFFSET_SIZE_IN_PIXELS = 3;

    public static isBeyondOffset(startPosition: MousePoint, currentPosition: MousePoint): boolean {
        return Math.abs(startPosition.x - currentPosition.x) > this.OFFSET_SIZE_IN_PIXELS
            || Math.abs(startPosition.y - currentPosition.y) > this.OFFSET_SIZE_IN_PIXELS;
    }
}

@Directive({
    selector: '[libDraggable]',
})
export class DraggableDirective {
    @Input() libDraggableDisabled: boolean;
    @Output() libDraggableDragStart = new EventEmitter();
    private startPosition: MousePoint | null = null;
    private mouseMoveRef = this.mousemove.bind(this);
    private mouseUpRef = this.mouseUp.bind(this);

    mousemove($event: MouseEvent) {
        if (
            this.startPosition !== null
            && !this.libDraggableDisabled
            && DraggableDirectiveHelper.isBeyondOffset(
            this.startPosition,
            {
                x: $event.x,
                y: $event.y
            })
        ) {
            this.libDraggableDragStart.emit();
            document.removeEventListener('mousemove', this.mouseMoveRef);
        }
    }

    @HostListener('mousedown', ['$event'])
    mouseDown($event: MouseEvent) {
        if (!this.libDraggableDisabled) {
            $event.stopPropagation();
            this.startPosition = {
                x: $event.x,
                y: $event.y,
            };
            document.addEventListener('mousemove', this.mouseMoveRef);
            document.addEventListener('mouseup', this.mouseUpRef);
        }
    }

    mouseUp() {
        this.startPosition = null;
        document.removeEventListener('mouseup', this.mouseUpRef);
    }
}
