import {
    ValidatorFn,
    Validators
} from '@angular/forms';

export class UrlValidatorFactory {
    private static readonly urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

    static build(): ValidatorFn {
        return Validators.pattern(UrlValidatorFactory.urlPattern);
    }
}
