import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { HeightModel } from '../../primitive/height-editor/height.model';
import { AutoHeightModelFactory } from '../../primitive/height-editor/autoHeightModelFactory';

@Component({
    selector: 'lib-auto-height-editor',
    template: `
        <input
            id="autoHeightCheckbox"
            type="checkbox"
            [checked]="heightModel.isAuto"
            (change)="toggleHeightAuto()"
            title="Auto">
        <label
            for="autoHeightCheckbox">Auto
        </label>
        <lib-height-editor
            *ngIf="!heightModel.isAuto"
            [height]="heightModel"
            (heightChange)="changeHeight($event)"
            (commitChange)="commitChange.emit(heightModel)"></lib-height-editor>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoHeightEditorComponent {
    @Input() heightModel: HeightModel;
    @Input() defaultHeightModel: HeightModel = new HeightModel(0);
    @Output() heightModelChange = new EventEmitter<HeightModel>();
    @Output() commitChange = new EventEmitter<HeightModel>();

    changeHeight(height: HeightModel) {
        this.heightModel = height;
        this.heightModelChange.emit(this.heightModel);
    }

    changeToAuto() {
        this.changeHeight(AutoHeightModelFactory.build());
    }

    toggleHeightAuto() {
        if (this.heightModel.isAuto) {
            this.changeHeight(this.defaultHeightModel);
        } else {
            this.changeToAuto();
        }
        this.commitChange.emit(this.heightModel);
    }
}
