import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { BreadcrumbModel } from '../contracts/models/breadcrumb.model';

@Component({
    selector: 'lib-breadcrumbs',
    template: `
        <ng-template
            ngFor
            let-breadcrumb
            let-isLast="last"
            [ngForOf]="breadcrumbPath">
            <lib-breadcrumb-node
                *ngIf="!isLast"
                (select)="select.emit(breadcrumb)"
                [model]="breadcrumb"></lib-breadcrumb-node>
            <lib-breadcrumb-leaf
                *ngIf="isLast"
                [model]="breadcrumb"></lib-breadcrumb-leaf>
        </ng-template>
    `,
    styles: [`
        :host {
            display: flex;
            align-items: center;
            height: 32px;
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 0;
            padding-bottom: 0;
            box-sizing: border-box;

            background-color: #fafafa;
        }
    `],
})
export class LibBreadcrumbsComponent
    implements OnInit {
    @Input() breadcrumbPath: BreadcrumbModel[];
    @Output() select = new EventEmitter<BreadcrumbModel>();

    constructor() {
    }

    ngOnInit(): void {
    }

}
