import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { EditorModel } from '../../adapters/editor-adapter/editor-adapter.component';

@Component({
    selector: 'lib-options',
    template: `
        <h2 class="options-title">Настройки</h2>
        <lib-editor
            [model]="model"
            (modelChange)="modelChange.emit($event)"></lib-editor>
    `,
    styles: [`
        :host {
            color: #333333;
        }

        .options-title {
            margin: 0;
            padding: 7px 10px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsComponent {
    @Input() model: EditorModel;
    @Output() modelChange = new EventEmitter<EditorModel>();
}
