import { ColorModel } from '../../primitive/color-editor/color.model';
import { BackgroundImageModel } from '../background-image-editor/background-image.model';

export class BackgroundModel {
    constructor(
        public color: ColorModel,
        public image: BackgroundImageModel,
    ) {
    }
}
