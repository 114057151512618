export interface RichTextPlainElementParameters {
    html: string;
}

export class RichTextPlainElementLinkedModel {
    public readonly '@type': 'richText' = 'richText';
    public parameters: RichTextPlainElementParameters;

    constructor(params: {
        html: string;
    }) {
        this.parameters = {
            html: params.html,
        };
    }
}
