import { ActionTypes } from './actions';

export class AddElementAction<TElement> {
    public readonly type = ActionTypes.AddElement;

    constructor(
        public readonly containerList: TElement[],
        public readonly targetModel: TElement,
        public readonly position: number,
    ) {
    }
}
