import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { PlainElementIteratorParameters } from '../../../../models/linked-models/plain-element-iterator-linked-control-model';

@Component({
    template: `
        <ul>
            <li *ngFor="let item of parameters.items; index as index">
                <lib-text-editor
                    [text]="item | json"
                    (commitChange)="changeItem(index, $event)"></lib-text-editor>
                <button (click)="deleteItem(index)">&times;</button>
            </li>
        </ul>
        <button (click)="addItem()">Add item</button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlainElementIteratorComponent {
    @Input() parameters: PlainElementIteratorParameters;
    @Output() parametersChanges = new EventEmitter<PlainElementIteratorParameters>();

    commitChange(changes: Partial<PlainElementIteratorParameters>) {
        this.parameters = { ...this.parameters, ...changes };
        this.parametersChanges.emit(this.parameters);
    }

    addItem() {
        this.commitChange({
            items: [
                ...this.parameters.items,
                {},
            ],
        })
    }

    changeItem(index: number, newValue: string) {
        const normalizedNewValue = JSON.parse(newValue);
        this.commitChange({
            items: this.parameters
                .items
                .map((item, itemIndex) => {
                    if (itemIndex !== index) {
                        return item;
                    }

                    return normalizedNewValue;
                })
        })
    }

    deleteItem(index: number) {
        this.commitChange({
            items: this.parameters
                .items
                .filter((item, itemIndex) => itemIndex !== index),
        })
    }
}
