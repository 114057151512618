import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { ResponsiveBlockSetParameters } from '../../../../models/linked-models/responsive-block-set-container-linked-model';

@Component({
    template: `
        <lib-background-group
            [background]="parameters.background"
            (commitChange)="commitChange({ background: $event })"></lib-background-group>

        <lib-expandable-group
            titleText="Выравнивание блоков">
            <fieldset>
                <legend>По горизонтали</legend>
                <lib-align-editor
                    [align]="parameters.columnsHorizontalAlign"
                    (commitChange)="commitChange({ columnsHorizontalAlign: $event })"></lib-align-editor>
            </fieldset>
            <fieldset>
                <legend>По вертикали</legend>
                <lib-vertical-align-editor
                    [vAlign]="parameters.columnsVerticalAlign"
                    (commitChange)="commitChange({ columnsVerticalAlign: $event })"></lib-vertical-align-editor>
            </fieldset>
        </lib-expandable-group>

        <lib-expandable-group
            titleText="Responsive block set">
            Padding:
            <lib-padding-group-editor
                [paddingGroupModel]="{
                    top: parameters.padding.top,
                    bottom: parameters.padding.bottom,
                    left: parameters.padding.left,
                    right: parameters.padding.right
                }"
                (commitChange)="commitChange({
                    padding: {
                        top: $event.top,
                        bottom: $event.bottom,
                        left: $event.left,
                        right: $event.right
                    }
                })"></lib-padding-group-editor>
        </lib-expandable-group>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveBlockSetComponent {
    @Input() parameters: ResponsiveBlockSetParameters;
    @Output() parametersChanges = new EventEmitter<ResponsiveBlockSetParameters>();

    commitChange(changes: Partial<ResponsiveBlockSetParameters>) {
        this.parameters = { ...this.parameters, ...changes };
        this.parametersChanges.emit(this.parameters);
    }
}
