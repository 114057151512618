<div
    class="title"
    [ngClass]="{
        isDndMode: isDndMode
    }">
    <lib-title
        [level]="level"
        [isSelected]="selectedElement === model"
        [isDropMode]="isDndMode"
        [isDropInsideAvailable]="false"
        [isDragged]="isDragged"

        libDraggable
        (libDraggableDragStart)="dragStart.emit({
            model: model,
            fromPosition: position
        })"
        (click)="select.emit(model)"

        [icon]="icon"
        [label]="title"></lib-title>
</div>
