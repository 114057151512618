import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { BorderModel } from './border.model';

@Component({
    selector: 'lib-border-editor',
    template: `
        <div>
            Width:
            <lib-pixel-editor
                [pixels]="borderModel.width"
                (pixelsChange)="patchValue({
                    width: $event
                })"
                (commitChange)="commitChange.emit(borderModel)"></lib-pixel-editor>
            px
        </div>
        <div>
            Style:
            <select [ngModel]="borderModel.style"
                (ngModelChange)="patchValue({
                    style: $event
                })"
                (blur)="commitChange.emit(borderModel)">
                <option [ngValue]="'none'">none</option>
                <option [ngValue]="'solid'">solid</option>
                <option [ngValue]="'dashed'">dashed</option>
                <option [ngValue]="'dotted'">dotted</option>
                <option [ngValue]="'double'">double</option>
            </select>
        </div>
        <div>
            Color:
            <lib-color-editor
                [colorModel]="borderModel.color"
                (colorModelChange)="patchValue({
                    color: $event
                })"
                (commitChange)="commitChange.emit(borderModel)"></lib-color-editor>
        </div>
    `,
})
export class BorderEditorComponent {
    @Input() borderModel: BorderModel;
    @Output() borderModelChange = new EventEmitter<BorderModel>();
    @Output() commitChange = new EventEmitter<BorderModel>();

    patchValue(changes: Partial<BorderModel>): void {
        this.borderModel = { ...this.borderModel, ...changes };
        this.borderModelChange.emit(this.borderModel);
    }
}
