import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentComponent } from './component/document/document.component';
import { DisplayComponent } from './display.component';
import { RowComponent } from './component/row/row.component';
import { ContainerComponent } from './component/container/container.component';
import { ResponsiveBlockSetContainerComponent } from './component/container/responsive-block-set-container/responsive-block-set-container.component';
import { HorizontalContainerComponent } from './component/container/horizontal-container/horizontal-container.component';
import { CellComponent } from './component/cell/cell.component';
import { ResponsiveBlockComponent } from './component/responsive-block/responsive-block.component';
import { PlainElementComponent } from './component/plain-element/plain-element.component';
import { RichTextPlainElementComponent } from './component/plain-element/rich-text-plain-element/rich-text-plain-element.component';
import { PlainElementIteratorComponent } from './component/plain-element/plain-element-iterator/plain-element-iterator.component';
import { GroupPlainElementComponent } from './component/plain-element/group-plain-element/group-plain-element.component';
import { SafeHtmlPipe } from './component/plain-element/rich-text-plain-element/safe-html.pipe';
import { DndModule } from '../../../../../lib-dnd/src/public-api';
import { DisplayComponentEventBusService } from './shared/display-event-bus/display-component-event-bus.service';
import { OverlayComponent } from './component/overlay/overlay.component';
import { BlockImagePlainElementComponent } from './component/plain-element/block-image-plain-element/block-image-plain-element.component';
import { ButtonPlainElementComponent } from './component/plain-element/button-plain-element/button-plain-element.component';
import { VerticalSpacerPlainElementComponent } from './component/plain-element/vertical-spacer-plain-element/vertical-spacer-plain-element.component';
import { LinkImagePlainElementComponent } from './component/plain-element/link-image-plain-element/link-image-plain-element.component';

@NgModule({
    declarations: [
        DisplayComponent,
        DocumentComponent,
        RowComponent,
        ContainerComponent,
        HorizontalContainerComponent,
        ResponsiveBlockSetContainerComponent,
        CellComponent,
        ResponsiveBlockComponent,
        PlainElementComponent,
        RichTextPlainElementComponent,
        PlainElementIteratorComponent,
        GroupPlainElementComponent,
        SafeHtmlPipe,
        OverlayComponent,
        BlockImagePlainElementComponent,
        ButtonPlainElementComponent,
        LinkImagePlainElementComponent,
        VerticalSpacerPlainElementComponent,
    ],
    imports: [
        CommonModule,
        DndModule,
    ],
    providers: [
        DisplayComponentEventBusService,
    ],
    exports: [
        DisplayComponent,
    ],
})
export class DisplayModule {
}
