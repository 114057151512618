import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { ITreeNodeDynamicComponent } from '../i-tree-node-component';
import { LinkedModel } from 'projects/demo/src/app/models/linked-models/linked-model';
import { DragStartEvent } from '../../drag-start-event';
import { PlainElementLinkedModel } from 'projects/demo/src/app/models/linked-models/plain-element-linked-model';

@Component({
    templateUrl: './tree-leaf-node.component.html',
})
export class TreeLeafNodeComponent
    implements ITreeNodeDynamicComponent {
    readonly '@type': 'treeLeafNodeComponent' = 'treeLeafNodeComponent';

    @Input() selectedElement: LinkedModel;
    @Input() isDndMode: boolean;
    @Output() select: EventEmitter<LinkedModel> = new EventEmitter<LinkedModel>();
    @Output() dragStart: EventEmitter<DragStartEvent> = new EventEmitter<DragStartEvent>();

    @Input() position: number;

    @Input() title: string;
    @Input() icon: string;
    @Input() level: number;
    @Input() isDragged: boolean;

    model: PlainElementLinkedModel;
}
