import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'lib-text-editor',
    template: `
        <input type="text"
            [ngModel]="text"
            (ngModelChange)="text = $event; textChange.emit(text)"
            (blur)="commitChange.emit(text)">
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditorComponent {
    @Input() text: string;
    @Output() textChange = new EventEmitter<string>();
    @Output() commitChange = new EventEmitter<string>();
}
