import { Url } from '../types/Url';
import { Width } from '../types/Width';
import { Height } from '../types/Height';
import { Pixels } from '../types/Pixels';
import { FontWeight } from '../types/FontWeight';
import { FontFamily } from '../types/FontFamily';
import { Color } from '../types/Color';
import {
    Border,
    BorderStyle
} from '../types/BorderGroup';

export interface ButtonPlainElementParameters {
    url: Url;
    text: string;
    width: Width;
    height: Height;
    fontSize: Pixels;
    fontWeight: FontWeight;
    fontFamily: FontFamily;
    color: Color;
    backgroundColor: Color;
    border: Border;
    borderRadius: Pixels;
}

export class ButtonPlainElementLinkedModel {
    public readonly '@type': 'button' = 'button';
    public parameters: ButtonPlainElementParameters;

    constructor(params: {
        url: Url;
        text: string;
        width: Width;
        height: Height;
        fontSize: Pixels;
        fontWeight: FontWeight;
        fontFamily: FontFamily;
        color: Color;
        backgroundColor: Color;
        borderWidth: Pixels;
        borderColor: Color;
        borderRadius: Pixels;
        borderStyle: BorderStyle;
    }) {
        this.parameters = {
            url: params.url,
            text: params.text,
            width: params.width,
            height: params.height,
            fontSize: params.fontSize,
            fontWeight: params.fontWeight,
            fontFamily: params.fontFamily,
            color: params.color,
            backgroundColor: params.backgroundColor,
            border: {
                width: params.borderWidth,
                style: params.borderStyle,
                color: params.borderColor,
            },
            borderRadius: params.borderRadius,
        };
    }
}
