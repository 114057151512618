export type BackgroundPosition = 'left top'
    | 'left center'
    | 'left bottom'
    | 'right top'
    | 'right center'
    | 'right bottom'
    | 'center top'
    | 'center center'
    | 'center bottom';

export type BackgroundRepeat = 'repeat'
    | 'repeat-x'
    | 'repeat-y'
    | 'no-repeat';

export class BackgroundImageModel {
    constructor(
        public sourceUrl: string,
        public position: BackgroundPosition,
        public repeat: BackgroundRepeat,
    ) {
    }
}
