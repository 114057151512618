import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { BorderRadiusGroupModel } from './border-radius-group.model';

type BorderRadiusCorner = 'all' | 'topLeft' | 'topRight' | 'bottomRight' | 'bottomLeft';

@Component({
    selector: 'lib-border-radius-group-editor',
    template: `
        <div class="padding-group">
            <div class="padding-group__main">
                <div class="icon">

                    <svg
                        *ngIf="activeSide === 'all'"
                        class="icon__all"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.875 5.67188C1.875 5.86328 2.01172 6 2.20312 6H3.29688C3.46094 6 3.625 5.86328 3.625 5.67188V3.375H5.92188C6.08594 3.375 6.25 3.23828 6.25 3.04688V1.95312C6.25 1.78906 6.08594 1.625 5.92188 1.625H2.53125C2.14844 1.625 1.875 1.92578 1.875 2.28125V5.67188ZM9.75 1.95312V3.04688C9.75 3.23828 9.88672 3.375 10.0781 3.375H12.375V5.67188C12.375 5.86328 12.5117 6 12.7031 6H13.7969C13.9609 6 14.125 5.86328 14.125 5.67188V2.28125C14.125 1.92578 13.8242 1.625 13.4688 1.625H10.0781C9.88672 1.625 9.75 1.78906 9.75 1.95312ZM13.7969 9.5H12.7031C12.5117 9.5 12.375 9.66406 12.375 9.82812V12.125H10.0781C9.88672 12.125 9.75 12.2891 9.75 12.4531V13.5469C9.75 13.7383 9.88672 13.875 10.0781 13.875H13.4688C13.8242 13.875 14.125 13.6016 14.125 13.2188V9.82812C14.125 9.66406 13.9609 9.5 13.7969 9.5ZM6.25 13.5469V12.4531C6.25 12.2891 6.08594 12.125 5.92188 12.125H3.625V9.82812C3.625 9.66406 3.46094 9.5 3.29688 9.5H2.20312C2.01172 9.5 1.875 9.66406 1.875 9.82812V13.2188C1.875 13.6016 2.14844 13.875 2.53125 13.875H5.92188C6.08594 13.875 6.25 13.7383 6.25 13.5469Z"
                            fill="#333333"/>
                    </svg>

                    <svg
                        *ngIf="activeSide === 'topLeft'"
                        class="icon__top-left"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.25 13.5469V12.4531C6.25 12.2891 6.08594 12.125 5.92188 12.125H3.625V9.82812C3.625 9.66406 3.46094 9.5 3.29688 9.5H2.20312C2.01172 9.5 1.875 9.66406 1.875 9.82812V13.2188C1.875 13.6016 2.14844 13.875 2.53125 13.875H5.92188C6.08594 13.875 6.25 13.7383 6.25 13.5469Z"
                            fill="#333333"/>
                        <path d="M13.7969 9.5H12.7031C12.5117 9.5 12.375 9.66406 12.375 9.82812V12.125H10.0781C9.88672 12.125 9.75 12.2891 9.75 12.4531V13.5469C9.75 13.7383 9.88672 13.875 10.0781 13.875H13.4688C13.8242 13.875 14.125 13.6016 14.125 13.2188V9.82812C14.125 9.66406 13.9609 9.5 13.7969 9.5Z"
                            fill="#333333"/>
                        <path d="M9.75 1.95312V3.04688C9.75 3.23828 9.88672 3.375 10.0781 3.375H12.375V5.67188C12.375 5.86328 12.5117 6 12.7031 6H13.7969C13.9609 6 14.125 5.86328 14.125 5.67188V2.28125C14.125 1.92578 13.8242 1.625 13.4688 1.625H10.0781C9.88672 1.625 9.75 1.78906 9.75 1.95312Z"
                            fill="#333333"/>
                        <path d="M2.20312 6C2.01172 6 1.875 5.86328 1.875 5.67188V2.28125C1.875 1.92578 2.14844 1.625 2.53125 1.625H5.92188C6.08594 1.625 6.25 1.78906 6.25 1.95312V3.04688C6.25 3.23828 6.08594 3.375 5.92188 3.375H3.625V5.67188C3.625 5.86328 3.46094 6 3.29688 6H2.20312Z"
                            fill="#16AADB"/>
                    </svg>

                    <svg
                        *ngIf="activeSide === 'topRight'"
                        class="icon__top-right"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.25 13.5469V12.4531C6.25 12.2891 6.08594 12.125 5.92188 12.125H3.625V9.82812C3.625 9.66406 3.46094 9.5 3.29688 9.5H2.20312C2.01172 9.5 1.875 9.66406 1.875 9.82812V13.2188C1.875 13.6016 2.14844 13.875 2.53125 13.875H5.92188C6.08594 13.875 6.25 13.7383 6.25 13.5469Z"
                            fill="#333333"/>
                        <path d="M13.7969 9.5H12.7031C12.5117 9.5 12.375 9.66406 12.375 9.82812V12.125H10.0781C9.88672 12.125 9.75 12.2891 9.75 12.4531V13.5469C9.75 13.7383 9.88672 13.875 10.0781 13.875H13.4688C13.8242 13.875 14.125 13.6016 14.125 13.2188V9.82812C14.125 9.66406 13.9609 9.5 13.7969 9.5Z"
                            fill="#333333"/>
                        <path d="M1.875 5.67188C1.875 5.86328 2.01172 6 2.20312 6H3.29688C3.46094 6 3.625 5.86328 3.625 5.67188V3.375H5.92188C6.08594 3.375 6.25 3.23828 6.25 3.04688V1.95312C6.25 1.78906 6.08594 1.625 5.92188 1.625H2.53125C2.14844 1.625 1.875 1.92578 1.875 2.28125V5.67188Z"
                            fill="#333333"/>
                        <path d="M9.75 1.95312V3.04688C9.75 3.23828 9.88672 3.375 10.0781 3.375H12.375V5.67188C12.375 5.86328 12.5117 6 12.7031 6H13.7969C13.9609 6 14.125 5.86328 14.125 5.67188V2.28125C14.125 1.92578 13.8242 1.625 13.4688 1.625H10.0781C9.88672 1.625 9.75 1.78906 9.75 1.95312Z"
                            fill="#16AADB"/>
                    </svg>

                    <svg
                        *ngIf="activeSide === 'bottomRight'"
                        class="icon__bottom-right"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.25 13.5469V12.4531C6.25 12.2891 6.08594 12.125 5.92188 12.125H3.625V9.82812C3.625 9.66406 3.46094 9.5 3.29688 9.5H2.20312C2.01172 9.5 1.875 9.66406 1.875 9.82812V13.2188C1.875 13.6016 2.14844 13.875 2.53125 13.875H5.92188C6.08594 13.875 6.25 13.7383 6.25 13.5469Z"
                            fill="#333333"/>
                        <path d="M1.875 5.67188C1.875 5.86328 2.01172 6 2.20312 6H3.29688C3.46094 6 3.625 5.86328 3.625 5.67188V3.375H5.92188C6.08594 3.375 6.25 3.23828 6.25 3.04688V1.95312C6.25 1.78906 6.08594 1.625 5.92188 1.625H2.53125C2.14844 1.625 1.875 1.92578 1.875 2.28125V5.67188Z"
                            fill="#333333"/>
                        <path d="M9.75 1.95312V3.04688C9.75 3.23828 9.88672 3.375 10.0781 3.375H12.375V5.67188C12.375 5.86328 12.5117 6 12.7031 6H13.7969C13.9609 6 14.125 5.86328 14.125 5.67188V2.28125C14.125 1.92578 13.8242 1.625 13.4688 1.625H10.0781C9.88672 1.625 9.75 1.78906 9.75 1.95312Z"
                            fill="#333333"/>
                        <path d="M13.7969 9.5H12.7031C12.5117 9.5 12.375 9.66406 12.375 9.82812V12.125H10.0781C9.88672 12.125 9.75 12.2891 9.75 12.4531V13.5469C9.75 13.7383 9.88672 13.875 10.0781 13.875H13.4688C13.8242 13.875 14.125 13.6016 14.125 13.2188V9.82812C14.125 9.66406 13.9609 9.5 13.7969 9.5Z"
                            fill="#16AADB"/>
                    </svg>

                    <svg
                        *ngIf="activeSide === 'bottomLeft'"
                        class="icon__bottom-left"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.875 5.67188C1.875 5.86328 2.01172 6 2.20312 6H3.29688C3.46094 6 3.625 5.86328 3.625 5.67188V3.375H5.92188C6.08594 3.375 6.25 3.23828 6.25 3.04688V1.95312C6.25 1.78906 6.08594 1.625 5.92188 1.625H2.53125C2.14844 1.625 1.875 1.92578 1.875 2.28125V5.67188Z"
                            fill="#333333"/>
                        <path d="M9.75 1.95312V3.04688C9.75 3.23828 9.88672 3.375 10.0781 3.375H12.375V5.67188C12.375 5.86328 12.5117 6 12.7031 6H13.7969C13.9609 6 14.125 5.86328 14.125 5.67188V2.28125C14.125 1.92578 13.8242 1.625 13.4688 1.625H10.0781C9.88672 1.625 9.75 1.78906 9.75 1.95312Z"
                            fill="#333333"/>
                        <path d="M13.7969 9.5H12.7031C12.5117 9.5 12.375 9.66406 12.375 9.82812V12.125H10.0781C9.88672 12.125 9.75 12.2891 9.75 12.4531V13.5469C9.75 13.7383 9.88672 13.875 10.0781 13.875H13.4688C13.8242 13.875 14.125 13.6016 14.125 13.2188V9.82812C14.125 9.66406 13.9609 9.5 13.7969 9.5Z"
                            fill="#333333"/>
                        <path d="M6.25 13.5469V12.4531C6.25 12.2891 6.08594 12.125 5.92188 12.125H3.625V9.82812C3.625 9.66406 3.46094 9.5 3.29688 9.5H2.20312C2.01172 9.5 1.875 9.66406 1.875 9.82812V13.2188C1.875 13.6016 2.14844 13.875 2.53125 13.875H5.92188C6.08594 13.875 6.25 13.7383 6.25 13.5469Z"
                            fill="#16AADB"/>
                    </svg>
                </div>
                <div class="editor">
                    <div
                        class="editor__common"
                        [ngClass]="{
                            'editor__common--mixed': isMixed,
                            'editor__common--hide': isExtended
                        }"
                        [hidden]="isExtended">
                        <lib-pixel-editor
                            class="editor__full-input"
                            [pixels]="isMixed ? 0 : borderRadiusGroupModel.topLeft"
                            (pixelsChange)="patchValue({
                                topLeft: $event,
                                topRight: $event,
                                bottomRight: $event,
                                bottomLeft: $event
                            })"
                            (focus)="fullInputFocus($event)"
                            (commitChange)="commitChange.emit(borderRadiusGroupModel)"
                            [maxValue]="BORDER_RADIUS_MAX"
                        ></lib-pixel-editor>
                    </div>
                    <div
                        class="editor__extended"
                        [ngClass]="{
                            'editor__extended--show': isExtended
                        }">
                        <lib-pixel-editor
                            class="editor__piece-input"
                            title="Top left corner"
                            [pixels]="borderRadiusGroupModel.topLeft"
                            [maxValue]="BORDER_RADIUS_MAX"
                            (pixelsChange)="patchValue({
                                topLeft: $event
                            })"
                            (commitChange)="commitChange.emit(borderRadiusGroupModel)"
                            (focus)="activeSide = 'topLeft'"
                            (blur)="resetSide()"
                        ></lib-pixel-editor>
                        <lib-pixel-editor
                            class="editor__piece-input"
                            title="Top right corner"
                            [pixels]="borderRadiusGroupModel.topRight"
                            [maxValue]="BORDER_RADIUS_MAX"
                            (pixelsChange)="patchValue({
                                topRight: $event
                            })"
                            (commitChange)="commitChange.emit(borderRadiusGroupModel)"
                            (focus)="activeSide = 'topRight'"
                            (blur)="resetSide()"
                        ></lib-pixel-editor>
                        <lib-pixel-editor
                            class="editor__piece-input"
                            title="Bottom right corner"
                            [pixels]="borderRadiusGroupModel.bottomRight"
                            [maxValue]="BORDER_RADIUS_MAX"
                            (pixelsChange)="patchValue({
                                bottomRight: $event
                            })"
                            (commitChange)="commitChange.emit(borderRadiusGroupModel)"
                            (focus)="activeSide = 'bottomRight'"
                            (blur)="resetSide()"
                        ></lib-pixel-editor>
                        <lib-pixel-editor
                            class="editor__piece-input"
                            title="Bottom left corner"
                            [pixels]="borderRadiusGroupModel.bottomLeft"
                            [maxValue]="BORDER_RADIUS_MAX"
                            (pixelsChange)="patchValue({
                                bottomLeft: $event
                            })"
                            (commitChange)="commitChange.emit(borderRadiusGroupModel)"
                            (focus)="activeSide = 'bottomLeft'"
                            (blur)="resetSide()"
                        ></lib-pixel-editor>
                    </div>
                </div>
            </div>
            <div class="switcher"
                *ngIf="canExtend">
                <button
                    class="switcher__button"
                    [ngClass]="{
                        'switcher__button--extended': isExtended
                    }"
                    (click)="isExtended = !isExtended">
                    <i class="fas fa-sliders-h"></i>
                </button>
            </div>
        </div>
    `,
    styles: [`
        /* reset input[type=number] arrow buttons */
        :host ::ng-deep input[type=number] {
            -moz-appearance: textfield;
        }

        :host ::ng-deep input::-webkit-outer-spin-button,
        :host ::ng-deep input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }


        .padding-group__main {
            display: flex;
            flex-basis: 205px;
            flex-grow: 1;
            flex-shrink: 0;
            border: 1px solid #ccc;
            border-radius: 4px;
            height: 34px;
            color: #333333;
        }

        .icon {
            flex-basis: 48px;
            flex-grow: 0;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .editor {
            flex-basis: 156px;
            flex-grow: 1;
            flex-shrink: 0;
            padding-right: 1px;
            color: #333333;
        }

        .editor__common {
            display: flex;
            height: 100%;
        }

        .editor__common--hide {
            display: none;
        }

        .editor__common--mixed {
            position: relative;
        }

        .editor__common--mixed ::ng-deep input:not(:focus) {
            color: transparent;
        }

        .editor__common--mixed::before {
            content: 'Mixed';
            position: absolute;
            right: 9px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
            color: #777777;
            pointer-events: none;
        }

        .editor__full-input {
            width: 100%;
            height: 100%;
            border-left: 1px solid #cccccc;
        }

        .editor__full-input ::ng-deep input {
            border: 0;
            padding: 6px;

            font-size: 14px;
            line-height: 19px;
            text-align: right;
            color: inherit;
        }

        .padding-group {
            display: flex;
        }

        .editor__extended {
            display: none;
            height: 100%;
        }

        .editor__extended--show {
            display: flex;
        }

        .editor__piece-input {
            flex-basis: 25%;
            border-left: 1px solid #cccccc;
        }

        .editor__piece-input ::ng-deep input {
            border: 0;
            padding: 6px;

            font-size: 14px;
            line-height: 19px;
            text-align: right;
            color: inherit;
        }

        .switcher__button {
            padding: 7px;
            height: 100%;
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }

        .switcher__button--extended {
            color: #16aadb;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BorderRadiusGroupEditorComponent {
    readonly BORDER_RADIUS_MAX = 100;
    @Input() borderRadiusGroupModel: BorderRadiusGroupModel;
    @Output() borderRadiusGroupModelChange = new EventEmitter<BorderRadiusGroupModel>();
    @Output() commitChange = new EventEmitter<BorderRadiusGroupModel>();
    @Input() canExtend = true;
    isExtended = false;
    activeSide: BorderRadiusCorner = 'all';

    get isMixed() {
        return this.borderRadiusGroupModel.topLeft !== this.borderRadiusGroupModel.topRight
            || this.borderRadiusGroupModel.topLeft !== this.borderRadiusGroupModel.bottomRight
            || this.borderRadiusGroupModel.topLeft !== this.borderRadiusGroupModel.bottomLeft;
    }

    patchValue(changes: Partial<BorderRadiusGroupModel>): void {
        this.borderRadiusGroupModel = { ...this.borderRadiusGroupModel, ...changes };
        this.borderRadiusGroupModelChange.emit(this.borderRadiusGroupModel);
    }

    resetSide(): void {
        this.activeSide = 'all';
    }

    resetValue(element: HTMLInputElement) {
        element.value = '';
    }

    fullInputFocus($event: FocusEvent) {
        if (this.isMixed && $event.target !== null) {
            this.resetValue($event.target as HTMLInputElement)
        }
    }
}
