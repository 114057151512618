import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { CellParameters } from '../../../../models/linked-models/cell-linked-model';

@Component({
    template: `
        <lib-expandable-group
            titleText="Padding">
            <lib-padding-group-editor
                [paddingGroupModel]="{
                    top: parameters.padding.top,
                    bottom: parameters.padding.bottom,
                    left: parameters.padding.left,
                    right: parameters.padding.right
                }"
                (commitChange)="commitChange({
                    padding: {
                        top: $event.top,
                        bottom: $event.bottom,
                        left: $event.left,
                        right: $event.right
                    }
                })"></lib-padding-group-editor>
        </lib-expandable-group>
        <lib-expandable-group
            titleText="Size">
            Width:
            <lib-width-editor
                [width]="parameters.width"
                (commitChange)="commitChange({
                    width: $event
                })"></lib-width-editor>
        </lib-expandable-group>

        <lib-expandable-group
            titleText="Выравнивание контента">
            <fieldset>
                <legend>По горизонтали</legend>
                <lib-align-editor
                    [align]="parameters.align"
                    (commitChange)="commitChange({ align: $event })"></lib-align-editor>
            </fieldset>
            <fieldset>
                <legend>По вертикали</legend>
                <lib-vertical-align-editor
                    [vAlign]="parameters.vAlign"
                    (commitChange)="commitChange({ vAlign: $event })"></lib-vertical-align-editor>
            </fieldset>
        </lib-expandable-group>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellComponent {
    @Input() parameters: CellParameters;
    @Output() parametersChanges = new EventEmitter<CellParameters>();

    commitChange(changes: Partial<CellParameters>) {
        this.parameters = { ...this.parameters, ...changes };
        this.parametersChanges.emit(this.parameters);
    }
}
