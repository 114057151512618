import { EventEmitter } from '@angular/core';
import { IStateReducer } from './i-state-reducer';

export class StateManager<T> {
    public stateChangedForward$ = new EventEmitter<T>();
    public stateChangedBack$ = new EventEmitter<T>();
    private past: T[] = [];
    private future: T[] = [];

    constructor(
        private readonly stateReducerList: IStateReducer<T>[]
    ) {
    }

    public get canUndo(): boolean {
        return this.past.length > 0;
    }

    public get canRedo(): boolean {
        return this.future.length > 0;
    }

    public dispatch(action: T) {
        const isApplied: boolean = this.stateReducerList
            .reduce((acc: boolean, reducer: IStateReducer<T>) => {
                if (reducer.dispatch(action)) {
                    return true;
                }
                return acc;
            }, false);

        if (!isApplied) {
            return;
        }

        this.past.push(action);
        this.future = [];
        this.stateChangedForward$.emit(action);
    }

    public undo() {
        if (!this.canUndo) {
            return;
        }

        const action = this.past.pop();
        if (action === undefined) {
            throw new Error('Cannot undo');
        }

        this.stateReducerList
            .forEach((reducer) => {
                reducer.rollback(action)
            });
        this.future.push(action);
        this.stateChangedBack$.emit(action);
    }

    public redo() {
        if (!this.canRedo) {
            return;
        }

        const action = this.future.pop();
        if (action === undefined) {
            throw new Error('Cannot redo');
        }

        this.stateReducerList
            .forEach((reducer) => {
                reducer.dispatch(action)
            });
        this.past.push(action);
        this.stateChangedForward$.emit(action);
    }
}
