import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorAdapterComponent } from './editor-adapter.component';
import { CellComponent } from './components/cell/cell.component';
import { DefaultComponent } from './components/default/default.component';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { LibEditorModule } from '../../../../../lib-editor/src/lib/lib-editor.module';
import { ImagePlainElementComponent } from './components/image-plain-element/image-plain-element.component';
import { HorizontalContainerComponent } from './components/horizontal-container/horizontal-container.component';
import { RowComponent } from './components/row/row.component';
import { DocumentComponent } from './components/document/document.component';
import { ResponsiveBlockSetComponent } from './components/responsive-block-set/responsive-block-set.component';
import { ResponsiveBlockComponent } from './components/responsive-block/responsive-block.component';
import { PlainElementIteratorComponent } from './components/plain-element-iterator/plain-element-iterator.component';
import { ExpandableGroupComponent } from './helper-components/expandable-group/expandable-group.component';
import { BackgroundGroupComponent } from './groups/background-group/background-group.component';
import { ButtonPlainElementComponent } from './components/button-plain-element/button-plain-element.component';

@NgModule({
    declarations: [
        EditorAdapterComponent,
        CellComponent,
        DefaultComponent,
        ImagePlainElementComponent,
        HorizontalContainerComponent,
        RowComponent,
        DocumentComponent,
        ResponsiveBlockSetComponent,
        ResponsiveBlockComponent,
        PlainElementIteratorComponent,
        ExpandableGroupComponent,
        BackgroundGroupComponent,
        ButtonPlainElementComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        LibEditorModule,
    ],
    exports: [
        EditorAdapterComponent,
    ],
})
export class EditorAdapterModule {
}
