import { ActionTypes } from './actions';
import { LinkedModel } from '../../../models/linked-models/linked-model';
import { LinkedModelParameters } from '../../../models/linked-models/linked-model-parameters';

export class ChangeParametersAction {
    public readonly type = ActionTypes.ChangeParameters;

    constructor(
        public model: LinkedModel,
        public prevValue: LinkedModelParameters,
        public nextValue: LinkedModelParameters,
    ) {
    }
}
