import { EventEmitter } from '@angular/core';
import { LinkedModel } from '../../models/linked-models/linked-model';
import {
    Actions,
    ActionTypes
} from './actions/actions';
import {
    IStateReducer,
    StateManager
} from '../../../../../lib-state-manager/src';
import { LinkedModelParameters } from '../../models/linked-models/linked-model-parameters';

export class StateManagerAdapter {
    public stateChanged$ = new EventEmitter();
    public stateElementRemoved$ = new EventEmitter<LinkedModel>();
    public stateElementAdded$ = new EventEmitter<LinkedModel>();
    public stateParametersChanged$ = new EventEmitter<LinkedModelParameters>();
    private readonly stateManager: StateManager<Actions>;

    constructor(
        private readonly stateReducerList: IStateReducer<Actions>[],
    ) {
        this.stateManager = new StateManager(stateReducerList);
        this.stateManager
            .stateChangedForward$
            .subscribe((action: Actions) => {
                if (action.type === ActionTypes.RemoveElement) {
                    this.stateElementRemoved$
                        .emit(action.targetModel);
                }

                if (action.type === ActionTypes.AddElement) {
                    this.stateElementAdded$
                        .emit(action.targetModel);
                }

                if (action.type === ActionTypes.ChangeParameters) {
                    this.stateParametersChanged$
                        .emit(action.nextValue);
                }

                this.stateChanged$.emit();
            });
        this.stateManager
            .stateChangedBack$
            .subscribe((action: Actions) => {
                if (action.type === ActionTypes.RemoveElement) {
                    this.stateElementAdded$
                        .emit(action.targetModel);
                }

                if (action.type === ActionTypes.AddElement) {
                    this.stateElementRemoved$
                        .emit(action.targetModel);
                }

                if (action.type === ActionTypes.ChangeParameters) {
                    this.stateParametersChanged$
                        .emit(action.prevValue);
                }

                this.stateChanged$.emit();
            });
    }

    get canUndo(): boolean {
        return this.stateManager.canUndo;
    }

    get canRedo(): boolean {
        return this.stateManager.canRedo;
    }

    dispatch(action: Actions): void {
        this.stateManager.dispatch(action);
    }

    undo(): void {
        this.stateManager.undo();
    }

    redo(): void {
        this.stateManager.redo();
    }
}
