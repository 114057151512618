import {
    Actions,
    ActionTypes
} from './actions/actions';
import * as _ from 'lodash';
import { IStateReducer } from '../../../../../lib-state-manager/src';
import { assertNever } from '../../utils/utils';

export class StateReducer
    implements IStateReducer<Actions> {
    dispatch(action: Actions): boolean {
        switch (action.type) {
            case ActionTypes.ChangeParameters:
                if (_.isEqual(action.model.parameters, action.nextValue)) {
                    return false;
                }
                action.model.parameters = action.nextValue;
                return true;
            case ActionTypes.AddElement:
                action.containerList.splice(
                    action.position,
                    0,
                    action.targetModel,
                );
                return true;
            case ActionTypes.MoveElement: {
                const isSameList = action.prevContainerList === action.nextContainerList;
                const isSamePosition = action.fromPosition === action.toPosition;
                if (isSameList && isSamePosition) {
                    return false;
                }


                action.prevContainerList.splice(action.fromPosition, 1);
                action.nextContainerList.splice(action.toPosition, 0, action.targetModel);
                return true;
            }
            case ActionTypes.RemoveElement:
                action.containerList.splice(
                    action.position,
                    1,
                );
                return true;
            default:
                return assertNever(action);
        }
    }

    rollback(action: Actions): void {
        switch (action.type) {
            case ActionTypes.ChangeParameters:
                action.model.parameters = action.prevValue;
                break;
            case ActionTypes.AddElement:
                action.containerList.splice(
                    action.position,
                    1,
                );
                break;
            case ActionTypes.RemoveElement:
                action.containerList.splice(
                    action.position,
                    0,
                    action.targetModel,
                );
                break;
            case ActionTypes.MoveElement:
                action.nextContainerList.splice(action.toPosition, 1);
                action.prevContainerList.splice(action.fromPosition, 0, action.targetModel);

                break;
            default:
                assertNever(action);
        }
    }
}
