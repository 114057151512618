import { NgModule } from '@angular/core';
import { LibBreadcrumbsComponent } from './lib-breadcrumbs.component';
import { BreadcrumbNodeComponent } from './components/breadcrumb-node/breadcrumb-node.component';
import { BreadcrumbLeafComponent } from './components/breadcrumb-leaf/breadcrumb-leaf.component';
import { CommonModule } from '@angular/common';


@NgModule({
    declarations: [
        LibBreadcrumbsComponent,
        BreadcrumbNodeComponent,
        BreadcrumbLeafComponent,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        LibBreadcrumbsComponent,
    ]
})
export class LibBreadcrumbsModule {
}
