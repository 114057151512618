<div [ngClass]="{
        'tree-container-node--selected': selectedElement === model,
        'tree-container-node--dragged': isDragged,
        'tree-container-node--dndMode': isDndMode
    }">
    <div
        libDraggable
        (libDraggableDragStart)="dragStart.emit({
            model: model,
            fromPosition: position
        })"

        (libDrop)="drop.emit({ to: model, position: getChildren().length })"
        (libDropAvailabilityStateChange)="dropAvailable = $event"
        [libDropPredicate]="DropPredicate"
        (mouseenter)="placeholderEnter()"
        (mouseout)="placeholderOut()"

        [ngClass]="{
            dropAvailable: dropAvailable,
            isDndMode: isDndMode
        }"
        class="title">

        <lib-title
            class="title__value"
            [level]="level"
            [isSelected]="selectedElement === model"
            [isDropMode]="isDndMode"
            [isDropInsideAvailable]="dropAvailable"
            [isExpandable]="true"
            [isDragged]="isDragged"
            [(isOpen)]="isOpen"

            (click)="select.emit(model)"

            [icon]="icon"
            [label]="title"></lib-title>
    </div>
    <div [hidden]="!isOpen">
        <div
            class="tree-container-node__child"
            *ngFor="let child of getChildren(); index as index">

            <div class="placeholder"
                [ngClass]="{
                    dropAvailable: dropAvailable,
                    isDndMode: isDndMode
                }"
                [ngStyle]="{
                    paddingLeft: level * 6 + 'px'
                }"
                (libDrop)="drop.emit({ to: model, position: index })"
                (libDropAvailabilityStateChange)="dropAvailable = $event"
                [libDropPredicate]="DropPredicate">
                <div class="placeholder__line"></div>
            </div>

            <lib-tree-dynamic-node-component
                (dragStart)="dragStart.emit({
                    model: $event.model,
                    fromPosition: $event.fromPosition
                })"
                (drop)="drop.emit({
                    to: $event.to,
                    position: $event.position
                })"
                [dropPredicate]="dropPredicate"
                [position]="index"
                [isDndMode]="isDndMode"
                [model]="child"
                (select)="select.emit($event)"
                [selectedElement]="selectedElement"
                [draggableElementPredicate]="draggableElementPredicate"
                [getLabelByModel]="getLabelByModel"
                [getIconByModel]="getIconByModel"
                [level]="level + 1">
            </lib-tree-dynamic-node-component>
        </div>
    </div>
</div>
