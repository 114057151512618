import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { DocumentParameters } from '../../../../models/linked-models/document-linked-model';
import { ColorModel } from '../../../../../../../lib-editor/src/lib/editors/primitive/color-editor/color.model';

@Component({
    selector: 'lib-document',
    template: `
        <lib-expandable-group
            titleText="Document background color">
            <lib-color-editor
                [transparentMode]="false"
                [colorModel]="backgroundColorModel"
                (commitChange)="commitChanges({ backgroundColor: $event.toString() })"></lib-color-editor>
        </lib-expandable-group>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentComponent
    implements OnInit,
        OnChanges {
    @Input() parameters: DocumentParameters;
    @Output() parametersChanges = new EventEmitter<DocumentParameters>();
    backgroundColorModel: ColorModel;

    ngOnInit(): void {
        this.initializeValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initializeValues();
    }

    initializeValues() {
        this.backgroundColorModel = new ColorModel(this.parameters.backgroundColor);
    }

    commitChanges(changes: Partial<DocumentParameters>) {
        this.parameters = { ...this.parameters, ...changes };
        this.parametersChanges.emit(this.parameters);
    }
}
