import {
    Component,
    ComponentFactoryResolver,
    Input,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ResponsiveBlockSetContainerComponent } from '../container/responsive-block-set-container/responsive-block-set-container.component';
import { HorizontalContainerComponent } from '../container/horizontal-container/horizontal-container.component';
import { RichTextPlainElementComponent } from './rich-text-plain-element/rich-text-plain-element.component';
import { PlainElementIteratorComponent } from './plain-element-iterator/plain-element-iterator.component';
import { GroupPlainElementComponent } from './group-plain-element/group-plain-element.component';
import { PlainElementLinkedModel } from '../../../../models/linked-models/plain-element-linked-model';
import { DynamicContainerComponent } from '../../../../../../../lib-dynamic-component/dynamic-container-component';
import { DisplayContainerDynamicComponent } from '../container/container.component';
import { CustomSimpleChanges } from '../../../../utils/angular-extensions';
import { BlockImagePlainElementComponent } from './block-image-plain-element/block-image-plain-element.component';
import { ButtonPlainElementComponent } from './button-plain-element/button-plain-element.component';
import { VerticalSpacerPlainElementComponent } from './vertical-spacer-plain-element/vertical-spacer-plain-element.component';
import { LinkImagePlainElementComponent } from './link-image-plain-element/link-image-plain-element.component';

export type DisplayPlainElementDynamicComponent = GroupPlainElementComponent
    | PlainElementIteratorComponent
    | RichTextPlainElementComponent
    | BlockImagePlainElementComponent
    | LinkImagePlainElementComponent
    | VerticalSpacerPlainElementComponent
    | ButtonPlainElementComponent
    | DisplayContainerDynamicComponent;

@Component({
    selector: 'lib-plain-element',
    template: `
        <ng-container #dynamicContainer></ng-container>
    `
})
export class PlainElementComponent
    extends DynamicContainerComponent<PlainElementLinkedModel, DisplayPlainElementDynamicComponent> {
    @Input() model: PlainElementLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;

    @ViewChild(
        'dynamicContainer',
        {
            read: ViewContainerRef,
            static: true
        },
    ) dynamicContainer: ViewContainerRef;

    private component: DisplayPlainElementDynamicComponent;

    constructor(resolver: ComponentFactoryResolver) {
        super(resolver);
    }

    contextToComponent(contextModel: PlainElementLinkedModel): Type<DisplayPlainElementDynamicComponent> {
        switch (contextModel['@type']) {
            case 'responsiveBlockSet':
                return ResponsiveBlockSetContainerComponent;
            case 'horizontalContainer':
                return HorizontalContainerComponent;
            case 'linkImage':
                return LinkImagePlainElementComponent;
            case 'blockImage':
                return BlockImagePlainElementComponent;
            case 'verticalSpacer':
                return VerticalSpacerPlainElementComponent;
            case 'button':
                return ButtonPlainElementComponent;
            case 'richText':
                return RichTextPlainElementComponent;
            case 'plain-element-iterator':
                return PlainElementIteratorComponent;
            case 'group-plain-element':
                return GroupPlainElementComponent;
        }
    }

    // TODO: generic type any
    onChange(changes: CustomSimpleChanges<any>): void {
        this.setValues();
        if ('ngOnChanges' in this.component) {
            this.component.ngOnChanges(changes);
        }
    }

    setValues() {
        this.component.model = this.model;
        this.component.position = this.position;
        this.component.isResponsive = this.isResponsive;
    }

    onCreateComponent(component: DisplayPlainElementDynamicComponent): void {
        this.component = component;

        this.setValues();
    }

    canRerender(previousModel: PlainElementLinkedModel, currentModel: PlainElementLinkedModel): boolean {
        return true;
    }

    beforeDestroyComponent(component: DisplayPlainElementDynamicComponent): void {
    }
}
