import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import { DocumentLinkedModelFactory } from './models/linked-models/document-linked-model-factory';
import {
    getPathFromRoot,
    getRandomChildren,
    randomElement
} from './utils/utils';
import { DocumentLinkedModel } from './models/linked-models/document-linked-model';
import {
    LinkedModel,
    LinkedModelContainers,
    LinkedModelWithParent
} from './models/linked-models/linked-model';
import { DocumentSourceModel } from './models/source-models/document-source-model';
import { LinkedModelParameters } from './models/linked-models/linked-model-parameters';
import { RowLinkedModel } from './models/linked-models/row-linked-model';
import { HorizontalContainerLinkedModel } from './models/linked-models/horizontal-container-linked-model';
import { IdMapper } from './utils/helpers/id-mapper';
import { AddElementAction } from './adapters/state-manager-adapter/actions/add-element.action';
import { ChangeParametersAction } from './adapters/state-manager-adapter/actions/change-parameters.action';
import { MoveElementAction } from './adapters/state-manager-adapter/actions/move-element.action';
import { RemoveElementAction } from './adapters/state-manager-adapter/actions/remove-element.action';
import { StateManagerAdapter } from './adapters/state-manager-adapter/state-manager-adapter';
import {
    DropAfterEvent,
    DropBeforeEvent,
    DropInsideEvent
} from './components/tree/drop-inside-event';
import { DragStartEvent } from './components/tree/drag-start-event';
import { StateReducer } from './adapters/state-manager-adapter/state-reducer';
import { DropPredicate } from './shared/drop-predicate/drop.predicate';
import { RemoveEvent } from './removeEvent';
import { CopyEvent } from './copyEvent';
import { Option } from './utils/option';
import { DragImageModel } from './components/drag-image/drag-image.model';
import { DisplayComponent } from './modules/display/display.component';

export interface MovableElement {
    model: LinkedModelWithParent;
    startPosition: number;
}

export interface AddableElement {
    model: LinkedModelWithParent;
}

export class LinkedModelHelper {
    public static childrenAccessor(model: LinkedModel): LinkedModelWithParent[] {
        switch (model['@type']) {
            case 'richText':
            case 'button':
            case 'verticalSpacer':
            case 'blockImage':
            case 'linkImage':
                return [];
            case 'document':
            case 'row':
            case 'cell':
            case 'responsiveBlock':
            case 'responsiveBlockSet':
            case 'horizontalContainer':
            case 'group-plain-element':
            case 'plain-element-iterator':
                return model.children;
        }
    }

}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css', './common-styles.css'],
})
export class AppComponent
    implements OnInit,
        AfterViewInit {
    public selectedElement: LinkedModel;
    public idMapper = new IdMapper<LinkedModel>(() => Math.random().toString(16));
    public stateManagerAdapter: StateManagerAdapter;

    public movableElement: Option<MovableElement> = Option.None();
    public addableElement: Option<AddableElement> = Option.None();
    public isDragging: boolean = false;
    public dragImage: DragImageModel | null = null;

    public viewportWidth = 800;
    public readonly documentLinkedModel: DocumentLinkedModel;
    public showOverlay: boolean = true;
    dropBeforeAfterPredicateRef = this.dropBeforeAfterPredicate.bind(this);
    dropHorizontalPredicateRef = this.dropHorizontalPredicate.bind(this);
    dropVerticalPredicateRef = this.dropVerticalPredicate.bind(this);
    getPathFromRootRef = this.getPathFromRoot.bind(this);
    draggableElementPredicateRef = this.draggableElementPredicate.bind(this);
    @ViewChild(DisplayComponent, { static: false })
    private displayComponent: DisplayComponent;

    constructor(
        readonly elementRef: ElementRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        const sourceModel: DocumentSourceModel = JSON.parse(elementRef.nativeElement.getAttribute('data-source-model'));
        this.documentLinkedModel = DocumentLinkedModelFactory.build(sourceModel);
        this.select(this.documentLinkedModel);

        this.stateManagerAdapter = new StateManagerAdapter([
            new StateReducer(),
        ]);
    }

    getDraggableModel(): Option<LinkedModel> {
        return this.movableElement
            .Map(x => x.model)
            .Else(
                this.addableElement
                    .Map(x => x.model)
            );
    }

    dropInsidePredicate = (dropContainer: LinkedModelContainers): boolean => {
        return this.getDraggableModel()
            .Match(
                (element) => DropPredicate.IsDropAvailable(
                    dropContainer,
                    element,
                ),
                () => false,
            );
    };

    dropInsidePredicateRef = this.dropInsidePredicate.bind(this);

    dropBeforeAfterPredicate(model: LinkedModel): boolean {
        return this.getDraggableModel()
            .Match(
                (element) => {
                    if (element === model) {
                        return false;
                    }

                    switch (model['@type']) {
                        case 'document':
                            return false;
                        case 'row':
                            return element['@type'] === 'row';
                        case 'horizontalContainer':
                        case 'responsiveBlockSet':
                            return element['@type'] === 'horizontalContainer' || element['@type'] === 'responsiveBlockSet';
                        case 'cell':
                            return element['@type'] === 'cell';
                        case 'responsiveBlock':
                            return element['@type'] === 'responsiveBlock';
                        case 'blockImage':
                        case 'linkImage':
                        case 'verticalSpacer':
                        case 'button':
                        case 'richText':
                        case 'group-plain-element':
                        case 'plain-element-iterator':
                            return element['@type'] === 'blockImage'
                                || element['@type'] === 'linkImage'
                                || element['@type'] === 'verticalSpacer'
                                || element['@type'] === 'button'
                                || element['@type'] === 'richText'
                                || element['@type'] === 'group-plain-element'
                                || element['@type'] === 'plain-element-iterator'
                                || element['@type'] === 'horizontalContainer'
                                || element['@type'] === 'responsiveBlockSet';
                    }
                },
                () => false
            );
    }

    dropHorizontalPredicate(model: LinkedModel): boolean {
        return this.getDraggableModel()
            .Match(
                (element) => {
                    if (element === model) {
                        return false;
                    }

                    switch (model['@type']) {
                        case 'document':
                        case 'row':
                        case 'horizontalContainer':
                        case 'responsiveBlockSet':
                        case 'richText':
                        case 'verticalSpacer':
                        case 'linkImage':
                        case 'blockImage':
                            return false;
                        case 'cell':
                        case 'responsiveBlock':
                        case 'button':
                            return true;
                        case 'group-plain-element':
                        case 'plain-element-iterator':
                            return false;
                    }
                },
                () => false
            );
    }

    dropVerticalPredicate(model: LinkedModel): boolean {
        return this.getDraggableModel()
            .Match(
                (element) => {
                    if (element === model) {
                        return false;
                    }

                    switch (model['@type']) {
                        case 'document':
                        case 'row':
                        case 'horizontalContainer':
                        case 'responsiveBlockSet':
                        case 'richText':
                        case 'verticalSpacer':
                        case 'linkImage':
                        case 'blockImage':
                            return true;
                        case 'cell':
                        case 'responsiveBlock':
                        case 'button':
                            return false;
                        case 'group-plain-element':
                        case 'plain-element-iterator':
                            return true;
                    }
                },
                () => false
            );
    }

    ngOnInit(): void {
        this.stateManagerAdapter
            .stateElementRemoved$
            .subscribe((model: LinkedModel) => {
                if (this.selectedElement === model) {
                    this.select(this.documentLinkedModel);
                }
            });

        this.stateManagerAdapter
            .stateElementAdded$
            .subscribe((model: LinkedModel) => {
                this.select(model);
            });

        // TODO: remake. Try not to trigger change from outside
        //  but encapsulate all display changes inside the display module
        this.stateManagerAdapter
            .stateParametersChanged$
            .subscribe(() => {
                this.viewportResize();
            });
    }

    selectRandomElement() {
        this.select(this.getRandomElementFromModel());
    }

    select(model: LinkedModel) {
        this.selectedElement = model;
    }

    addRow() {
        console.time('add row');
        this.stateManagerAdapter
            .dispatch(new AddElementAction(
                this.documentLinkedModel.children,
                new RowLinkedModel({
                    children: [],
                    bodyWidth: 600,
                    innerWidth: 600,
                    background: {
                        color: '#ffffff',
                        image: {
                            url: '',
                            position: 'left top',
                            repeat: 'no-repeat',
                        },
                    },
                    bodyBackground: {
                        color: 'transparent',
                    },
                    innerPadding: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    },
                    responsiveInnerPadding: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    },
                }),
                0,
            ));
        console.timeEnd('add row');
    }

    addContainer() {
        this.stateManagerAdapter
            .dispatch(new AddElementAction(
                this.documentLinkedModel.children[0].children,
                new HorizontalContainerLinkedModel({
                    children: [],
                    background: {
                        color: '#ff0000',
                        image: {
                            url: '',
                            position: 'left top',
                            repeat: 'no-repeat',
                        },
                    },
                    border: {
                        top: {
                            width: 0,
                            style: 'none',
                            color: '#000000',
                        },
                        bottom: {
                            width: 0,
                            style: 'none',
                            color: '#000000',
                        },
                        left: {
                            width: 0,
                            style: 'none',
                            color: '#000000',
                        },
                        right: {
                            width: 0,
                            style: 'none',
                            color: '#000000',
                        },
                    },
                    isFullWidth: true,
                    cellsHeight: 'auto',
                    borderRadius: {
                        topLeft: 0,
                        topRight: 0,
                        bottomRight: 0,
                        bottomLeft: 0,
                    },
                    align: 'center',
                }),
                0,
            ));
    }

    changeParameters(selectedElement: LinkedModel, currentParameters: LinkedModelParameters, nextValue: LinkedModelParameters) {
        this.stateManagerAdapter
            .dispatch(new ChangeParametersAction(
                selectedElement,
                currentParameters,
                nextValue,
            ));
    }

    moveFirstRowDown() {
        // this.stateManagerAdapter
        //     .dispatch(new MoveElementAction(
        //         this.documentLinkedModel.children,
        //         this.documentLinkedModel.children,
        //         0,
        //         1,
        //         this.documentLinkedModel.children[0],
        //     ));
    }

    deleteFirstRow() {
        console.time('delete row');
        const rowLinkedModel = this.documentLinkedModel.children[0];
        this.stateManagerAdapter
            .dispatch(new RemoveElementAction(
                this.documentLinkedModel.children,
                rowLinkedModel,
                0,
            ));
        console.timeEnd('delete row');
    }

    dragMoveStart($event: DragStartEvent) {
        if ($event.model['@type'] !== 'document') {
            this.select($event.model);
            this.movableElement = Option.Some({
                model: $event.model,
                startPosition: $event.fromPosition,
            });
            this.dragImage = {
                icon: this.getIconByModel($event.model),
                title: this.getLabelByModel($event.model),
            };
            this.isDragging = true;
        }
    }

    dragAddStart(model: LinkedModelWithParent) {
        this.addableElement = Option.Some({
            model: model,
        });
        this.dragImage = {
            icon: this.getIconByModel(model),
            title: this.getLabelByModel(model),
        };
        this.isDragging = true;
    }

    @HostListener('document:mouseup')
    mouseUp() {
        this.movableElement = Option.None();
        this.addableElement = Option.None();
        this.dragImage = null;
        this.isDragging = false;
    }

    dropInside(model: DropInsideEvent) {
        this.movableElement
            .MatchSome(
                (movableElement) => {
                    this.moveElement(
                        movableElement.model,
                        model.to,
                        movableElement.startPosition,
                        model.position,
                    );
                }
            );

        this.addableElement
            .MatchSome(
                (addableElement) => {
                    this.addElement(
                        addableElement.model,
                        model.to,
                        model.position,
                    );
                }
            );
    }

    dropBefore($event: DropBeforeEvent) {
        const nextParent: LinkedModelContainers = this.getParent($event.before);

        this.movableElement
            .MatchSome(
                (movableElement) => {
                    const prevParent = this.getParent(movableElement.model);
                    const isSameContainer = nextParent === prevParent;
                    const nextPosition = isSameContainer && movableElement.startPosition < $event.position ? $event.position - 1 : $event.position;
                    this.moveElement(
                        movableElement.model,
                        nextParent,
                        movableElement.startPosition,
                        nextPosition,
                    );
                }
            );


        this.addableElement
            .MatchSome(
                (addableElement) => {
                    this.addElement(
                        addableElement.model,
                        nextParent,
                        $event.position,
                    );
                }
            );
    }

    dropAfter($event: DropAfterEvent) {
        const nextParent: LinkedModelContainers = this.getParent($event.after);

        this.movableElement
            .MatchSome(
                (movableElement) => {
                    const prevParent = this.getParent(movableElement.model);
                    const isSameContainer = nextParent === prevParent;
                    const nextPosition = isSameContainer && movableElement.startPosition < $event.position ? $event.position : $event.position + 1;
                    this.moveElement(
                        movableElement.model,
                        nextParent,
                        movableElement.startPosition,
                        nextPosition,
                    );
                }
            );


        this.addableElement
            .MatchSome(
                (addableElement) => {
                    this.addElement(
                        addableElement.model,
                        nextParent,
                        $event.position + 1,
                    );
                }
            );
    }

    copy($event: CopyEvent) {
        const parent = this.getParent($event.model);
        const containerList = parent.children;
        this.stateManagerAdapter
            .dispatch(new AddElementAction(
                containerList,
                { ...$event.model },
                $event.position + 1,
            ));
    }

    remove($event: RemoveEvent) {
        const parent = this.getParent($event.model);
        const containerList = parent.children;
        this.stateManagerAdapter
            .dispatch(new RemoveElementAction(
                containerList,
                $event.model,
                $event.position,
            ));
    }

    getLabelByModel(model: LinkedModel): string {
        switch (model['@type']) {
            case 'document':
                return 'Документ';
            case 'row':
                return 'Полоса';
            case 'horizontalContainer':
                return 'Строчный контейнер';
            case 'responsiveBlockSet':
                return 'Адаптивный контейнер';
            case 'responsiveBlock':
                return 'Адаптивный блок';
            case 'cell':
                return 'Ячейка';
            case 'blockImage':
                return 'Картинка';
            case 'linkImage':
                return 'Картинка с ссылкой';
            case 'verticalSpacer':
                return 'Отступ';
            case 'button':
                return 'Кнопка';
            case 'richText':
                return 'Форматируемый текст';
            case 'plain-element-iterator':
                return 'Итератор элементов';
            case 'group-plain-element':
                return 'Группа?';
        }
    }

    getIconByModel(model: LinkedModel): string {
        switch (model['@type']) {
            case 'document':
                return 'folder';
            case 'row':
                return 'bars';
            case 'horizontalContainer':
                return 'table';
            case 'responsiveBlockSet':
                return 'th-large';
            case 'responsiveBlock':
                return 'square';
            case 'cell':
                return 'stream';
            case 'blockImage':
                return 'image';
            case 'button':
            case 'verticalSpacer':
            case 'linkImage':
                return 'times';
            case 'richText':
                return 'paragraph';
            case 'plain-element-iterator':
                return 'layer-group';
            case 'group-plain-element':
                return 'object-group';
        }
    }

    viewportResize() {
        this.displayComponent.resize(this.viewportWidth);
        this.changeDetectorRef.detectChanges();
    }

    draggableElementPredicate(model: LinkedModel): boolean {
        return this.getDraggableModel()
            .Contains(model);
    }

    public getPathFromRoot(model: LinkedModel): LinkedModel[] {
        return getPathFromRoot(
            this.documentLinkedModel,
            model,
            LinkedModelHelper.childrenAccessor,
        );
    }

    ngAfterViewInit(): void {
        this.viewportResize();
    }

    private getParent(target: LinkedModel): LinkedModelContainers {
        const path = getPathFromRoot(
            this.documentLinkedModel,
            target,
            LinkedModelHelper.childrenAccessor,
        );
        const penultimateIndex = path.length - 2;
        const parent = path[penultimateIndex];
        if (parent == undefined) {
            throw new Error('no parent');
        }

        return parent as LinkedModelContainers;
    }

    private addElement(
        addableElement: LinkedModelWithParent,
        containerModel: LinkedModelContainers,
        toPosition: number,
    ) {
        if (!DropPredicate.IsDropAvailable(
            containerModel,
            addableElement
        )) {
            throw new Error('can not drop inside')
        }

        const containerList = containerModel.children;
        this.stateManagerAdapter
            .dispatch(
                new AddElementAction(
                    containerList,
                    addableElement,
                    toPosition,
                ),
            );
    }

    private moveElement(
        movableElement: LinkedModelWithParent,
        containerModel: LinkedModelContainers,
        fromPosition: number,
        toPosition: number,
    ) {
        if (!DropPredicate.IsDropAvailable(
            containerModel,
            movableElement
        )) {
            throw new Error('can not drop inside')
        }

        const nextContainerList = containerModel.children;
        const prevParent = this.getParent(movableElement);
        const prevContainerList = prevParent.children;
        this.stateManagerAdapter
            .dispatch(
                new MoveElementAction(
                    prevContainerList,
                    nextContainerList,
                    fromPosition,
                    toPosition,
                    movableElement,
                )
            );
    }

    private getRandomElementFromModel(): LinkedModelWithParent {
        const randomDocumentChild = randomElement(this.documentLinkedModel.children);

        return getRandomChildren(
            randomDocumentChild,
            LinkedModelHelper.childrenAccessor,
        ) as LinkedModelWithParent;
    }
}
