import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { Background } from '../../../../models/types/Background';
import { BackgroundModel } from '../../../../../../../lib-editor/src/lib/editors/complex/background-editor/background.model';
import { ColorModel } from '../../../../../../../lib-editor/src/lib/editors/primitive/color-editor/color.model';
import { BackgroundImageModel } from '../../../../../../../lib-editor/src/lib/editors/complex/background-image-editor/background-image.model';

@Component({
    selector: 'lib-background-group',
    template: `
        <lib-expandable-group
            titleText="Фон">
            <p class="support-warn">
                Изображение фона отобразится не во всех почтовых клиентах!
                <a class="support-warn__button"
                    (click)="popover()">Подробнее
                </a>
            </p>
            <lib-background-editor
                [backgroundModel]="backgroundModel"
                (commitChange)="commitChange.emit({
                    color: $event.color.toString(),
                    image: {
                        url: $event.image.sourceUrl,
                        repeat: $event.image.repeat,
                        position: $event.image.position
                    }
                })"></lib-background-editor>
        </lib-expandable-group>
    `,
    styles: [`
        .support-warn {
            margin: 0;

            font-size: 10px;
            line-height: 14px;
            color: #9ba0a5;
        }

        .support-warn__button {
            color: #337ab7;
            text-decoration: none;
            border-bottom: 1px dashed;
            cursor: default;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundGroupComponent
    implements OnChanges {
    @Input() background: Background;
    @Output() commitChange = new EventEmitter<Background>();
    backgroundModel: BackgroundModel;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.background) {
            this.initValues();
        }
    }

    initValues() {
        this.backgroundModel = new BackgroundModel(
            new ColorModel(this.background.color),
            new BackgroundImageModel(
                this.background.image.url,
                this.background.image.position,
                this.background.image.repeat,
            ),
        );
    }

    // TODO: make bootstrap popovers
    popover() {
        window.alert('Некоторые почтовые клиенты (Windows 10 Mail, Android 4.4, Gmail приложение для iOS и Android под не Gmail аккаунты) не поддерживают фоновые изображения. Мы рекомендуем выбирать цвет, похожий на изображение, в качестве запасного варианта.');
    }
}
