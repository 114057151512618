import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'lib-switcher-editor',
    template: `
        <div class="switcher">
            <label class="switcher__wrapper">
                <input type="checkbox"
                    class="switcher__checkbox"
                    [checked]="enable"
                    (change)="switch()">
                <span class="switcher__label">{{label}}</span>
            </label>
        </div>
    `,
    styles: [`
        .switcher {
            padding-left: 2.25em;
        }

        .switcher__wrapper {
            display: inline-block;
        }

        .switcher__checkbox {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        .switcher__label {
            position: relative;
            display: inline-block;
            margin-bottom: 0;
            vertical-align: top;
        }

        .switcher__label::before {
            content: '';
            position: absolute;
            top: .25em;
            left: -2.25em;
            display: block;
            width: 1.75em;
            height: 1em;
            background-color: #ffffff;
            border: #adb5bd solid 1px;
            border-radius: .5em;
            box-sizing: border-box;
        }

        .switcher__checkbox:checked ~ .switcher__label::before {
            color: #fff;
            border-color: #16aadb;
            background-color: #16aadb;
        }

        .switcher__label::after {
            content: '';
            position: absolute;
            top: calc(.25em + 2px);
            left: calc(-2.25em + 2px);
            display: block;
            width: calc(1em - 4px);
            height: calc(1em - 4px);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
            background-color: #16aadb;
            border-radius: .5em;
            box-sizing: border-box;
        }

        .switcher__checkbox:checked ~ .switcher__label::after {
            background-color: #ffffff;
            transform: translateX(.75em);
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitcherEditorComponent {
    @Input() label: string;
    @Input() enable: boolean;
    @Output() enableChange = new EventEmitter<boolean>();
    @Output() commitChange = new EventEmitter<boolean>();

    switch(): void {
        this.enable = !this.enable;
        this.enableChange.emit(this.enable);
        this.commitChange.emit(this.enable);
    }
}
