import {
    Component,
    Input
} from '@angular/core';
import { GroupPlainElementLinkedModel } from '../../../../../models/linked-models/group-plain-element-linked-model';

@Component({
    template: `
        <lib-plain-element
            *ngFor="let plainElementModel of model.children; index as position"
            [model]="plainElementModel"
            [position]="position"
            [isResponsive]="isResponsive"></lib-plain-element>
    `,
})
export class GroupPlainElementComponent {
    @Input() model: GroupPlainElementLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;
}
