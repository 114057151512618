import { PlainElementLinkedModel } from './plain-element-linked-model';

export interface GroupPlainElementParameters {
}

export class GroupPlainElementLinkedModel {
    public readonly '@type': 'group-plain-element' = 'group-plain-element';
    public children: PlainElementLinkedModel[];
    public parameters: GroupPlainElementParameters = {};

    constructor(params: {
        children: PlainElementLinkedModel[];
    }) {
        this.children = params.children;
    }
}
