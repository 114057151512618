import {
    Component,
    EventEmitter,
    Output
} from '@angular/core';
import { IDynamicComponent } from '../../../../../../../lib-dynamic-component/i-dynamic-component';
import { ToolboxListTypes } from '../toolbox-list-group/toolbox-list-types';

@Component({
    template: `
        <h2>Компоненты</h2>
    `,
})
export class ComponentListComponent
    implements IDynamicComponent<ToolboxListTypes> {
    model: ToolboxListTypes;
    @Output() dragStart = new EventEmitter<string>();
}
