import { MapHelper } from './map-helper';

export class IdMapper<T> {
    private readonly idToWysiwyg = new Map<string, T>();
    private readonly wysiwygToId = new Map<T, string>();

    constructor(private readonly idGenerator: () => string) {
    }

    getModelById(id: string): T {
        return MapHelper.getOrThrow(this.idToWysiwyg, id);
    }

    getIdByModel(model: T): string {
        return MapHelper.getOrDefault(
            this.wysiwygToId,
            model,
            () => {
                const id = this.idGenerator();
                this.wysiwygToId.set(model, id);
                this.idToWysiwyg.set(id, model);

                return id;
            },
        );
    }
}
