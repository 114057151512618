import { Component } from '@angular/core';
import { SelectorService } from '../../services/selector/selector.service';

@Component({
    selector: 'toolbox-button-group',
    template: `
        <button class="toolbox-button"
            [ngClass]="{
                'toolbox-button--selected': selectorService.isSelected('container') | async
            }"
            (click)="selectorService.selectContainerTab()">
            <img src="/assets/toolbox-add-container-icon.svg"
                alt="Add container">
        </button>
        <button class="toolbox-button"
            [ngClass]="{
                'toolbox-button--selected': selectorService.isSelected('element') | async
            }"
            (click)="selectorService.selectElementTab()">
            <img src="/assets/toolbox-add-element-icon.svg"
                alt="Add Element">
        </button>
        <button class="toolbox-button"
            [ngClass]="{
                'toolbox-button--selected': selectorService.isSelected('component') | async
            }"
            (click)="selectorService.selectComponentTab()">
            <img src="/assets/toolbox-add-component-icon.svg"
                alt="Add Component">
        </button>
        <button class="toolbox-button"
            [ngClass]="{
                'toolbox-button--selected': selectorService.isSelected('theme') | async
            }"
            (click)="selectorService.selectThemeTab()">
            <img src="/assets/toolbox-themes-icon.svg"
                alt="Themes">
        </button>
    `,
    styles: [`
        .toolbox-button {
            width: 40px;
            height: 40px;
            border: none;
            border-left: 2px solid transparent;
            border-right: 2px solid transparent;

            background-color: transparent;
            opacity: 0.65;

            cursor: pointer;
        }

        .toolbox-button:hover,
        .toolbox-button:focus {
            opacity: 1;
        }

        .toolbox-button--selected {
            border-left-color: #16aadb;
            opacity: 1;
        }
    `],
})
export class ToolboxButtonGroupComponent {
    constructor(
        public selectorService: SelectorService
    ) {
    }
}
