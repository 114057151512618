import { Url } from '../types/Url';
import { Width } from '../types/Width';
import { Height } from '../types/Height';
import { BlockImagePlainElementResponsiveParameters } from './block-image-plain-element-linked-model';

export interface LinkImagePlainElementParameters {
    linkUrl: Url;
    sourceUrl: Url;
    alternativeText: string;
    width: Width;
    height: Height;
    canShrink: boolean;
    canGrow: boolean;
}

export class LinkImagePlainElementLinkedModel {
    public readonly '@type': 'linkImage' = 'linkImage';
    public parameters: LinkImagePlainElementParameters;
    public responsiveParameters: BlockImagePlainElementResponsiveParameters;

    constructor(params: {
        linkUrl: Url;
        sourceUrl: Url;
        alternativeText: string;
        width: Width;
        height: Height;
        canShrink: boolean;
        canGrow: boolean;
        responsiveCanShrink: boolean;
        responsiveCanGrow: boolean;
    }) {
        this.parameters = {
            linkUrl: params.linkUrl,
            sourceUrl: params.sourceUrl,
            alternativeText: params.alternativeText,
            width: params.width,
            height: params.height,
            canShrink: params.canShrink,
            canGrow: params.canGrow,
        };
        this.responsiveParameters = {
            canShrink: params.responsiveCanShrink,
            canGrow: params.responsiveCanGrow,
        };
    }
}
