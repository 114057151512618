import { Url } from '../types/Url';
import { Width } from '../types/Width';
import { Height } from '../types/Height';

export interface ImagePlainElementParameters {
    sourceUrl: Url;
    alternativeText: string;
    width: Width;
    height: Height;
}

export class ImagePlainElementLinkedModel {
    public readonly '@type': 'image' = 'image';
    public parameters: ImagePlainElementParameters;

    constructor(params: {
        sourceUrl: Url;
        alternativeText: string;
        width: Width;
        height: Height;
    }) {
        this.parameters = {
            sourceUrl: params.sourceUrl,
            alternativeText: params.alternativeText,
            width: params.width,
            height: params.height,
        };
    }
}
