import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { RichTextPlainElementLinkedModel } from '../../../../../models/linked-models/rich-text-plain-element-linked-model';
import { DisplayComponentEventBusService } from '../../../shared/display-event-bus/display-component-event-bus.service';
import {
    CustomOnChanges,
    CustomSimpleChanges
} from '../../../../../utils/angular-extensions';

@Component({
    template: `
        <div class="content"
            [innerHTML]="model.parameters.html | safeHtml"></div>
    `,
    styles: [`
        .content {
            pointer-events: none;
        }
    `],
})
export class RichTextPlainElementComponent
    implements OnInit,
        CustomOnChanges,
        OnDestroy {
    @Input() model: RichTextPlainElementLinkedModel;
    @Input() position: number;
    @Input() isResponsive: boolean;

    constructor(
        private elementRef: ElementRef,
        public displayComponentEventService: DisplayComponentEventBusService) {

    }

    ngOnInit(): void {
        this.displayComponentEventService.create(
            this.model,
            this.position,
            this.elementRef)
    }

    ngOnChanges(changes: CustomSimpleChanges<this>): void {
        if (changes.position) {
            this.displayComponentEventService.change(
                this.model,
                this.position,
                this.elementRef);
        }
    }

    ngOnDestroy(): void {
        this.displayComponentEventService.destroy(this.model);
    }
}
