import { ChangeParametersAction } from './change-parameters.action';
import { AddElementAction } from './add-element.action';
import { MoveElementAction } from './move-element.action';
import { RemoveElementAction } from './remove-element.action';
import {
    LinkedModel,
    LinkedModelWithParent
} from '../../../models/linked-models/linked-model';

export enum ActionTypes {
    ChangeParameters = 'Change Parameters',
    AddElement = 'Add Element',
    RemoveElement = 'Remove Element',
    MoveElement = 'Move Element',
}

export type Actions = ChangeParametersAction
    | AddElementAction<LinkedModel>
    | MoveElementAction<LinkedModelWithParent>
    | RemoveElementAction<LinkedModel>;
