export class ColorModel {
    public static readonly TRANSPARENT_COLOR = 'transparent';
    private static readonly COLOR_REGEXP = /^#[a-fA-F0-9]{6}$/;
    public readonly isTransparent: boolean = false;
    private readonly color: string;

    static isTransparent(color: string): boolean {
        return color === this.TRANSPARENT_COLOR;
    }

    static isColor(color: string): boolean {
        return this.COLOR_REGEXP.test(color);
    }

    static isValidColor(color: string): boolean {
        return this.isTransparent(color) || this.isColor(color);
    }

    constructor(color: string | ColorModel) {
        if (color instanceof ColorModel) {
            this.isTransparent = color.isTransparent;
            this.color = color.color;
            return;
        }

        if (ColorModel.isTransparent(color)) {
            this.isTransparent = true;
            this.color = color;
            return;
        }

        if (ColorModel.isColor(color)) {
            this.isTransparent = false;
            this.color = color;
            return;
        }

        throw new Error('Value is not a color');
    }

    public toString(): string {
        return this.color;
    }
}

export class TransparentColorModel extends ColorModel {
    constructor() {
        super(ColorModel.TRANSPARENT_COLOR);
    }
}
